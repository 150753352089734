#canvas {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    z-index: 9999;
}

.canvasLogo {
    width: 100vw;
    height: 100vh;
}

/* .st1 {
    fill: white;
} */

/* #maskBg {
    fill: black;
} */

/* .dark .st1 {
    fill: black;
}

.dark #maskBg {
    fill: white;
} */

.wrappor {
    transform-origin: 21% 37%;
}

.destroy {
    display: none !important;
}

.bgAnimation {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
}