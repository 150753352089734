.FlipBoxWrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.opportunity-block,
.opportunity-block>.Front,
.opportunity-block>.Back {
    height: 100%;
    width: 200%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-align: left;
}

.opportunity-block>.Front {
    background-image: url(https://landsecconnectcms.azurewebsites.net/media/1182/opps_experiences.jpg);
    background-size: cover;
    background-position: center center;
    transition: height 2s;
}

.opportunity-block>.Back,
.opportunity-block>.Front {
    padding: 30px;
}

.opportunity-block.active>.Back {
    display: flex;
}

.FlipBoxWrapper.active .Back>.BackWrapper {
    max-width: 300px;
}

.opportunity-block .title {
    text-transform: capitalize;
    color: white;
    margin-bottom: 20px;
}

.Opportunity-landig-page .Back {
    background-color: #daa404;
}

.FlipBoxWrapper .Back {
    opacity: 0;
    transform: scale(.95);
    transition: all .8s;
}

.FlipBoxWrapper:hover .Back,
.FlipBoxWrapper.active .Back {
    transform: scale(1);
    opacity: 1;
}

.FlipBoxWrapper .opportunity-block {
    transform: translateX(0%);
    transition: .25s ease-out;
}

.FlipBoxWrapper.active .opportunity-block {
    transform: translateX(-50%);
}

.FlipBoxWrapper .text {
    font-size: 1.2rem;
}

.grid-item-title.small {
    font-size: 1.2rem !important;
    animation: fadeIn 2.5s forwards;
    animation-delay: 2.5s;
}

.active.grid-item-content:before {
    animation: lineSlideIn 2s forwards;
    animation-delay: 2.5s;
}

.active.grid-item-content:before {}

@keyframes lineSlideIn {
    0% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
        transform: translate(25px) scaleX(5);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scaleX(.8);
    }
    100% {
        opacity: 1;
        transform: scaleX(1);
    }
}

/* Mobile first queries */

/* Larger than mobile */

@media (min-width: 400px) {}

/* Larger than phablet */

@media (min-width: 550px) {}

/* Larger than tablet */

@media (min-width: 768px) {}

/* Larger than desktop */

@media (min-width: 992px) {
    .opportunity-block:hover>.Back,
    .opportunity-block.active>.Back {
        display: flex;
    }
    .opportunity-block:hover>.Back>.BackWrapper,
    .opportunity-block.active>.Back>.BackWrapper {
        max-width: 300px;
    }
    .FlipBoxWrapper .opportunity-block {
        transition: none;
    }
    .FlipBoxWrapper:hover .opportunity-block {
        transform: translateX(-50%);
        transition: none;
    }
}

/* Larger than Desktop HD */

@media (min-width: 1200px) {}

@media (min-width: 1680px) {}

@media (min-width: 768px) {}