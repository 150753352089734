.grid-head.black {
    height: auto;
    min-height: 350px;
    display: flex;
    flex-direction: column;
}

.grid-head {
    z-index: 99;
}

.grid-head-container {
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    color: white;
}

.grid-head-container.controlsBoxText .controlsBoxText-body {
    flex-direction: column;
    align-items: end;
    cursor: auto;
}

.grid-head-container ::-webkit-scrollbar {
    display: none;
}

.grid-head-body {
    flex: 1;
    background-color: #ef3b9c;
    background-image: url('../../assets/components/controlsbox/Animated_square_600x600px.jpg');
    background-position: top left;
    background-size: cover;
    animation: cb-animation-bg 10s infinite;
    align-items: center;
    display: flex;
}

.grid-head-body-black {
    cursor: auto;
    position: relative;
}

.grid-head-body-black,
.controlsBoxText-body {
    background-color: #262626;
    height: 300px;
    padding: 0px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.controlsBoxText .controlsBoxText-body {
    position: relative;
    background-color: #daa404;
    height: 300px;
    color: white;
    line-height: 1.5em;
    display: flex;
    padding: 40px;
    justify-content: center;
}

.controlsBoxText .controlsBoxText-body::before {
    position: absolute;
    content: '';
    left: 0px;
    bottom: 0px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 200px 0 0 200px;
    border-color: transparent transparent transparent white;
    opacity: 0.5;
}

.ghb-wrapper-black {
    position: relative;
    background-color: black;
}

.ghb-wrapper-black #logo-img {
    display: none;
    max-width: 180px;
}

.ghb-wrapper-black #logo-img-mobile {
    height: 59px;
    padding: 5px;
}

.grid-head-body-pink {
    background-color: #717171;
    transition: background-color 0.5s ease;
    min-height: 55px;
    height: auto;
    padding: 10px;
    overflow: -webkit-paged-y;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    align-items: center;
    width: 100%;
}

.grid-head-body-pink .dropdown {
    background: rgba(0, 0, 0, 0) !important;
    color: white !important;
    border: 0px !important;
}

.grid-head-body-pink .dropdown .text,
.grid-head-body-pink .dropdown .default.text,
.grid-head-body-pink .dropdown a:hover,
.grid-head-body-pink .dropdown a:focus,
.grid-head-body-pink .dropdown a:active,
.grid-head-body-pink .dropdown a {
    color: white !important;
}

.grid-head-body-pink .dropdown a {
    display: block;
}

.grid-head-body-pink .menu {
    border: 0px !important;
    background: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important;
}

.grid-head-body-pink .menu .item {
    border: 0px !important;
}

.ghb-wrapper-pink-text {
    width: 100%;
}

.ghb-wrapper-black-text,
.controlsBoxText-body {
    margin: 0px;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 3rem;
    padding: 60px;
}

.ghb-wrapper-black-text .Type {
    color: #ef3b9c;
}

.ghb-wrapper-black-text .Address,
.ghb-wrapper-black-text .Type {
    font-size: 1rem;
}

.ghb-wrapper-black-text .Name {
    font-size: 2.5rem;
}

.listNav {
    display: none;
}

.listNavMobile {
    list-style: none;
    padding: 0;
    margin: 0px;
}

.listNavMobileWrapper {
    position: relative;
    transition: all .5s;
}

.listNavMobileWrapper:after {
    content: '';
    position: absolute;
    right: 0px;
    width: 30px;
    height: 100%;
    top: 0px;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%22-14.5%20385.5%2030%2030%22%3E%0A%20%20%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M3.625%20400.5l-9.375%209.375V413l12.5-12.5-12.5-12.5v3.125%22/%3E%0A%3C/svg%3E%0A");
    transform: rotate(90deg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 20px;
    transition: all .3s;
}


.listNavMobileWrapper.open:after {
    transform: rotate(-90deg);
}

.listNavMobile li a {
    color: black;
    transition: all .3s;
}

.listNavMobileWrapper.close {
    height: 35px;
}

.listNavMobileWrapper.open {
    height: auto;
}

listNavMobileWrapper.close,
.listNavMobile a {
    height: 0px;
    display: block;
    padding: 0px;
    opacity: 0;
}

.listNavMobile a.active,
.listNavMobileWrapper.open a {
    height: auto;
    padding: 10px 0px;
    opacity: 1;
}

.selectNav {
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    margin-bottom: 0px;
}

select.selectNav option {
    margin: 40px;
    background: rgba(255, 255, 255, 0);
    color: black;
    box-shadow: 0 0 10px 100px #fff inset;
}

.ghb-wrapper {
    text-align: center;
    flex: 1;
}

.grid-head-footer {
    width: 100%;
    height: 55px;
    line-height: 55px;
    text-align: center;
    background-color: #162038;
    font-size: 2rem;
    text-transform: capitalize;
}

.grid-head-footer a,
.grid-head-footer a:hover {
    color: white;
}

.grid-head-footer .row {
    max-width: 350px;
    margin: 0 auto;
}

#logo-img,
#logo-img-landsec {
    max-height: 400px;
    height: 140px;
}

#logo-img-landsec-2 {
    display: none;
    max-height: 150px;
    margin-top: -20px;
    margin-left: -20px;
    animation: special-op 2.5s forwards;
    /* animation-delay: 3s; */
}

i.big.icon,
i.big.icons {
    font-size: 1rem;
}

.cbl-wrapper {
    position: absolute;
    z-index: 99;
    width: 100%;
    background: black;
}

.cbl-wrapper #logo-img  {
    display: none;
    width: 100%;
    width: 200px;
    /* margin: 90px 70px; */
}

.cbl-wrapper #logo-img-mobile {
    display: block;
    height: 59px;
    padding: 5px;
}
#LandsecConnectLogo{
    display: none;
}




.grid-head-body a {
    width: 100%;
    height: 100%;
}



#logo-img,
#logo-img-landsec {
    position: absolute;
    top: 0px;
    left: 0px;
}

.x-logo-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes cb-animation-bg {
    0% {
        opacity: 1;
        background-size: 100% 100%;
        /* background-position: 8% center; */
    }
    25% {
        opacity: 0.85;
        background-size: 120% 120%;
    }
    50% {
        opacity: 1;
        background-size: 110% 150%;
    }
    100% {
        opacity: 1;
        background-size: 100% 100%;
        /* background-position: 10% center; */
    }
}

.controlsBoxText .controlsBoxText-body {
    font-size: 1.4rem;
}

.controlsBoxText .controlsBoxText-body .ConnectLink {
    font-size: 1.2rem
}

.grid-head-footer.controlsBoxBlack {
    height: auto;
}

.controlsBoxBlack .row {
    padding: 0px !important;
    max-width: 100%;
}

.grid-head-footer.controlsBoxBlack .Next-arrow,
.grid-head-footer.controlsBoxBlack .Prev-arrow {
    position: relative;
}

.grid-head-footer.controlsBoxBlack .Next-arrow:after,
.grid-head-footer.controlsBoxBlack .Prev-arrow:after {
    content: ' ';
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%22-14.5%20385.5%2030%2030%22%3E%0A%20%20%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M3.625%20400.5l-9.375%209.375V413l12.5-12.5-12.5-12.5v3.125%22/%3E%0A%3C/svg%3E%0A");
    background-size: 20px;
    height: 20px;
    width: 20px;
    color: white;
    position: absolute;
    top: 50%;
    transform: translateY(-56%);
    transition: .25s ease-out;
}

.grid-head-footer.controlsBoxBlack .Next-arrow {
    margin-right: 25px;
}

.grid-head-footer.controlsBoxBlack .Prev-arrow {
    margin-left: 20px;
}

.grid-head-footer.controlsBoxBlack .Prev-arrow:after {
    left: -25px;
    transform: translateY(-50%) rotate(180deg);
}

.grid-head-footer.controlsBoxBlack .Next-arrow:after {
    right: -25px;
}

.grid-head-footer.controlsBoxBlack .Prev-arrow:hover:after {
    left: -30px;
}

.grid-head-footer.controlsBoxBlack .Next-arrow:hover:after {
    right: -30px;
}

.controlsBoxBlack .row .column:first-child {
    text-align: left;
}

.controlsBoxBlack .row .column:last-child {
    text-align: right;
}

#logo-img-landsec {
    opacity: 0;
    margin-top: -11px;
}


.grid-head.black.full-height{
    display: block;
}
.grid-head.black.full-height > div:first-child{
    height: 370px;
}
.grid-head.black.full-height > div:last-child{
    height: auto;
}

.grid-head.black.full-height > div:last-child > div {
    padding-top: 50px;
    padding-bottom: 50px;
}

.LocationTypesWrapper .LocationTypes {
    margin-right: 6px;
    position: relative;
}


.LocationTypes:after {
   content: ' -';
}

.LocationTypesWrapper .LocationTypes:last-child:after{
    content: '';
}


/* Mobile first queries */

/* Larger than mobile */

@media (min-width: 400px) {}

/* Larger than phablet */

@media (min-width: 550px) {}

/* Larger than tablet */

@media (min-width: 768px) {}

/* Larger than desktop */

@media (min-width: 992px) {
    #logo-img {
        max-height: initial;
        height: auto;
        max-width: 300px;
        width: 60%;
    }
    .ghb-wrapper-black #logo-img-mobile {
        display: none;
    }
    .ghb-wrapper-black-text {
        margin: 0px;
        line-height: 2.4rem;
    }
    .ghb-wrapper-black-text .Address,
    .ghb-wrapper-black-text .Type {
        font-size: 1.3rem;
    }
    .ghb-wrapper-black-text .Address {
        line-height: 1.3em;
    }
    .ghb-wrapper-black {
        text-align: left;
        -ms-flex: 1 1;
        flex: 1 1;
    }
    .ghb-wrapper-black #logo-img {
        height: auto;
        display: block;
        max-width: 123px;
    }
    .ghb-wrapper-black {
        background: rgba(0, 0, 0, 0);
    }
    .ghb-wrapper-black-text .Name {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    .ghb-wrapper-black-text.cbl {
        font-size: 3.5rem;
        line-height: 2rem;
        justify-content: left;
        margin-left: 20px;
    }
    .ghb-wrapper-black-text.cbl .Name {
        line-height: 2rem;
    }
    .ghb-wrapper-black {
        display: block;
        flex: 1;
    }
    .ghb-wrapper-pink-text {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%!important;
        /* min-height: 50vh!important; */
        flex:1 1 auto;
    }

    .grid-head-body-pink.ghb{
        min-height: 50vh!important;
        flex:1 1 auto;
        height: 100%!important;
    }


    .listNav {
        margin: 0px;
    }
    .ghb-wrapper-pink-text ul {
        list-style-type: none;
        padding-left: 0px;
        /* line-height: 1.3rem; */
    }
    .ghb-wrapper-pink-text ul li {
        position: relative;
        margin-left: 10px;
    }
    .ghb-wrapper-pink-text ul li::before {
        content: ' ';
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%22-14.5%20385.5%2030%2030%22%3E%0A%20%20%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M3.625%20400.5l-9.375%209.375V413l12.5-12.5-12.5-12.5v3.125%22/%3E%0A%3C/svg%3E%0A");
        background-size: 26px;
        height: 26px;
        width: 26px;
        color: white;
        position: absolute;
        top: 50%;
        left: -7px;
        transform: translateY(-56%);
        transition: .25s ease-out;
        /* font-family: Arial;
        font-weight: bold; */
    }
    .ghb-wrapper-pink-text ul li:hover::before {
        left: -8px;
    }
    .listNav {
        display: block;
    }
    .grid-head-body-pink {
        padding: 30px;
        display: block;
    }
    .grid-head-body-black,
    .controlsBoxText-body {
        padding: 30px;
        padding-right: 10px;
        background-color: black;
        height: 100%;
    }
    .cbl-wrapper #logo-img {
        display: block;
    }
    .cbl-wrapper #logo-img-mobile {
        display: none;
    }
    #LandsecConnectLogo{
        display: block;
    }
    
    .cbl-wrapper {
        width: 100%;
        background: rgba(0, 0, 0, 0);
    }
    i.big.icon,
    i.big.icons {
        font-size: 1rem;
        margin: 0;
        margin-top: -2px;
    }
    /* .grid-head,
    .grid-item {
        max-width: 500px; 
    } */
    .grid-head.black {
        min-height: 50vh;
        /* height: 100%; */
        /* min-height: 1200px; */
    }
    .grid-head.black.full-height {
        min-height: 100vh;
    }
    .ghb-wrapper-black-text.cbb {
        justify-content: left;
        text-align: left;
        display: block;
    }
    .grid-head-container {
        height: 50vh;
    }
    .controlsBoxText .controlsBoxText-body {
        overflow: hidden;
    }
    .ghb-wrapper-black-text,
    .controlsBoxText-body {
        padding: 0px;
    }
    #logo-img-landsec {
        max-width: 123px;
        animation: fadeOut 0.75s forwards;
        opacity: 1;
        animation-delay: 1s;
    }
    #logo-img-landsec-2 {
        max-height: 210px;
        display: block;
    }

    #logo-img-landsec.noDelay {
        /* animation-delay: 0s; */
    }
    #logo-img {
        opacity: 0;
        animation: opacityFadeIn 1.5s forwards;
        animation-delay: 1.3s;
    }
    #logo-img.noDelay {
       animation-delay:  0s; /*  */
    }

    .grid-head.black.full-height{
        display: flex;
    }
    .grid-head.black.full-height  div{
        height: auto;
        max-height: 50vh;
        /* position: relative; */
    }

    .grid-head.black.full-height  div .MapFiltered-menu{
        display: flex;
        justify-content: center;
        /* height: 50vh; */
        align-items: center;
    }

    .grid-head.black.full-height > div:first-child{
        /* height: 50vh; */
        min-height: 490px;
        max-height: 50vh;
        height:50vh;
    }
    .grid-head.black.full-height > div:last-child{
        justify-content: center;
        display: flex;
        padding: 0px 0px;
    }

    .grid-head.black.full-height > div:last-child > div {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    
}

/* Larger than Desktop HD */

@media (min-width: 1200px) {}

@media (min-width: 1400px) {
    .ghb-wrapper-black-text .Name {
        font-size: 2.4rem;
        line-height: 1em;
        margin-left: -4px;
    }
    .grid-head-body-black,
    .controlsBoxText-body {
        padding: 40px;
        height: 100%;
    }
    .grid-head-body-pink {
        padding: 30px;
    }
   
}

@media (min-width: 1680px) {
    .controlsBoxText-body,
    .grid-head-body-black {
        padding: 60px;
        height: 100%;
    }
    .ghb-wrapper-black #logo-img {
        max-width: 180px;
    }
    .ghb-wrapper-black-text .Name {
        font-size: 3.3rem;
    }
  
    .controlsBoxText .controlsBoxText-body {
        height: 100%;
        padding: 60px 80px;
    }
    .ghb-wrapper-black-text.cbl .Name {
        line-height: 4.4rem;
    }
    #logo-img-landsec {
        height: 170px;
        max-width: 100%;
        margin-top: 0px;
    }
}

@keyframes opacityFadeIn {
    0%   {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes special-op {
    0%   {opacity: 1;}
    30% { opacity: 1;}
    50% { opacity: 0.2;}
    70% { opacity: 1;}
    100% {opacity: 1;}
}


@media (max-height: 990px) and (min-width: 992px) {
   .grid-head-body-black{
       display: block!important;
   }
   
    .grid-head-container {
        min-height: 490px;
    }

    .ghb-wrapper-black-text .Address{
        line-height: 1.3rem!important;
    }
    
    .ghb-wrapper-black {
        height: 155px!important;
    }

    .grid-head.black.full-height  div{
        max-height: initial;
        /* position: relative; */
    }
}
