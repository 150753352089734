.slideItem {
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: center center;
}

.slideText {
    text-align: left;
    color: white;
    position: absolute;
    width: 100%;
    bottom: 10px;
    left: 0px;
    padding: 30px;
}

.slideAction {
    position: relative;
    padding-left: 35px;
    margin-top: 0px;
    font-weight: initial;
}

.slideAction:before {
    position: absolute;
    content: '';
    width: 30px;
    height: 30px;
    background-image: url('../../assets/Images/EnterPoint/arrowEnterPoint.svg');
    background-size: contain;
    background-repeat: no-repeat;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
}

.slideAction a {
    text-decoration: underline;
    color: white;
    margin-left: -5px;
}

.slideAction a:hover {
    color: white;
}

.slideItem h1 {
    font-size: 8vw;
}

.slideItem h3 {
    margin-top: 0px;
}

/* Video background */

.videobg {
    position: absolute;
    width: 100vw;
    /* Set video container element width here */
    height: 100vh;
    /* Set video container element height here */
    overflow: hidden;
    background: #111;
    /* bg color, if video is not high enough */
    background-image: url('http://madisonsoho-development.azurewebsites.net/boxxed/PWA/images/bg-video-poster.jpg');
    background-size: cover;
    background-position: center center;
    z-index: 1;
}

/* horizontally center the video */

.videobg-width {
    position: absolute;
    width: 100%;
    /* Change width value to cover more area*/
    height: 100%;
    left: -9999px;
    right: -9999px;
    margin: auto;
}

/* set video aspect ratio and vertically center */

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}

.videobg-aspect {
    position: absolute;
    width: 100%;
    height: 0;
    top: -9999px;
    bottom: -9999px;
    margin: auto;
    padding-bottom: 56.25%;
    /* 16:9 ratio */
    overflow: hidden;
}

.videobg-make-height {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.videobg-hide-controls {
    box-sizing: content-box;
    position: relative;
    height: 100%;
    width: 100%;
    /* Vimeo timeline and play button are ~55px high */
    padding: 55px 97.7777px;
    /* 16:9 ratio */
    top: -55px;
    left: -97.7777px;
    /* 16:9 ratio */
}

.videobg iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 0 none;
}

.carouselWrapper {
    z-index: 1;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
}

.carousel .slide {
    background-color: rgba(0, 0, 0, 0)
}

.container-video {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
}

/* Video background */

.videobg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.videobg iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.videobg-hide-controls>div {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
}


.slideItem i.big.icon,
.slideItem i.big.icons {
    margin-right: 1px;
    font-size: 1.5em !important;
}

.slideItem h1,
.slideItem h2,
.slideItem h3,
.slideItem h4,
.slideItem h5 {
    line-height: 1em !important;
}

.logoWrapper.home {
    background: rgba(0, 0, 0, 1);
    position: relative;
    z-index: 99;
    height: 59px;
}

.CarouselTextWrapper {
    position: relative;
    width: 100vw;
    height: 100vh;
    margin-top: -59px;
    overflow: hidden;
}

.ControllerSliderWrapper {
    position: absolute;
    bottom: 36vw;
    left: 0px;
    padding: 30px;
    color: white;
    z-index: 99;
    /* transform: translateY(-700%); */
    /* transform: translateY(0%); */
}

.ControllerSliderWrapper ul {
    list-style: none;
    list-style-type: none;
    margin-left: 0px;
    padding-left: 5px;
}

.ControllerSliderWrapper li {
    position: relative;
    float: left;
    margin-left: 20px;
    opacity: 0.6;
    cursor: pointer;
}

.ControllerSliderWrapper li.active {
    padding-right: 40px;
    opacity: 1;
}

.ControllerSliderWrapper li:hover:not(.active) {
    opacity: 1;
    transform: scale(1.2);
}

.ControllerSliderWrapper li::after {
    content: '';
    width: 0px;
    height: 1px;
    background-color: white;
    position: absolute;
    top: 40%;
    left: 25px;
}

.ControllerSliderWrapper li.active::after {
    width: 40px;
    transition: width 0.3s;
}

.ControllerSliderWrapper li.active:last-child::after {
    content: '';
    width: 0px;
}

.ControllerSliderWrapper li:first-child {
    margin-left: 0px;
}


.landingpage-maincontrols {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: white;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.landingpage-maincontrols-row-link{
    padding: 30px 50px;
    display: block;
    text-align: left;
}

.landingpage-maincontrols-row {
   line-height: 1.5;
}

.landingpage-maincontrols > .landingpage-maincontrols-row:first-child {
   position: relative;
   border-bottom: 2px solid white;
   padding-bottom: 40px;
   margin-bottom: 40px;
}
 
.landingpage-maincontrols-row-index{
    font-size: 1.3rem;
    text-align: center;
    display: none;
}

.landingpage-maincontrols-row-title {
    font-size: 3rem;
}

.landingpage-maincontrols-row-cta {
    font-size: 2.3rem;
    position:relative;
    display: inline-block;
    letter-spacing: -1px;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 25px;
}

.landingpage-maincontrols-row-cta a, .landingpage-maincontrols-row-cta a:hover {
  color:white;
}

.landingpage-maincontrols-row-cta:hover {
    text-decoration: none;
}

.landingpage-maincontrols-row-cta:before {
    position: absolute;
    content: "";
    width: 30px;
    height: 30px;
    background-image: url(/static/media/arrowEnterPoint.d8bd28e1.svg);
    background-size: contain;
    background-repeat: no-repeat;
    left: -20px;
    top: 49%;
    transform: translateY(-50%);
}

/* Mobile first queries */

/* Larger than mobile */

@media (min-width: 400px) {}

/* Larger than phablet */

@media (min-width: 550px) {
    .ControllerSliderWrapper {
        bottom: 150px;
    }
    .slideItem h1 {
        font-size: 3rem;
    }
}

/* Larger than tablet */

@media (min-width: 768px) {
    .slideText {
        padding: 60px;
    }
    .ControllerSliderWrapper {
        padding: 60px;
    }

    .landingpage-maincontrols-row-title {
        font-size: 3rem;
    }
}

/* Larger than desktop */

@media (min-width: 992px) {

    .landingpage-maincontrols {
        flex-direction: row;
        text-align: center;
        border: 2px solid white;
        min-width: 906.36px;
    }

    .landingpage-maincontrols > .landingpage-maincontrols-row:first-child {
        margin: 0px;
        position: relative;
        padding-bottom: 0px;
        margin-bottom: 0px;
        border-bottom: 0px;
     }

    .landingpage-maincontrols > .landingpage-maincontrols-row:first-child:after {
        content: '';
        position: absolute;
        width:2px;
        height:90%;
        right: 0px;
        top:50%;
        transform: translateY(-50%);
        background-color: white;
     }

    .landingpage-maincontrols-row-cta{
        /* margin-left: 0px!important; */
    }

    .slideItem h1 {
        font-size: 5rem;
    }
    .slideItem h3 {
        font-size: 2rem;
    }
    .carouselWrapper {
        width: 100vw;
        height: 100vh;
        max-height: 100vh;
        overflow: hidden;
    }
    .carouselWrapper .slide:nth-child(1) .slideText,
    .carouselWrapper .slide:nth-child(2) .slideText,
    .carouselWrapper .slide:nth-child(3) .slideText,
    .carouselWrapper .slide:nth-child(4) .slideText {
        bottom: auto;
        transform: none;
    }
    .carouselWrapper .slide .slideText {
        display: inline-block;
        width: auto;
        top: auto;
        padding: 0px;
        max-width: 700px;
        bottom: 177px !important;
        left: 177px;
    }
    .ControllerSliderWrapper {
        left: 177px;
        padding: 0px;
        bottom: 440px;
    }
    /* .carouselWrapper .slide:nth-child(1) .slideText {
        left: 15%;
        top: 50%;
    }
    .carouselWrapper .slide:nth-child(2) .slideText {
        left: 25%;
        top: 20%;
    }
    .carouselWrapper .slide:nth-child(3) .slideText {
        right: 49px;
        bottom: 10%;
        position: absolute;
        display: inline-block;
        left: auto;
    }
    .carouselWrapper .slide:nth-child(4) .slideText {
        right: 5%;
        bottom: 10%;
        left: auto;
    } */
    .logoWrapper.home {
        background: rgba(0, 0, 0, 0);
        position: relative;
        z-index: 99;
        width: 25%;
    }

    .landingpage-maincontrols-row-title {
        font-size: 5.3rem;
    }

    .landingpage-maincontrols-row-link{
        text-align: center;
    }
}

/* Larger than Desktop HD */

@media (min-width: 1200px) {
    /* .carouselWrapper .slide:nth-child(3) .slideText {
        left: 50%;
        top: 50%;
    } */
}

@media (min-width: 1680px) {
    .carouselWrapper .slide .slideText {
        bottom: 234px !important;
        left: 234px;
    }
    .ControllerSliderWrapper {
        left: 234px;
    }
}