.grid-head.black {
    height: auto;
    min-height: 350px;
    display: flex;
    flex-direction: column;
}

.grid-head {
    z-index: 99;
}

.grid-head-container {
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    color: white;
}

.grid-head-container.controlsBoxText .controlsBoxText-body {
    flex-direction: column;
    align-items: end;
    cursor: auto;
}

.grid-head-container ::-webkit-scrollbar {
    display: none;
}

.grid-head-body {
    flex: 1 1;
    background-color: #ef3b9c;
    background-image: url(/static/media/Animated_square_600x600px.4e0fd263.jpg);
    background-position: top left;
    background-size: cover;
    -webkit-animation: cb-animation-bg 10s infinite;
            animation: cb-animation-bg 10s infinite;
    align-items: center;
    display: flex;
}

.grid-head-body-black {
    cursor: auto;
    position: relative;
}

.grid-head-body-black,
.controlsBoxText-body {
    background-color: #262626;
    height: 300px;
    padding: 0px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.controlsBoxText .controlsBoxText-body {
    position: relative;
    background-color: #daa404;
    height: 300px;
    color: white;
    line-height: 1.5em;
    display: flex;
    padding: 40px;
    justify-content: center;
}

.controlsBoxText .controlsBoxText-body::before {
    position: absolute;
    content: '';
    left: 0px;
    bottom: 0px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 200px 0 0 200px;
    border-color: transparent transparent transparent white;
    opacity: 0.5;
}

.ghb-wrapper-black {
    position: relative;
    background-color: black;
}

.ghb-wrapper-black #logo-img {
    display: none;
    max-width: 180px;
}

.ghb-wrapper-black #logo-img-mobile {
    height: 59px;
    padding: 5px;
}

.grid-head-body-pink {
    background-color: #717171;
    -webkit-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
    min-height: 55px;
    height: auto;
    padding: 10px;
    overflow: -webkit-paged-y;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    align-items: center;
    width: 100%;
}

.grid-head-body-pink .dropdown {
    background: rgba(0, 0, 0, 0) !important;
    color: white !important;
    border: 0px !important;
}

.grid-head-body-pink .dropdown .text,
.grid-head-body-pink .dropdown .default.text,
.grid-head-body-pink .dropdown a:hover,
.grid-head-body-pink .dropdown a:focus,
.grid-head-body-pink .dropdown a:active,
.grid-head-body-pink .dropdown a {
    color: white !important;
}

.grid-head-body-pink .dropdown a {
    display: block;
}

.grid-head-body-pink .menu {
    border: 0px !important;
    background: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important;
}

.grid-head-body-pink .menu .item {
    border: 0px !important;
}

.ghb-wrapper-pink-text {
    width: 100%;
}

.ghb-wrapper-black-text,
.controlsBoxText-body {
    margin: 0px;
    flex: 1 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 3rem;
    padding: 60px;
}

.ghb-wrapper-black-text .Type {
    color: #ef3b9c;
}

.ghb-wrapper-black-text .Address,
.ghb-wrapper-black-text .Type {
    font-size: 1rem;
}

.ghb-wrapper-black-text .Name {
    font-size: 2.5rem;
}

.listNav {
    display: none;
}

.listNavMobile {
    list-style: none;
    padding: 0;
    margin: 0px;
}

.listNavMobileWrapper {
    position: relative;
    -webkit-transition: all .5s;
    transition: all .5s;
}

.listNavMobileWrapper:after {
    content: '';
    position: absolute;
    right: 0px;
    width: 30px;
    height: 100%;
    top: 0px;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%22-14.5%20385.5%2030%2030%22%3E%0A%20%20%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M3.625%20400.5l-9.375%209.375V413l12.5-12.5-12.5-12.5v3.125%22/%3E%0A%3C/svg%3E%0A");
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 20px;
    -webkit-transition: all .3s;
    transition: all .3s;
}


.listNavMobileWrapper.open:after {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.listNavMobile li a {
    color: black;
    -webkit-transition: all .3s;
    transition: all .3s;
}

.listNavMobileWrapper.close {
    height: 35px;
}

.listNavMobileWrapper.open {
    height: auto;
}

listNavMobileWrapper.close,
.listNavMobile a {
    height: 0px;
    display: block;
    padding: 0px;
    opacity: 0;
}

.listNavMobile a.active,
.listNavMobileWrapper.open a {
    height: auto;
    padding: 10px 0px;
    opacity: 1;
}

.selectNav {
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    margin-bottom: 0px;
}

select.selectNav option {
    margin: 40px;
    background: rgba(255, 255, 255, 0);
    color: black;
    box-shadow: 0 0 10px 100px #fff inset;
}

.ghb-wrapper {
    text-align: center;
    flex: 1 1;
}

.grid-head-footer {
    width: 100%;
    height: 55px;
    line-height: 55px;
    text-align: center;
    background-color: #162038;
    font-size: 2rem;
    text-transform: capitalize;
}

.grid-head-footer a,
.grid-head-footer a:hover {
    color: white;
}

.grid-head-footer .row {
    max-width: 350px;
    margin: 0 auto;
}

#logo-img,
#logo-img-landsec {
    max-height: 400px;
    height: 140px;
}

#logo-img-landsec-2 {
    display: none;
    max-height: 150px;
    margin-top: -20px;
    margin-left: -20px;
    -webkit-animation: special-op 2.5s forwards;
            animation: special-op 2.5s forwards;
    /* animation-delay: 3s; */
}

i.big.icon,
i.big.icons {
    font-size: 1rem;
}

.cbl-wrapper {
    position: absolute;
    z-index: 99;
    width: 100%;
    background: black;
}

.cbl-wrapper #logo-img  {
    display: none;
    width: 100%;
    width: 200px;
    /* margin: 90px 70px; */
}

.cbl-wrapper #logo-img-mobile {
    display: block;
    height: 59px;
    padding: 5px;
}
#LandsecConnectLogo{
    display: none;
}




.grid-head-body a {
    width: 100%;
    height: 100%;
}



#logo-img,
#logo-img-landsec {
    position: absolute;
    top: 0px;
    left: 0px;
}

.x-logo-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes cb-animation-bg {
    0% {
        opacity: 1;
        background-size: 100% 100%;
        /* background-position: 8% center; */
    }
    25% {
        opacity: 0.85;
        background-size: 120% 120%;
    }
    50% {
        opacity: 1;
        background-size: 110% 150%;
    }
    100% {
        opacity: 1;
        background-size: 100% 100%;
        /* background-position: 10% center; */
    }
}

@keyframes cb-animation-bg {
    0% {
        opacity: 1;
        background-size: 100% 100%;
        /* background-position: 8% center; */
    }
    25% {
        opacity: 0.85;
        background-size: 120% 120%;
    }
    50% {
        opacity: 1;
        background-size: 110% 150%;
    }
    100% {
        opacity: 1;
        background-size: 100% 100%;
        /* background-position: 10% center; */
    }
}

.controlsBoxText .controlsBoxText-body {
    font-size: 1.4rem;
}

.controlsBoxText .controlsBoxText-body .ConnectLink {
    font-size: 1.2rem
}

.grid-head-footer.controlsBoxBlack {
    height: auto;
}

.controlsBoxBlack .row {
    padding: 0px !important;
    max-width: 100%;
}

.grid-head-footer.controlsBoxBlack .Next-arrow,
.grid-head-footer.controlsBoxBlack .Prev-arrow {
    position: relative;
}

.grid-head-footer.controlsBoxBlack .Next-arrow:after,
.grid-head-footer.controlsBoxBlack .Prev-arrow:after {
    content: ' ';
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%22-14.5%20385.5%2030%2030%22%3E%0A%20%20%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M3.625%20400.5l-9.375%209.375V413l12.5-12.5-12.5-12.5v3.125%22/%3E%0A%3C/svg%3E%0A");
    background-size: 20px;
    height: 20px;
    width: 20px;
    color: white;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-56%);
            transform: translateY(-56%);
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}

.grid-head-footer.controlsBoxBlack .Next-arrow {
    margin-right: 25px;
}

.grid-head-footer.controlsBoxBlack .Prev-arrow {
    margin-left: 20px;
}

.grid-head-footer.controlsBoxBlack .Prev-arrow:after {
    left: -25px;
    -webkit-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
}

.grid-head-footer.controlsBoxBlack .Next-arrow:after {
    right: -25px;
}

.grid-head-footer.controlsBoxBlack .Prev-arrow:hover:after {
    left: -30px;
}

.grid-head-footer.controlsBoxBlack .Next-arrow:hover:after {
    right: -30px;
}

.controlsBoxBlack .row .column:first-child {
    text-align: left;
}

.controlsBoxBlack .row .column:last-child {
    text-align: right;
}

#logo-img-landsec {
    opacity: 0;
    margin-top: -11px;
}


.grid-head.black.full-height{
    display: block;
}
.grid-head.black.full-height > div:first-child{
    height: 370px;
}
.grid-head.black.full-height > div:last-child{
    height: auto;
}

.grid-head.black.full-height > div:last-child > div {
    padding-top: 50px;
    padding-bottom: 50px;
}

.LocationTypesWrapper .LocationTypes {
    margin-right: 6px;
    position: relative;
}


.LocationTypes:after {
   content: ' -';
}

.LocationTypesWrapper .LocationTypes:last-child:after{
    content: '';
}


/* Mobile first queries */

/* Larger than mobile */

@media (min-width: 400px) {}

/* Larger than phablet */

@media (min-width: 550px) {}

/* Larger than tablet */

@media (min-width: 768px) {}

/* Larger than desktop */

@media (min-width: 992px) {
    #logo-img {
        max-height: none;
        max-height: initial;
        height: auto;
        max-width: 300px;
        width: 60%;
    }
    .ghb-wrapper-black #logo-img-mobile {
        display: none;
    }
    .ghb-wrapper-black-text {
        margin: 0px;
        line-height: 2.4rem;
    }
    .ghb-wrapper-black-text .Address,
    .ghb-wrapper-black-text .Type {
        font-size: 1.3rem;
    }
    .ghb-wrapper-black-text .Address {
        line-height: 1.3em;
    }
    .ghb-wrapper-black {
        text-align: left;
        flex: 1 1;
    }
    .ghb-wrapper-black #logo-img {
        height: auto;
        display: block;
        max-width: 123px;
    }
    .ghb-wrapper-black {
        background: rgba(0, 0, 0, 0);
    }
    .ghb-wrapper-black-text .Name {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    .ghb-wrapper-black-text.cbl {
        font-size: 3.5rem;
        line-height: 2rem;
        justify-content: left;
        margin-left: 20px;
    }
    .ghb-wrapper-black-text.cbl .Name {
        line-height: 2rem;
    }
    .ghb-wrapper-black {
        display: block;
        flex: 1 1;
    }
    .ghb-wrapper-pink-text {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%!important;
        /* min-height: 50vh!important; */
        flex:1 1 auto;
    }

    .grid-head-body-pink.ghb{
        min-height: 50vh!important;
        flex:1 1 auto;
        height: 100%!important;
    }


    .listNav {
        margin: 0px;
    }
    .ghb-wrapper-pink-text ul {
        list-style-type: none;
        padding-left: 0px;
        /* line-height: 1.3rem; */
    }
    .ghb-wrapper-pink-text ul li {
        position: relative;
        margin-left: 10px;
    }
    .ghb-wrapper-pink-text ul li::before {
        content: ' ';
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%22-14.5%20385.5%2030%2030%22%3E%0A%20%20%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M3.625%20400.5l-9.375%209.375V413l12.5-12.5-12.5-12.5v3.125%22/%3E%0A%3C/svg%3E%0A");
        background-size: 26px;
        height: 26px;
        width: 26px;
        color: white;
        position: absolute;
        top: 50%;
        left: -7px;
        -webkit-transform: translateY(-56%);
                transform: translateY(-56%);
        -webkit-transition: .25s ease-out;
        transition: .25s ease-out;
        /* font-family: Arial;
        font-weight: bold; */
    }
    .ghb-wrapper-pink-text ul li:hover::before {
        left: -8px;
    }
    .listNav {
        display: block;
    }
    .grid-head-body-pink {
        padding: 30px;
        display: block;
    }
    .grid-head-body-black,
    .controlsBoxText-body {
        padding: 30px;
        padding-right: 10px;
        background-color: black;
        height: 100%;
    }
    .cbl-wrapper #logo-img {
        display: block;
    }
    .cbl-wrapper #logo-img-mobile {
        display: none;
    }
    #LandsecConnectLogo{
        display: block;
    }
    
    .cbl-wrapper {
        width: 100%;
        background: rgba(0, 0, 0, 0);
    }
    i.big.icon,
    i.big.icons {
        font-size: 1rem;
        margin: 0;
        margin-top: -2px;
    }
    /* .grid-head,
    .grid-item {
        max-width: 500px; 
    } */
    .grid-head.black {
        min-height: 50vh;
        /* height: 100%; */
        /* min-height: 1200px; */
    }
    .grid-head.black.full-height {
        min-height: 100vh;
    }
    .ghb-wrapper-black-text.cbb {
        justify-content: left;
        text-align: left;
        display: block;
    }
    .grid-head-container {
        height: 50vh;
    }
    .controlsBoxText .controlsBoxText-body {
        overflow: hidden;
    }
    .ghb-wrapper-black-text,
    .controlsBoxText-body {
        padding: 0px;
    }
    #logo-img-landsec {
        max-width: 123px;
        -webkit-animation: fadeOut 0.75s forwards;
                animation: fadeOut 0.75s forwards;
        opacity: 1;
        -webkit-animation-delay: 1s;
                animation-delay: 1s;
    }
    #logo-img-landsec-2 {
        max-height: 210px;
        display: block;
    }

    #logo-img-landsec.noDelay {
        /* animation-delay: 0s; */
    }
    #logo-img {
        opacity: 0;
        -webkit-animation: opacityFadeIn 1.5s forwards;
                animation: opacityFadeIn 1.5s forwards;
        -webkit-animation-delay: 1.3s;
                animation-delay: 1.3s;
    }
    #logo-img.noDelay {
       -webkit-animation-delay:  0s;
               animation-delay:  0s; /*  */
    }

    .grid-head.black.full-height{
        display: flex;
    }
    .grid-head.black.full-height  div{
        height: auto;
        max-height: 50vh;
        /* position: relative; */
    }

    .grid-head.black.full-height  div .MapFiltered-menu{
        display: flex;
        justify-content: center;
        /* height: 50vh; */
        align-items: center;
    }

    .grid-head.black.full-height > div:first-child{
        /* height: 50vh; */
        min-height: 490px;
        max-height: 50vh;
        height:50vh;
    }
    .grid-head.black.full-height > div:last-child{
        justify-content: center;
        display: flex;
        padding: 0px 0px;
    }

    .grid-head.black.full-height > div:last-child > div {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    
}

/* Larger than Desktop HD */

@media (min-width: 1200px) {}

@media (min-width: 1400px) {
    .ghb-wrapper-black-text .Name {
        font-size: 2.4rem;
        line-height: 1em;
        margin-left: -4px;
    }
    .grid-head-body-black,
    .controlsBoxText-body {
        padding: 40px;
        height: 100%;
    }
    .grid-head-body-pink {
        padding: 30px;
    }
   
}

@media (min-width: 1680px) {
    .controlsBoxText-body,
    .grid-head-body-black {
        padding: 60px;
        height: 100%;
    }
    .ghb-wrapper-black #logo-img {
        max-width: 180px;
    }
    .ghb-wrapper-black-text .Name {
        font-size: 3.3rem;
    }
  
    .controlsBoxText .controlsBoxText-body {
        height: 100%;
        padding: 60px 80px;
    }
    .ghb-wrapper-black-text.cbl .Name {
        line-height: 4.4rem;
    }
    #logo-img-landsec {
        height: 170px;
        max-width: 100%;
        margin-top: 0px;
    }
}

@-webkit-keyframes opacityFadeIn {
    0%   {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes opacityFadeIn {
    0%   {opacity: 0;}
    100% {opacity: 1;}
}

@-webkit-keyframes special-op {
    0%   {opacity: 1;}
    30% { opacity: 1;}
    50% { opacity: 0.2;}
    70% { opacity: 1;}
    100% {opacity: 1;}
}

@keyframes special-op {
    0%   {opacity: 1;}
    30% { opacity: 1;}
    50% { opacity: 0.2;}
    70% { opacity: 1;}
    100% {opacity: 1;}
}


@media (max-height: 990px) and (min-width: 992px) {
   .grid-head-body-black{
       display: block!important;
   }
   
    .grid-head-container {
        min-height: 490px;
    }

    .ghb-wrapper-black-text .Address{
        line-height: 1.3rem!important;
    }
    
    .ghb-wrapper-black {
        height: 155px!important;
    }

    .grid-head.black.full-height  div{
        max-height: none;
        max-height: initial;
        /* position: relative; */
    }
}

/* Makes a fixed background wrapper
which the user cannot interact with */

.iframe-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0 !important;
    pointer-events: none;
    overflow: hidden;
}

.iframe-wrapper.fixed {
    position: fixed;
}

/* Make the iframe keep an aspect ratio, and
position it in the middle of its parent wrapper*/

.iframe-wrapper iframe {
    width: 100vw;
    height: 56.25vw;
    /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh;
    /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.iframe-wrapper.absolute iframe {
    width: 100%;
    height: 100%;
}

#myDefaultVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}


.slideItem {
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: center center;
}

.slideText {
    text-align: left;
    color: white;
    position: absolute;
    width: 100%;
    bottom: 10px;
    left: 0px;
    padding: 30px;
}

.slideAction {
    position: relative;
    padding-left: 35px;
    margin-top: 0px;
    font-weight: normal;
    font-weight: initial;
}

.slideAction:before {
    position: absolute;
    content: '';
    width: 30px;
    height: 30px;
    background-image: url(/static/media/arrowEnterPoint.d8bd28e1.svg);
    background-size: contain;
    background-repeat: no-repeat;
    left: 5px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.slideAction a {
    text-decoration: underline;
    color: white;
    margin-left: -5px;
}

.slideAction a:hover {
    color: white;
}

.slideItem h1 {
    font-size: 8vw;
}

.slideItem h3 {
    margin-top: 0px;
}

/* Video background */

.videobg {
    position: absolute;
    width: 100vw;
    /* Set video container element width here */
    height: 100vh;
    /* Set video container element height here */
    overflow: hidden;
    background: #111;
    /* bg color, if video is not high enough */
    background-image: url('http://madisonsoho-development.azurewebsites.net/boxxed/PWA/images/bg-video-poster.jpg');
    background-size: cover;
    background-position: center center;
    z-index: 1;
}

/* horizontally center the video */

.videobg-width {
    position: absolute;
    width: 100%;
    /* Change width value to cover more area*/
    height: 100%;
    left: -9999px;
    right: -9999px;
    margin: auto;
}

/* set video aspect ratio and vertically center */

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}

.videobg-aspect {
    position: absolute;
    width: 100%;
    height: 0;
    top: -9999px;
    bottom: -9999px;
    margin: auto;
    padding-bottom: 56.25%;
    /* 16:9 ratio */
    overflow: hidden;
}

.videobg-make-height {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.videobg-hide-controls {
    box-sizing: content-box;
    position: relative;
    height: 100%;
    width: 100%;
    /* Vimeo timeline and play button are ~55px high */
    padding: 55px 97.7777px;
    /* 16:9 ratio */
    top: -55px;
    left: -97.7777px;
    /* 16:9 ratio */
}

.videobg iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 0 none;
}

.carouselWrapper {
    z-index: 1;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
}

.carousel .slide {
    background-color: rgba(0, 0, 0, 0)
}

.container-video {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
}

/* Video background */

.videobg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.videobg iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.videobg-hide-controls>div {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
}


.slideItem i.big.icon,
.slideItem i.big.icons {
    margin-right: 1px;
    font-size: 1.5em !important;
}

.slideItem h1,
.slideItem h2,
.slideItem h3,
.slideItem h4,
.slideItem h5 {
    line-height: 1em !important;
}

.logoWrapper.home {
    background: rgba(0, 0, 0, 1);
    position: relative;
    z-index: 99;
    height: 59px;
}

.CarouselTextWrapper {
    position: relative;
    width: 100vw;
    height: 100vh;
    margin-top: -59px;
    overflow: hidden;
}

.ControllerSliderWrapper {
    position: absolute;
    bottom: 36vw;
    left: 0px;
    padding: 30px;
    color: white;
    z-index: 99;
    /* transform: translateY(-700%); */
    /* transform: translateY(0%); */
}

.ControllerSliderWrapper ul {
    list-style: none;
    list-style-type: none;
    margin-left: 0px;
    padding-left: 5px;
}

.ControllerSliderWrapper li {
    position: relative;
    float: left;
    margin-left: 20px;
    opacity: 0.6;
    cursor: pointer;
}

.ControllerSliderWrapper li.active {
    padding-right: 40px;
    opacity: 1;
}

.ControllerSliderWrapper li:hover:not(.active) {
    opacity: 1;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.ControllerSliderWrapper li::after {
    content: '';
    width: 0px;
    height: 1px;
    background-color: white;
    position: absolute;
    top: 40%;
    left: 25px;
}

.ControllerSliderWrapper li.active::after {
    width: 40px;
    -webkit-transition: width 0.3s;
    transition: width 0.3s;
}

.ControllerSliderWrapper li.active:last-child::after {
    content: '';
    width: 0px;
}

.ControllerSliderWrapper li:first-child {
    margin-left: 0px;
}


.landingpage-maincontrols {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    color: white;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.landingpage-maincontrols-row-link{
    padding: 30px 50px;
    display: block;
    text-align: left;
}

.landingpage-maincontrols-row {
   line-height: 1.5;
}

.landingpage-maincontrols > .landingpage-maincontrols-row:first-child {
   position: relative;
   border-bottom: 2px solid white;
   padding-bottom: 40px;
   margin-bottom: 40px;
}
 
.landingpage-maincontrols-row-index{
    font-size: 1.3rem;
    text-align: center;
    display: none;
}

.landingpage-maincontrols-row-title {
    font-size: 3rem;
}

.landingpage-maincontrols-row-cta {
    font-size: 2.3rem;
    position:relative;
    display: inline-block;
    letter-spacing: -1px;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 25px;
}

.landingpage-maincontrols-row-cta a, .landingpage-maincontrols-row-cta a:hover {
  color:white;
}

.landingpage-maincontrols-row-cta:hover {
    text-decoration: none;
}

.landingpage-maincontrols-row-cta:before {
    position: absolute;
    content: "";
    width: 30px;
    height: 30px;
    background-image: url(/static/media/arrowEnterPoint.d8bd28e1.svg);
    background-size: contain;
    background-repeat: no-repeat;
    left: -20px;
    top: 49%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

/* Mobile first queries */

/* Larger than mobile */

@media (min-width: 400px) {}

/* Larger than phablet */

@media (min-width: 550px) {
    .ControllerSliderWrapper {
        bottom: 150px;
    }
    .slideItem h1 {
        font-size: 3rem;
    }
}

/* Larger than tablet */

@media (min-width: 768px) {
    .slideText {
        padding: 60px;
    }
    .ControllerSliderWrapper {
        padding: 60px;
    }

    .landingpage-maincontrols-row-title {
        font-size: 3rem;
    }
}

/* Larger than desktop */

@media (min-width: 992px) {

    .landingpage-maincontrols {
        flex-direction: row;
        text-align: center;
        border: 2px solid white;
        min-width: 906.36px;
    }

    .landingpage-maincontrols > .landingpage-maincontrols-row:first-child {
        margin: 0px;
        position: relative;
        padding-bottom: 0px;
        margin-bottom: 0px;
        border-bottom: 0px;
     }

    .landingpage-maincontrols > .landingpage-maincontrols-row:first-child:after {
        content: '';
        position: absolute;
        width:2px;
        height:90%;
        right: 0px;
        top:50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        background-color: white;
     }

    .landingpage-maincontrols-row-cta{
        /* margin-left: 0px!important; */
    }

    .slideItem h1 {
        font-size: 5rem;
    }
    .slideItem h3 {
        font-size: 2rem;
    }
    .carouselWrapper {
        width: 100vw;
        height: 100vh;
        max-height: 100vh;
        overflow: hidden;
    }
    .carouselWrapper .slide:nth-child(1) .slideText,
    .carouselWrapper .slide:nth-child(2) .slideText,
    .carouselWrapper .slide:nth-child(3) .slideText,
    .carouselWrapper .slide:nth-child(4) .slideText {
        bottom: auto;
        -webkit-transform: none;
                transform: none;
    }
    .carouselWrapper .slide .slideText {
        display: inline-block;
        width: auto;
        top: auto;
        padding: 0px;
        max-width: 700px;
        bottom: 177px !important;
        left: 177px;
    }
    .ControllerSliderWrapper {
        left: 177px;
        padding: 0px;
        bottom: 440px;
    }
    /* .carouselWrapper .slide:nth-child(1) .slideText {
        left: 15%;
        top: 50%;
    }
    .carouselWrapper .slide:nth-child(2) .slideText {
        left: 25%;
        top: 20%;
    }
    .carouselWrapper .slide:nth-child(3) .slideText {
        right: 49px;
        bottom: 10%;
        position: absolute;
        display: inline-block;
        left: auto;
    }
    .carouselWrapper .slide:nth-child(4) .slideText {
        right: 5%;
        bottom: 10%;
        left: auto;
    } */
    .logoWrapper.home {
        background: rgba(0, 0, 0, 0);
        position: relative;
        z-index: 99;
        width: 25%;
    }

    .landingpage-maincontrols-row-title {
        font-size: 5.3rem;
    }

    .landingpage-maincontrols-row-link{
        text-align: center;
    }
}

/* Larger than Desktop HD */

@media (min-width: 1200px) {
    /* .carouselWrapper .slide:nth-child(3) .slideText {
        left: 50%;
        top: 50%;
    } */
}

@media (min-width: 1680px) {
    .carouselWrapper .slide .slideText {
        bottom: 234px !important;
        left: 234px;
    }
    .ControllerSliderWrapper {
        left: 234px;
    }
}
#canvas {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    z-index: 9999;
}

.canvasLogo {
    width: 100vw;
    height: 100vh;
}

/* .st1 {
    fill: white;
} */

/* #maskBg {
    fill: black;
} */

/* .dark .st1 {
    fill: black;
}

.dark #maskBg {
    fill: white;
} */

.wrappor {
    -webkit-transform-origin: 21% 37%;
            transform-origin: 21% 37%;
}

.destroy {
    display: none !important;
}

.bgAnimation {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.grid-head {
    float: left;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    height: 196px;
    cursor: pointer;
}

.grid-item,
.grid-head {
    display: inline-block;
    position: relative;
    color: #fff;
    height: 250px;
    overflow: hidden;
    width: 100%;
    vertical-align: top;
    font-size: 1rem;
    background: white;
}

.g-i {
    font-size: 1rem;
}

.grid-head {
    height: 300px;
    /* -webkit-transition: width 0.75s, height 0.75s;
    transition: width 0.75s, height 0.75s; */
}

.grid-item-bg,
.grid-item-bg-img {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
}

.grid-item-bg {
    overflow: hidden;
}

.grid-item-bg-img {
    background-position: center center;
}

.grid-item-bg-img::before {
    position: absolute;
    z-index: 0;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    -webkit-transition: opacity .5s ease;
    transition: opacity .5s ease;
}

.grid-item-content {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 0;
    left: 0;
    padding: 12px 18px 14px;
    z-index: 11;
}

.video-react-big-play-button {
    display: none !important;
}

.grid-item-content::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 0;
    border-top: 1px solid #fff;
    opacity: .75;
    transition: opacity 0.5s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-transition: opacity 0.5s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    transition: opacity 0.5s cubic-bezier(0.23, 1, 0.32, 1), transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    transition: opacity 0.5s cubic-bezier(0.23, 1, 0.32, 1), transform 0.5s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-transform-origin: left;
    transform-origin: left;
}

.grid-item-symbol {
    -webkit-transition: opacity .5s ease;
    transition: opacity .5s ease;
    margin-right: 10px;
    text-transform: uppercase;
    color: white;
    opacity: 0.75;
    font-size: 20px;
}

.grid-item-symbol.arrow {
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%22-14.5%20385.5%2030%2030%22%3E%0A%20%20%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M3.625%20400.5l-9.375%209.375V413l12.5-12.5-12.5-12.5v3.125%22/%3E%0A%3C/svg%3E%0A");
    width: 20px;
    height: 20px;
    background-size: 20px;
    margin-left: -12px;
    -webkit-transition: margin-left 0.70s;
    transition: margin-left 0.70s;
}

.grid-item:hover .grid-item-symbol.arrow,
.grid-item-b:hover .grid-item-symbol.arrow {
    margin-left: 0px;
}

.grid-item-title {
    font-family: "LandsecBrownWeb-Bold";
    -webkit-transition: opacity .5s ease;
    transition: opacity .5s ease;
    opacity: 1;
    color: white;
}

small,
.small {
    font-size: 1.25rem;
}

.video-react-video,
.video-react .video-react-poster {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
}

.video-react .video-react-video {
    top: 20%;
}

/* Mobile first queries */

/* Larger than mobile */

@media (min-width: 400px) {
    .grid-head {
        height: 370px;
        margin-top: -1px;
    }
}

/* Larger than phablet */

@media (min-width: 550px) {}

/* Larger than tablet */

@media (min-width: 768px) {}

/* Larger than desktop */

@media (min-width: 992px) {
    .grid-item {
        /* height: 300px; */
        height: 25vh;
    }
    .grid-item-bg-img {
        height: 100%;
    }
    .grid-head,
    .grid-head-body-black,
    .grid-head-body-pink,
    .ghb {
        height: 600px;
        height: 50vh;
        flex: 1 1;
        display: flex;
    }
    .grid-item-content {
        padding: 12px 25px 12px;
    }
    .grid-item,
    .grid-head {
        /* width: 33.33333%; */
        width: 25%;
        font-size: 1.5rem;
    }
    .grid-head {
        height: 50vh;
        margin-top: -1px;
    }
    .g-i {
        font-size: 2rem;
    }
    .gridix-list-items:hover .grid-item-bg-img::before,
    .my-gallery-class:hover .grid-item-bg-img::before {
        /* background: rgba(0, 0, 0, 0.85); */
        opacity: 1;
        z-index: 9;
    }
    .gridix-list-items:hover .grid-item:hover .grid-item-bg-img::before,
    .my-gallery-class:hover .g-i:hover .grid-item-bg-img::before {
        opacity: 0;
    }
    .grid-item .grid-item-bg-img::before,
    .g-i .grid-item-bg-img::before {
        opacity: 0;
    }
    .grid-item:hover .grid-item-content::before,
    .g-i:hover .grid-item-content::before {
        opacity: 1;
        -webkit-transform: translate(25px, 0) scale(5, 1);
        transform: translate(25px, 0) scale(5, 1);
    }
    .grid-item-title {
        opacity: 0;
    }
    .grid-item:hover .grid-item-title,
    .g-i:hover .grid-item-title,
    .active .grid-item-title {
        opacity: 1;
    }
    small,
    .small {
        font-size: 1rem;
    }
    .grid-item:hover .grid-item-symbol,
    .g-i:hover .grid-item-symbol {
        opacity: 1;
    }
    .grid-item-title.small {
        font-size: 1.2rem;
    }
    .grid-item-bg-img::before {
        background: rgba(0, 0, 0, 0.65);
    }
}

/* Larger than Desktop HD */

@media (min-width: 1200px) {}

@media (min-width: 1680px) {
    .grid-item,
    .grid-head {
        width: 25%;
        font-size: 2rem;
    }
}

@media (max-height: 900px) and (min-width: 992px) {
    .grid-head.black {
        min-height: 350px;
        /* height: 370px; */
    }
}
/*.cls-1,
.cls-3 {
    fill: none;
}

.cls-2 {
    clip-path: url(#clip-path);
}

.cls-3 {
    stroke: #a44396;
    stroke-miterlimit: 10;
    stroke-width: 0.5px;
}

.cls-4 {
    fill: #a44496;
}
*/

body {
    background-color: #262b44 !important;
}

/**Tooltip style*/

.mapboxgl-popup-content {
    padding: 0px;
    background: rgba(255, 255, 255, 0);
}

.mapboxgl-popup-content .mapboxgl-popup-close-button {
    color: white;
    font-size: 2rem;
}

.mapboxgl-popup-content:hover .mapboxgl-popup-close-button {
    color: black;
}

#tooltip,
.mapboxgl-popup-content {
    width: 400px;
    height: 175px;
}

.mapboxgl-popup {
    max-width: 500px !important;
}

.mapboxgl-popup-anchor-right {
    margin-top: -79px;
    margin-left: -136px;
    position: relative;
}

.mapboxgl-popup-anchor-right,
.mapboxgl-popup-anchor-bottom-right {
    margin-top: -85px;
    margin-left: 539px;
    position: relative;
}

.mapboxgl-popup-anchor-bottom-right {
    margin-top: 7px;
}

.mapboxgl-popup-anchor-bottom-right .toolTipLocationWrapper {
    border-bottom: 0px solid white;
}

.mapboxgl-popup-anchor-bottom-right .toolTipLocationWrapper::after {
    height: 124px !important;
}

.mapboxgl-popup-anchor-right::after,
.mapboxgl-popup-anchor-bottom-righ::after {
    position: absolute;
    content: '';
    width: 482px;
    height: 1px;
    background-color: white;
    bottom: 0px;
    right: calc(100% - 344px);
}

.mapboxgl-popup-anchor-right .toolTipLocationWrapper {
    border-bottom: 0px;
}

.mapboxgl-popup-anchor-left {
    margin-top: -85px;
    margin-left: -539px;
    position: relative;
}

.mapboxgl-popup-anchor-top-right {
    margin-top: 16px;
    margin-left: 8px;
    position: relative;
}

.mapboxgl-popup-anchor-bottom-left {
    margin-left: -532px;
    margin-top: 14px;
}

.mapboxgl-popup-anchor-top-left .toolTipLocationWrapper::before {
    right: -255%;
}

.mapboxgl-popup-anchor-left:after,
.mapboxgl-popup-anchor-bottom-left:after,
.mapboxgl-popup-anchor-top-left:after {
    position: absolute;
    content: '';
    width: 191px;
    height: 1px;
    background-color: white;
    bottom: 0px;
    left: calc(100% - 54px);
}

.mapboxgl-popup-anchor-top-left {
    margin-left: -532px;
    margin-top: -163px;
}

.mapboxgl-popup-anchor-top-right {
    margin-top: -164px;
    margin-left: 546px;
}

.mapboxgl-popup-anchor-top-right .toolTipLocationWrapper {
    border-bottom: 0px;
}

.mapboxgl-popup-anchor-bottom-right::after {
    position: absolute;
    content: '';
    width: 482px;
    height: 1px;
    background-color: white;
    bottom: 0px;
    right: calc(100% - 344px)
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-righ .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
    display: none;
}

.tooltip-border {
    fill: none;
}

.tooltip-background {
    fill: #27314a;
}

#tooltip:hover {
    cursor: pointer;
}

#tooltip:hover .tooltip-background {
    fill: #fff;
}

#tooltip:hover .tooltip-text,
#tooltip:hover .tooltip-title {
    fill: black;
}

.tooltip-border {
    stroke: #fff;
    stroke-miterlimit: 10;
    stroke-width: 0.55px;
}

.tooltip-text,
.tooltip-title {
    fill: #fff;
}

.tooltip-text {
    font-size: 4.24px;
}

.tooltip-title {
    font-size: 7.59px;
}

.tooltip-img,
.tooltip-bg-icon {
    fill: #231f20;
}

/*//Tooltip style*/

#MapWrapper {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.loc {
    cursor: pointer;
}

.loc:hover {
    fill: white;
}

.img-tooltip {
    width: 150px;
    height: 146px;
    background-position: center center;
    background-size: cover;
}

.__react_component_tooltip {
    padding: 0;
    border-radius: 0;
    background-color: #162038 !important;
    color: white !important;
    opacity: 1 !important;
    border: 1px solid white;
    margin-top: -73px !important;
}

.__react_component_tooltip:before {
    content: '';
    position: absolute;
    width: 150px;
    height: 1px;
    background-color: white;
    left: -151px !important;
    bottom: -1px;
    border: 1px !important;
    top: auto !important;
}

.__react_component_tooltip:after {
    border: 0 !important;
}

.__react_component_tooltip.place-right {
    margin-left: 151px;
}

.marker {
    background-color: #a93a8c;
    background-size: cover;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -6px;
    margin-left: -6px;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}

.marker:hover {
    width: 15px;
    height: 15px;
    margin-left: -7px;
    margin-top: -7px;
}

.mapboxgl-popup-close-button {
    color: white !important;
    opacity: 0.7;
}

.mapboxgl-popup-content:hover .mapboxgl-popup-close-button {
    color: black !important;
    opacity: 0.7;
}

.mapboxgl-popup-close-button:hover {
    opacity: 1;
}

.marker:hover,
.marker:hover:active,
.marker.active,
.marker:hover:focus {
    background-color: white;
}

.marker.inactive:hover{
    background-color: #a93a8c;  
}

.mapboxgl-popup {
    max-width: 200px;
}

.mapboxgl-popup:hover {
    z-index: 9;
}

.mapboxgl-popup-content {
    text-align: center;
}

.helper-coord {
    display: none;
    z-index: 999999;
    text-align: right;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 20px;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
    display: none;
}

.mapBoxWrapper {
    display: none;
}

.mapBoxRowWrapper {
    display: inline-block;
    padding: 30px;
    color: white;
    /* background: #162038; */
}

.center-detail-container-row {
    border-bottom: 1px solid white;
    margin: 20px 0px;
    margin-top: 10px;
    padding-bottom: 20px;
    display: block;
    min-height: 155px;
    width: 100%;
}

.center-detail-container-row:last-child {
    border: 0px;
    padding-bottom: 0px;
}

.center-detail-column {
    margin-top: 1rem !important;
}

.center-detail-icon-bg {
    /* background-color: black; */
    background-image: url('http://landsecconnectcms.azurewebsites.net/media/1006/icon_menu_connect.svg');
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center center;
    height: 30px;
    width: 30px;
    margin-right: 10px;
    border-radius: 50%;
}

.center-detail-center-name {
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 1.2;
}

.center-detail-row {
    display: flex;
    flex-direction: row;
}

.center-detail-body {
    line-height: 1em;
}

.center-detail-numb {
    font-weight: bold;
    font-size: 1rem;
}

.center-detail-desc {
    /* font-size: 0.7rem; */
    font-size: 1rem;
    line-height: 1.4;
}

.row.center-detail-wrapper {
    padding: 5px 0px !important;
}

.testDiv {
    /* background-image: url('../../../assets/locations/TooltipBorder.svg'); */
    background-size: 118% 101%;
    background-position: center -1px;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
}

#tooltip,
.mapboxgl-popup-content,
.toolTipLocationWrapper {
    width: 395px;
    height: 173px;
}

.toolTipLocationWrapper {
    position: relative;
    overflow: hidden;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    cursor: pointer;
    color: white;
    text-align: left;
}

.mapboxgl-popup-content .mapboxgl-popup-close-button {
    z-index: 99;
}

.toolTipLocationWrapper:hover,
.toolTipLocationWrapper a:hover,
.toolTipLocationWrapper:hover a * {
    color: black;
}

.toolTipLocationWrapper::after {
    content: '';
    position: absolute;
    border-right: 1px solid white;
    width: 1px;
    height: 121px;
    right: 0px;
    top: 0px;
}

.mapboxgl-popup-anchor-left .toolTipLocationWrapper::after,
.mapboxgl-popup-anchor-top-right .toolTipLocationWrapper::after,
.mapboxgl-popup-anchor-bottom-right .toolTipLocationWrapper::after {
    height: 131px;
}

.toolTipLocationWrapper::before {
    content: '';
    position: absolute;
    width: 1100%;
    height: 1100%;
    background: rgba(0, 0, 0, 0);
    bottom: 0;
    right: -258%;
    -webkit-transform-origin: 54% 0;
    transform-origin: 54% 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: -1;
    border: 1px solid white;
    background: #262b44;
}

.toolTipLocationWrapper:hover:before {
    background: white;
}

.toolTipLocationContainer {
    display: flex;
    widows: 100%;
    height: 100%;
}

.LocationImg {
    flex: 1 1;
    background-size: cover;
    background-position: center center;
}

.LocationDesc {
    flex: 1 1;
    padding: 15px 25px;
}

.LocationDesc .title {
    font-family: "LandsecBrownWeb-Bold";
}

.listOfSpec {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px 0px;
}

.specItem {
    flex: 1 1;
    display: flex;
    align-items: center;
}

.specIcon {
    width: 30px;
    height: 30px;
    background-image: url('http://landsecconnectcms.azurewebsites.net/media/1006/icon_menu_connect.svg');
    background-size: 27px;
    background-repeat: no-repeat;
    background-position: center center;
}

.keyFactsTextWrapper {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    font-size: 12px;
}

.keyFactsText{
    white-space: pre-line;
    max-width: 170px;
}

.keyFactsTitle {
    font-family: "LandsecBrownWeb-Bold";
}

.noLinkTrough{
    line-height: 1.4;
    font-size: 0.9rem;
    padding-left: 0;
    margin-left: 9px;
}

.mapboxgl-marker {
    /* z-index: 9; */
}

/* Larger than mobile */

@media (min-width: 400px) {}

/* Larger than phablet */

@media (min-width: 550px) {}

/* Larger than tablet */

@media (min-width: 768px) {}

/* Larger than desktop */

@media (min-width: 992px) {
    #MapWrapper {
        max-width: 100%;
        max-height: 100%;
    }
    .mapBoxWrapper {
        display: block;
        position: absolute;
        overflow: hidden;
        height: 100vh;
        width: calc(100% - 25%)!important;
        right: 0;
    }
    .mapBoxWrapper.Full,  .mapBoxWrapper > div{
        width: 100%!important;
        height: 100%!important;
    }
    .mapBoxRowWrapper {
        display: none;
    }
}

@media (max-height: 900px) and (min-width: 992px) {

    .mapBoxWrapper, .mapBoxWrapper> div{
        min-height: 980px;
    }
}

/* Larger than Desktop HD */

@media (min-width: 1200px) {}

@media (min-width: 1680px) {}

@media (min-width: 768px) {}
body {
    /* background: #bababa !important; */
}

section {
    background: #bababa ;
}

body,
main,
#root,
.body {
    height: 100%;
}

.centreSpecificContent section,
.centreSpecificContent .section {
    display: flex;
    height: 100%;
    width: 100%;
    font-size: 1rem;
    flex-direction: column;
}

section#keyfacts {
    overflow-y: auto;
}

#referencePositionsKeyFacts {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    width: 912px;
    height: 641px;
    z-index: 999;
    /* display: none; */
}

.centreSpecificContent .small {
    font-size: 0.8rem;
    line-height: 0.8rem;
    margin-top: 5px;
    display: block;
}

/* #OVERVIEW  */

.overview-block-1,
.overview-block-2 {
    min-height: 300px;
    background-size: cover;
    background-position: center center;
}

.overview-block-2-1,
.overview-block-2-2 {
    flex: 1 1;
    min-height: 300px;
    background-size: cover;
    background-position: center center;
    font-size: 1.3rem;
}

.overview-block-2-2 {
    background-color: #262626;
    line-height: 1.5em;
    color: white;
    padding: 40px 50px;
}

/* #KEYFACTS  */

.keyfacts-block-1 {
    min-height: 300px;
    flex: 1 1;
    align-items: center;
    display: flex;
    justify-content: center;
}

.keyfacts-block-1 img,
.keyfacts-block-1 .LazyLoad {
    width: 100%;
    height: 100%;
    padding: 30px;
    padding-bottom: 0px;
}

.keyfacts-block-2 {
    flex: 1 1;
    min-height: 300px;
    padding: 0px 20px;
    padding-bottom: 40px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-content: start;
}

.keyfacts-block-2-x {
    position: relative;
    padding: 0px 5px;
    width: 150px;
    height: 60px;
    padding-left: 37px;
    margin-bottom: 10px;
    color: black;
    font-weight: bold;
    font-size: 1.3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.keyfacts-block-2-x .keyfacts-close-btn {
    display: none;
    z-index: 9999;
}

.keyfacts-block-2-x.active {
    width: 100%;
    min-height: 100px;
    height: auto;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 37px;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
    background-color: white;
}

.keyfacts-block-2-x.active .keyfacts-close-btn {
    display: block;
    text-align: right;
    position: absolute;
    right: 15px;
    top: 10px;
}

.keyfacts-block-2-x.active .keyfacts-close-btn:hover {
    opacity: 0.7;
}

.active.keyfacts-block-2-x:before {
    position: absolute;
    content: '';
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: white;
    z-index: -2;
    cursor: pointer;
}

.keyfacts-block-2-x .keyfacts-details-wrapper,
.keyfacts-block-2-x.active .keyfacts-main-wrapper {
    display: none;
}

.keyfacts-block-2-x.active .keyfacts-details-wrapper,
.keyfacts-block-2-x .keyfacts-main-wrapper {
    display: block;
}

.keyfacts-block-2-x .keyfacts-main-wrapper {
    position: relative;
    line-height: 1rem !important;
}

.showMore {
    position: absolute;
    right: -10px;
    top: -10px;
    -webkit-animation-name: show-more-animation-plus;
            animation-name: show-more-animation-plus;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

.keyfacts-block-2-x.active .keyfacts-show-more>i {
    display: none;
}

.keyfacts-block-2-x>div {
    margin-bottom: 2px;
}

.keyfacts-block-corner {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 35px 0 0 35px;
    border-color: transparent transparent transparent #ef3b9c;
    left: 0px;
    bottom: 0px;
    z-index: -1;
    margin-bottom: 0px !important;
    z-index: 1;
}

.keyfacts-block-2-x-x {
    margin: 20px 0px;
}

.keyfacts-show-more {
    position: absolute;
    width: 40px;
    height: 40px;
    left: -2px;
    bottom: -4px;
    z-index: 1;
    text-align: right;
    color: black;
    font-size: 1rem;
    padding: 2px;
    background-image: url(https://landsecconnectcms.azurewebsites.net/media/1006/icon_menu_connect.svg);
    background-position: 10px calc(100% - 10px);
    background-repeat: no-repeat;
    background-size: 20px;
    z-index: 2;
}

.keyfacts-show-more i {
    position: absolute;
    right: 0px;
    top: 0px;
    -webkit-animation-name: show-more-animation-plus;
            animation-name: show-more-animation-plus;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    /* -webkit-transition: all 0.3s show-more-animation-plus;
    -moz-transition: all 0.3s show-more-animation-plus;
    -o-transition: all 0.3s show-more-animation-plus;
    -ms-transition: all 0.3s show-more-animation-plus; */
}

.keyfact-Image-wrapper {
    margin-top: 20px;
}

/* #OPPORTUNITIES  */

section#opportunities,
section#eventSpaces {
    flex-flow: row wrap;
    justify-content: space-around;
    align-content: start;
}

.opportunities-block-1,
.opportunities-block-0,
.opportunities-block-2,
.opportunities-block-3,
.opportunities-block-99 {
    width: 100%;
    min-height: 300px;
    background: #ef3b9c;
    display: flex;
    flex-direction: column;
}

.opportunities-block-0 {
    background: #ef3b9c;
    height: 600px;
    width: 100%;
}

.opportunities-block-1.wrapper {
    height: 600px;
}

.opportunities-block-3,
.opportunities-block-99 {
    width: 100%;
}

.opportunities-block-99 {
    height: 100%;
}

section#eventSpaces .opportunities-block-1>.opportunity-block {
    align-items: start;
    justify-content: start;
}

.cover-wrapper {
    width: 100%;
    height: 100%;
}

.cover,
.overview-block-1 img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
}

section#eventSpaces .opportunities-block-2 {
    background-color: black;
    width: 100%;
}

section#eventSpaces .opportunities-block-3 {
    background-color: #bababa;
    width: 100%;
}

#popUpsList {
    list-style: none;
    color: white;
    padding-left: 20px;
    line-height: 25px;
    overflow-y: auto;
    width: 100%;
}

#popUpsList li {
    margin-bottom: 15px;
}

#popUpsList li a {
    color: white;
}

#popUpsList li a:hover,
#popUpsList li a.active {
    font-family: "LandsecBrownWeb-Bold";
    position: relative;
    color: black;
    cursor: pointer;
    text-decoration: underline;
    z-index: 1;
}

#popUpsList li a:hover:after,
#popUpsList li a.active:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25px 0 0 25px;
    border-color: transparent transparent transparent white;
    left: -6px;
    bottom: 0px;
    z-index: -1;
}

section#eventSpaces .opportunities-block-2 {
    min-height: 300px;
    height: auto;
}

section#eventSpaces .opportunities-block-2>div {
    width: 100%;
}

#eventSpacesSlider .slideItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

#eventSpacesSlider #sliderImg {
    position: relative;
    background-position: center center;
    background-size: cover;
    z-index: 1;
}

#eventSpacesSlider {
    position: relative;
}

#eventSpacesSlider::before {
    position: absolute;
    content: '';
    right: 0px;
    bottom: 0px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 9vw 9vw;
    border-color: transparent transparent #000000 transparent;
    z-index: 2;
}

#eventSpacesSlider #summary,
#eventSpaces .opportunities-block-1,
#eventSpaces .opportunities-block-3 {
    position: relative;
    padding: 40px;
}

#eventSpacesSlider #summary {
    position: relative;
    font-family: "FM-Light";
    letter-spacing: 0.1em;
    background-color: #262626;
    color: white;
    line-height: 1.5em;
    z-index: 1;
}

#eventSpacesSlider #summary:before {
    position: absolute;
    content: '';
    left: 0px;
    top: 0px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9vw 9vw 0 0;
    border-color: #000000 transparent transparent transparent;
    z-index: -1;
}

#eventSpacesSlider #summary {
    order: 2;
}

.event-space-block {
    position: relative;
    width: 100%;
    height: 100%;
}

.trianlgeCorner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    -webkit-transform: translateX(-15px) translateY(-15px);
            transform: translateX(-15px) translateY(-15px);
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 100px 100px 0 0;
    border-color: #000000 transparent transparent transparent;
    z-index: 1;
}

.event-space-container {
    position: relative;
    z-index: 2;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.event-space-container .block-1 {
    flex: 1 1;
    min-height: 300px;
}

.event-space-container .block-1:first-child {
    min-height: auto;
    margin-bottom: 40px;
}

#eventSpacesSlider {
    background-size: cover;
    background-position: center center;
}

.event-space-container .eve-button {
    position: relative;
    background: black;
    padding: 10px 20px;
    color: white;
    padding-right: 40px;
    margin-top: 20px;
    display: inline-block;
}

.event-space-container .eve-button:before {
    position: absolute;
    content: '>';
    right: 20px;
}

.event-space-container .block-1 h2 {
    margin-bottom: 0px;
}

.event-space-container p {
    margin-top: 40px;
}

.event-space-container .block-1.imageWrapper {
    padding: 0px;
    margin-bottom: 50px;
}

.event-space-container .imageMap {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(https://landsecconnectcms.azurewebsites.net/media/1003/holding-image.png);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 10px 0px;
}

.event-space-container .details span {
    margin-bottom: 20px;
    display: block;
    line-height: 1.5em;
}

.event-space-container .details>span div {
    font-family: "LandsecBrownWeb-Bold";
}

section#leasing {
    justify-content: center;
    align-items: center;
    display: inline-block;
    height: auto;
}

section#leasing .cover-wrapper {
    width: 100%;
    max-width: 100%;
    position: absolute;
    top:0px;
    left:0px;
    height:90vw;
    background-size: cover;
    background-position: center center;
    /* background-color: white; */
    background-repeat: no-repeat;
}

#levSelectorShadow {
    opacity: 0.5;
}

#LevelSelector,
#levSelectorShadow {
    position: absolute;
    width: 100%;
    max-width: 150px;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

/* _:-ms-fullscreen, #LevelSelector  { position: relative!important; }
_:-ms-fullscreen, #levSelectorShadow  { display: none; } */


.LevelSelectorWrapper {
    position: relative;
    height: 10px;
    width: 100px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.LevelSelectorWrapper:hover {
    height: 30px;
    cursor: pointer;
}

#levSelectorShadow {
    z-index: 99;
}

.LevelSelectorPanelWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.FloorNameLabel {
    font-family: "LandsecBrownWeb-Bold";
    padding-left: 20px;
    margin-right: 10px;
}

#ListLevelSelector {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
    padding-right: 20px;
}

#ListLevelSelector li {
    position: relative;
    flex: 0 0 auto;
}

section#leasing .LS-1,
section#leasing .LS-2,
section#leasing .LS-3 {
    fill: #dbdada;
}

.leasing-wrapper {
    padding: 40px;
    padding-top: 300px;
    width: 100%;
    /* height: 100%; */
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.leasing-wrapper .topContent,
.leasing-wrapper .bottomContent {
    flex: 1 1;
}

.leasing-wrapper .bottomContent {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    z-index: 1;
}

.leasing-wrapper .headerContact {
    color: white;
}

.headerContact h1,
 .headerContact h3,
.headerContact h4 {
    margin: 0px;
}

.leasing-wrapper .headerContact h4 {
    margin-top: 15px;
}

.listContacts {
    padding-left: 0px;
    line-height: 2.5em;
    list-style: none;
    font-size: 1.3rem;
    /* letter-spacing: 0.05em; */
}

.listContacts li {
    position: relative;
    padding-left: 30px;
}

.listContacts li:before {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    background-image: url(/static/media/Leasing_Email.48919ffd.svg);
    left: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.listContacts li a {
    color: black;
    position: relative;
}

.listContacts li a:after {
    position: absolute;
    content: '';
    height: 1px;
    width: 0px;
    bottom: -2px;
    left: 0px;
    background-color: black;
    -webkit-transition: width .1s;
    transition: width .1s;
}

.listContacts li a:hover:after {
    width: 100%;
}

.listContacts li.email:before {
    background-image: url(/static/media/Leasing_Email_white.144895a4.svg);
}

.listContacts li.telephone:before {
    background-image: url(/static/media/Leasing_Telephone_white.ce1810b4.svg);
}

.listContacts li.download:before {
    background-image: url(/static/media/Leasing_Download_white.ce7f83ee.svg);
}

.leasing-wrapper .topContent {
    display: none;
}

.leasing-wrapper .bottomContent {
    display: inline-block;
}

/* #Gallery */

section#gallery {
    flex-direction: column;
}

section#gallery>.SliderWrapper {
    flex: 1 1;
}

section#gallery>.SliderFooter {
    height: auto;
    background: white;
    padding: 20px;
}

section#gallery>.SliderFooter h2 {
    margin-bottom: 5px;
}

section#gallery>.SliderWrapper .SliderContainer,
section#gallery>.SliderWrapper .slideItem,
section#gallery>.SliderWrapper .carousel-slider,
section#gallery>.SliderWrapper .slider,
section#gallery>.SliderWrapper .slider-wrapper {
    height: 100%;
    width: 100%;
}

section#gallery>.SliderWrapper .carousel.carousel-slider .control-arrow:hover,
section#gallery>.SliderWrapper .carousel.carousel-slider .control-arrow {
    background: black;
    opacity: 1;
    height: 100px;
    width: 40px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%)
}

.carousel .control-arrow.control-prev:before,
.carousel.carousel-slider .control-arrow.control-prev:before {
    content: ' ';
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%22-14.5%20385.5%2030%2030%22%3E%0A%20%20%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M3.625%20400.5l-9.375%209.375V413l12.5-12.5-12.5-12.5v3.125%22/%3E%0A%3C/svg%3E%0A");
    height: 26px;
    border: 0;
    width: 26px;
    background-size: 26px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    margin-left: 0px;
}

.carousel .control-arrow.control-next:before,
.carousel.carousel-slider .control-arrow.control-next:before {
    content: ' ';
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%22-14.5%20385.5%2030%2030%22%3E%0A%20%20%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M3.625%20400.5l-9.375%209.375V413l12.5-12.5-12.5-12.5v3.125%22/%3E%0A%3C/svg%3E%0A");
    height: 26px;
    border: 0;
    width: 26px;
    background-size: 26px;
    margin-right: 0px;
}

section#gallery>.SliderWrapper .carousel .slide .legend {
    width: 100%;
    left: 0;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    margin: 0px;
    bottom: 0;
    opacity: 1;
    border-radius: 0px;
    background: white;
    color: black;
    text-align: left;
    padding: 20px
}

section#gallery>.SliderWrapper .carousel .slide .legend h2 {
    font-size: 1.2rem;
    font-family: "LandsecBrownWeb-Bold" !important;
}

.carousel.carousel-slider {
    height: 100%;
}

#eventSpacesSlider .carousel.carousel-slider .control-arrow {
    height: 25px;
    margin: 5%;
    top: 50%;
    opacity: 0.8;
}

#eventSpacesSlider .carousel.carousel-slider .control-arrow:hover {
    background: rgba(0, 0, 0, 0);
    opacity: 1;
}

section#eventSpaces .opportunities-block-1>.opportunity-block {
    align-items: center;
}

section#eventSpaces .opportunities-block-1>.opportunity-block .text {
    margin-top: 10px;
}

section#eventSpaces .opportunities-block-1>.opportunity-block>div>div:first-child {
    display: none;
}

section#eventSpaces .opportunities-block-1>.opportunity-block h2 {
    margin-top: 0px;
    margin-bottom: 20px;
    text-align: center;
}

section#centre-specific-page{
    width: 100vw;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-content: start;
    width: 100%;
    height: auto;
    position: absolute;
}

section#centre-specific-page .grid-item-jolly{
    background-color:black;
}

section#centre-specific-page .ModelImage {
    background-size: auto 90%;
    background-repeat: no-repeat;
    background-size: contain;
}

.centre-specific-page-detailsWrapper{
    padding:50px;
    line-height: 1.3;
    color:white;
    font-size: 1.5em;
    line-height: 1.5em;
    display: flex;
    flex-flow: wrap;
    margin: 0 auto;
    text-align:left;
    height: 100%;
    /* width: 100%; */
    display: block;
    flex: 1 1 auto;
}

.overview-block-01.grid-item-b{
    order: 1;
    /* height: 500px; */
}
.overview-block-02{
    order: 3;
}
.overview-block-03{
    order: 2;
}

.centre-specific-page-keyfact-wrapper{
    padding-left:45px;
    position: relative;
    /* flex: 0 0 100%; */
    margin-bottom: 40px;
    justify-content: flex-end;
    display: start;
}

.centre-specific-page-keyfact-details{
    display: none;
    background: white;
    color:black;
    padding:15px;
    font-size: 1.2rem;
    width: 100%;
    z-index: 9999;
    margin-left: -30px;
    margin-top: 10px;
}

.centre-specific-page-keyfact-controls{
    display:none;
}

.centre-specific-page-keyfact-details-heading{
    font-family: "LandsecBrownWeb-Bold";
    font-size: 1.5rem;
}

.centre-specific-page-keyfact-details-text{
    margin-bottom: 10px;
}

.centre-specific-page-keyfact-wrapper.active .centre-specific-page-keyfact-controls:checked + div > .centre-specific-page-keyfact-details{
    display: block;
}
.centre-specific-page-keyfact-wrapper.active .centre-specific-page-keyfact-controls:checked + div > .centre-specific-page-keyfact-title,
.centre-specific-page-keyfact-wrapper.active .centre-specific-page-keyfact-controls:checked + div > .centre-specific-page-keyfact-subtitle{
    display: none;
}

.centre-specific-page-keyfact-title{
    font-size: 1.5rem;
    font-family: "LandsecBrownWeb-Bold";
    line-height: 1;
    text-align: left;
}

.centre-specific-page-keyfact-subtitle{
    color:black;
    font-size: 1rem;
    margin-left: 52px;
    text-align: left;
}

.centre-specific-page-keyfact-icon{
    background-image: url(https://landsec-connect-cms.boxxed.com/media/1209/keyfacts_visitorfrequency.svg);
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    float: left;
    margin-right: 20px;
   
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
    width: 100%;
}
.lazy-load-image-background.blur.lazy-load-image-loaded > img {
    width: 100%;
    /* max-height: 100vh; */
}

.controlsLevelSelector {
    display: flex;
    height: 66px;
    width: 20px;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    font-size: 2rem;
    color: white;
}

.caseStudyText {
    font-family: "LandsecBrownWeb-Regular";
}

.escTexty {
    font-family: "FM-Light";
}

.caseStudyImgWrapper {
    margin: 0 auto;
    max-width: 200px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.dialog-cover-wrapper {
    position: relative;
    overflow: auto;
    width: 100% !important;
    height: 100%;
    padding: 20px;
}

.dialog-cover-wrapper img {
    /* transform: translateX(-50%) translateY(-50%) scale(1);
    left: 50%;
    top: 50%;
    position: absolute; */
}

.imageInModal img {
    height: 100%;
}
.cover-wrapper.modal .imgWrapperModal   {
    width: 100%;
    max-width: 100%;
}

.grid-item-b.centre-specific-page-details-container {
    height: auto;
}

.centre-specific-page-details-container .carousel .slide{
    background-color: rgba(0, 0, 0, 0 )
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
    opacity:1;
}

section#centre-specific-page .grid-item-jolly {
    display: block;
    /* min-height: 350px; */
}


.leasing-map-wrapper{
    position: relative;
}
.leasing-map-btn {
    position: absolute!important;
    bottom: 40px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.case-studies-wrapper{
    background-color: #404040;
    display: block;
    flex-direction: column;
    height: 100%;
}

.case-studies-wrapper-content{
    flex: 0 0 50%;
    position:relative;
    overflow: hidden;
    z-index: 1;
}

.case-studies-wrapper-content:before{
    content: '';
    position: absolute;
    width: 200px;
    height: 350px;
    left:-80px;
    top: -130px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    background: rgba(0, 0, 0, 0.5 );
    z-index: -1;
}

.case-studies-wrapper-content-name, .case-studies-wrapper-content-logo, .case-studies-wrapper-content-summary {
    margin:50px;
    color:white;
    /* font-family: "FM-Light"; */
    font-size: 1.5rem;
}

.case-studies-wrapper-content-summary{
    line-height: 1.5em;
    text-align: left;
    margin-top: 0px;
}

.case-studies-wrapper-content-name{
    font-size: 1.8rem;
    display: none;
}
.case-studies-wrapper-bg{
    background-size: cover;
    background-position: center center;
    flex: 0 0 50%;
    min-height: 300px;
    display: none;
}
.case-studies-wrapper-content-logo{
    width: 60%;
    height: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: 0 auto;
}

.carousel.carousel-slider .control-arrow:hover {
    background: rgba(0,0,0,0);
}

.FilterName{
    font-size: 2rem;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide{
    display: flex;
    justify-content: center;
}

.mapImage{
    position: absolute;
    left: 0px;
    top: 50px;
    width: 100%; 
    height: 300px;
    background-size: contain;
    background-repeat: no-repeat;
}

.rto-btn{
    position: absolute!important;
    bottom: 30px;
    left: 30px;
    width: 155px;
    text-align: left;
    line-height: 1;
}

.rto-btn.leasing{
    /* left:0;
    bottom: 0; */
}


.connectDetail.grid-item-b{
    min-height: 300px!important;
    height: auto!important;
    background-color: #262b44;
    padding-bottom: 50px;
}

.leasing-map-btn {
    position: absolute!important;
    bottom: 30px;
    left: 30px;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
}



/* Mobile first queries */

/* Larger than mobile */

@media (min-width: 400px) {}

/* Larger than phablet */

@media (min-width: 550px) {}

/* Larger than tablet */

@media (min-width: 768px) {}

/* Larger than desktop */

@media (min-width: 992px) {
    section h2 {
        font-size: 2.3rem;
    }
    section h3,
    #popUpsList {
        font-size: 1.3rem;
    }
    .centreSpecificContent section,
    .centreSpecificContent .section {
        /* width: 66.6666%; */
        width: 75%;
        flex-direction: row;
    }
    .overview-block-1 {
        flex: 1 1;
    }
    .overview-block-2 {
        flex: 1 1;
        flex-direction: column;
        display: flex;
    }
    .overview-block-2-1 {
        flex: 1 1;
        height: 600px;
        background-size: cover;
        background-position: center center;
    }
    .overview-block-2-2 {
        flex: 1 1;
        height: 600px;
        background-color: #262626;
        line-height: 1.5em;
        color: white;
        padding: 40px 50px;
        z-index: 999;
        position: relative;
        overflow-y: auto;
    }
    .overview-block-2-1,
    .overview-block-2-2 {
        font-size: 1.5rem;
    }
    .keyfacts-details-wrapper .small {
        margin-top: 4px;
    }
    section#keyfacts {
        flex-direction: column;
        position: relative;
        height: 100%;
    }
    .keyfacts-block-2-x.active {
        width: 250px;
    }
    .keyfacts-block-1 {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
    .keyfacts-block-2 {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
    }
    .keyfacts-block-1 img,
    .keyfacts-block-1 .LazyLoad {
        width: 350px;
    }
    .keyfacts-block-2 .keyfacts-block-2-x.hand {
        cursor: pointer;
    }
    .keyfacts-block-2 .keyfacts-block-2-x {
        position: absolute;
        left: 50%;
        top: 50%;
        opacity: 0;
        -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);
        -webkit-animation: fadein 2s;
        animation: fadein 2s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
        /* max-height: 400px;
        overflow-y: auto;
        position: absolute; */
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(1) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -127, -158) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -127, -158) scale(1.3);
        -webkit-animation-delay: 0.3;
                animation-delay: 0.3
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(2) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 76, -235) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 76, -235) scale(1.3);
        -webkit-animation-delay: 0.6s;
                animation-delay: 0.6s
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(3) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 253, -158) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 253, -158) scale(1.3);
        -webkit-animation-delay: 0.9s;
                animation-delay: 0.9s
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(4) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 290, 37) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 290, 37) scale(1.3);
        -webkit-animation-delay: 1.1s;
                animation-delay: 1.1s
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(5) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 177, 164) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 177, 164) scale(1.3);
        -webkit-animation-delay: 1.4s;
                animation-delay: 1.4s
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(6) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -45, 191) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -45, 191) scale(1.3);
        -webkit-animation-delay: 1.7s;
                animation-delay: 1.7s
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(7) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -245, 36) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -245, 36) scale(1.3);
        -webkit-animation-delay: 2s;
                animation-delay: 2s
    }
    .opportunities-block-1,
    .opportunities-block-0,
    .opportunities-block-2,
    .opportunities-block-3,
    .opportunities-block-99,
    section#eventSpaces .opportunities-block-2 {
        width: 33.3333%;
        height: 600px;
        height: 50vh;
        flex-direction: column;
    }
    .opportunities-block-0 {
        height: 300px;
        height: 25vh;
        min-height: 25vh;
        width: 100%;
    }
    .opportunities-block-2,
    section#eventSpaces .opportunities-block-2 {
        width: 66.6666%;
    }
    section#eventSpaces .opportunities-block-2 {
        flex-direction: row;
    }
    section#eventSpaces .opportunities-block-2 .opportunities-block-1 {
        width: 50%;
    }
    .opportunities-block-3,
    .opportunities-block-99 {
        width: 100%;
    }
    .opportunities-block-99 {
        height: 100%;
    }
    .opportunities-block-1.wrapper {
        height: 50%;
    }
    body,
    main,
    #root,
    .body {
        /* min-height: 1200px; */
        height: 100vh;
    }
    .event-space-container {
        flex-direction: row;
    }
    .event-space-container .block-1 {
        flex: 1 1;
        min-height: auto;
    }
    .trianlgeCorner {
        border-width: 5vw 5vw 0 0;
    }
    .event-space-container .block-1.imageWrapper {
        padding: 0px 20px;
    }
    #eventSpacesSlider .slideItem {
        display: flex;
        flex-direction: row;
    }
    .event-space-container .block-1 .details {
        padding-left: 70px;
        width: 100%;
        height: 100%;
        font-size: 1.3rem;
    }
    #eventSpacesSlider #summary,
    #eventSpaces .opportunities-block-1,
    #eventSpaces .opportunities-block-3,
    .leasing-wrapper {
        padding: 30px;
    }
    .leasing-wrapper {
        height: 100%;
    }
    .trianlgeCorner {
        -webkit-transform: translateX(-10%) translateY(-10%);
                transform: translateX(-10%) translateY(-10%);
        border-width: 150px 150px 0 0;
    }
    .event-space-container .block-1.imageWrapper {
        margin-bottom: 0px;
    }
    section#leasing{
        height: 100vh;
    }
    section#leasing .cover-wrapper {
        width: 100%;
        height: 100vh;
        background-size: cover;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);
    }
    section#leasing .cover-wrapper.modal {
        /* z-index: 99; */
        cursor: pointer;
    }
    section#leasing .cover-wrapper.modal .imgWrapperModal, .cover-wrapper.modal .imgWrapperModal   {
        width: 100%;
        max-width: 100%;
        /* max-height: 100vh;
        overflow: hidden; */
    }
    .LevelSelectorPanelWrapper {
        position: absolute;
        right: 5%;
        bottom: 5%;
        /* align-items: flex-end; */
        z-index: 99;
        margin-top: 0px;
    }
    .FloorNameLabel {
        padding-left: 40px;
        margin-right: 40px;
    }
    .LevelSelectorWrapper {
        position: relative;
        height: 20px;
        width: 150px;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }
    .LevelSelectorWrapper:hover #LevelSelector,
    .LevelSelectorWrapper:hover #levSelectorShadow {
        -webkit-transform: translateY(-50%) matrix(1, 0, 0, 1, -10, 1);
                transform: translateY(-50%) matrix(1, 0, 0, 1, -10, 1);
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    .leasing-wrapper .topContent {
        position: absolute;
        top: 0px;
        left: 0px;
        color: white;
        display: block;
        padding: 30px;
    }
    .leasing-wrapper .bottomContent {
        position: absolute;
        bottom: 0px;
        left: 0px;
        display: block;
        padding: 30px;
    }
    /* section#gallery>.SliderWrapper .carousel.carousel-slider .control-arrow:hover,
    section#gallery>.SliderWrapper .carousel.carousel-slider .control-arrow {
        transform: translateY(0px)
    } */
    /* .centreSpecificContent section,
    .centreSpecificContent .section {
        font-size: 12px; 
    } */
    .opportunity-block .title {
        font-family: "LandsecBrownWeb-Bold";
    }
    .opportunity-block .BackWrapper .text {
        font-size: 1.5rem;
        line-height: 2rem;
        color: black;
    }
    #eventSpacesSlider .carousel.carousel-slider .control-arrow {
        bottom: 0;
        top: auto;
    }
    section#eventSpaces .opportunities-block-1>.opportunity-block>div>div:first-child {
        display: block;
    }
    section#eventSpaces .opportunities-block-1>.opportunity-block {
        align-items: start;
    }
    section#eventSpaces .opportunities-block-1>.opportunity-block h2 {
        margin-top: 20px;
        text-align: left;
    }
    #eventSpacesSlider #summary,
    #eventSpacesSlider #sliderImg {
        order: 1;
    }
    .event-space-container .details span {
        line-height: 1.3em;
    }
    .lazy-load-image-background.blur.lazy-load-image-loaded {
        width: auto;
    }
    .controlsLevelSelector {
        display: none;
    }
    section#leasing {
        display: flex;
        overflow: hidden;
    }
    .caseStudyImgWrapper {
        margin-left: 0px;
    }
    .imageInModal img {
        height: auto;
    }


    .centre-specific-page-keyfact-wrapper.active .centre-specific-page-keyfact-controls:checked + div > .centre-specific-page-keyfact-details{
        display: block;
    }

    .centre-specific-page-keyfact-wrapper{
        /* flex: 1 1 50%; */
        /* flex-grow: 0;
        flex-shrink: 0; */
        flex-basis: 50%;
        /* width: 20%; */
        position: relative;
        /* width: 75%; */
        font-size: 1.3rem;
        margin-bottom: 20px;
        padding-left: 0px;  
    }

    .centre-specific-page-keyfact-wrapper:last-child{
        margin-bottom: 0px;
    }

    .grid-item-b.centre-specific-page-details-container {
        /* overflow-y: scroll; */
        overflow:hidden;
    }

    .grid-item-b.centre-specific-page-details-container{
        justify-content: center;
        display: flex;
        align-items: center;
        height: 50vh;
    }

    .centre-specific-page-keyfact-title, .case-studies-wrapper-content-summary{
        font-size: 1.3rem;
        text-align: left;
    }
    .centre-specific-page-keyfact-subtitle {
        font-size: 1rem;
        text-align: left;
    }

    .leasing-map-btn {
        position: absolute!important;
        bottom: 40px;
        right: 40px;
        left: auto;
        left: initial;
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }

    .case-studies-wrapper{
        background-color: #404040;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .case-studies-wrapper-bg{
        min-height: 300px;
        display: block;
    }

    .case-studies-wrapper-content-name{
        display: block;
    }
   w
    .SliderContainer {
        width: 50vw;
        height: 50vh;
    }

    .carousel .slider-wrapper.axis-horizontal .slider .slide{
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .carousel .slider-wrapper, .carousel .slider-wrapper.axis-horizontal .slider .slide{
        height: 50vh;
    }

    .case-studies-wrapper-content-name, .case-studies-wrapper-content-logo, .case-studies-wrapper-content-summary {
        margin: 30px;
    }
    .case-studies-wrapper-content-name{
        margin-bottom: 0px;
    }
    .case-studies-wrapper-content-logo{
        margin-top: 10px;
        height: 150px;
    }

    .case-studies-wrapper-content-summary{
        line-height: 1.4em;
    }

    .overview-block-02{
        order: 2;
    }
    .overview-block-03{
        order: 3;
    }

    .listContacts {
        font-size: 1rem;
    }

    .centreSpecificContent .hidden{
        display: none!important;
    }
    section#leasing.full{
        width: 100%;
        overflow-y: auto;
    }

    .mapImage{
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);
        width: 60%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .full   .mapImage, .mapImage.FullScreen{
        width: 100%;
        top: 0px;
        -webkit-transform: translateX(-50%) ;
                transform: translateX(-50%) ;
    }
    

    .mapImage.FullScreen{
        height:100%;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);
        background-size: cover;
        
    
    }

    .full .bottomContent, .full .topContent, .full .LevelSelectorPanelWrapper{
        position: fixed;
    }

    .Slider-Flex{
        /* flex: 1 1 auto; */
        width: 100%;
    }

    .centre-specific-page-detailsWrapper {
        max-width: 80%;
        display: flex!important;
        overflow: hidden;
        flex-direction: row;
        width: 40vw!important;
        padding-left: 0px;
        padding-right: 0px;
        max-width: 40vw!important;
        overflow: hidden;
        justify-content: center;
        /* flex-direction: column; */
    }

    section#centre-specific-page .grid-sizer-b,section#centre-specific-page .grid-item-b,section#centre-specific-page .grid-item-1-1,section#centre-specific-page .grid-item-1-2,section#centre-specific-page .grid-item-2-1,
    section#centre-specific-page .grid-item-3-1,section#centre-specific-page .grid-item-4-1{
        min-height: 490px;
    }

    .connectionDetail {
        max-width: 100%;
        width: 100%;
        display: block;
    }
    .SliderContainer{
        width: 100%;
    }
   
}

/* Larger than Desktop HD */

@media (min-width: 1200px) {
    .keyfacts-block-1 img,
    .keyfacts-block-1 .LazyLoad {
        width: 800px;
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(1) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -298, -234) scale(1.2);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -298, -234) scale(1.2);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(2) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 75, -370) scale(1.2);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 75, -370) scale(1.2);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(3) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 399, -223) scale(1.2);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 399, -223) scale(1.2);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(4) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 428, 63) scale(1.2);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 428, 63) scale(1.2);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(5) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 257, 306) scale(1.2);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 257, 306) scale(1.2);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(6) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -158, 302) scale(1.2);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -158, 302) scale(1.2);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(7) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -387, 72) scale(1.2);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -387, 72) scale(1.2);
    }
    /* Hard Fix */
    .Id-00292319-595d-445a-b60e-66f27085c149 .lazy-load-image-background {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
    .Id-00292319-595d-445a-b60e-66f27085c149 .keyfacts-block-2 .keyfacts-block-2-x:nth-child(6) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -346, 326) scale(1.2);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -346, 326) scale(1.2);
    }
    section#leasing .cover-wrapper {
        width: 80%;
        max-width: 100%;
        background-size: contain;
    }

    #leasing.full .cover-wrapper  {
        width: 100%!important;
    }

    .centre-specific-page-details-container {
        /* padding:90px; */
    }

    .centre-specific-page-details-container .slide{
        display: flex;
        justify-content: center;
        text-align: left;
    }

  
/* 
    .centre-specific-page-details-container .slide > div{
    } */

    .centre-specific-page-detailsWrapper{
        font-size: 1.5rem;
        margin-bottom: 20px;
        padding-left: 0px;  
    }
  
    .centre-specific-page-keyfact-title{
        font-size: 1.7rem;
        text-align: left;
    }
    .centre-specific-page-keyfact-subtitle {
        font-size: 1.3rem;
        text-align: left;
    }
   

    .case-studies-wrapper-content-name, .case-studies-wrapper-content-logo, .case-studies-wrapper-content-summary {
        margin: 10px 50px;
    }

    .case-studies-wrapper-content-name{
        margin-top: 50px;
    }
   
}

@media (min-width: 1680px) {
    .keyfacts-block-1 img,
    .keyfacts-block-1 .LazyLoad {
        width: 900px;
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(1) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -393, -265) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -393, -265) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(2) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 75, -410) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 75, -410) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(3) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 505, -261) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 505, -261) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(4) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 549, 53) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 549, 53) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(5) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 333, 313) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 333, 313) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(6) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -183, 348) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -183, 348) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(7) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -521, 50) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -521, 50) scale(1.3);
    }
    /* Hard Fix */
    .Id-00292319-595d-445a-b60e-66f27085c149 .lazy-load-image-background {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
    .Id-00292319-595d-445a-b60e-66f27085c149 .keyfacts-block-2 .keyfacts-block-2-x:nth-child(6) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -392, 380) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -392, 380) scale(1.3);
    }
    .Id-f772fb9f-bd97-438b-87a6-3c84d79f0fbd .lazy-load-image-background {
        -webkit-transform: translateY(-61px);
                transform: translateY(-61px);
    }
    section#leasing .cover-wrapper {
        width: 100%;
        max-width: 100%;
    }
    #eventSpacesSlider #summary,
    #eventSpaces .opportunities-block-1,
    #eventSpaces .opportunities-block-3,
    .leasing-wrapper {
        padding: 60px;
    }
    .leasing-wrapper {
        height: 100%;
    }
    .trianlgeCorner {
        -webkit-transform: translateX(-25%) translateY(-25%);
                transform: translateX(-25%) translateY(-25%);
    }
    .event-space-container .details span {
        line-height: 1.5em;
    }
    .event-space-container .block-1 .details {
        font-size: 1.5rem;
    }
    section#eventSpaces .opportunities-block-1>.opportunity-block h2 {
        margin-top: 40px;
    }
    .overview-block-2-1,
    .overview-block-2-2 {
        font-size: 1.8rem;
    }

  

 

    .case-studies-wrapper-content-logo{
        height: 200px;
    }

    .centre-specific-page-detailsWrapper{
        line-height: 1.6;
        font-size: 1.7rem;
        margin-bottom: 40px;

    }

    .centre-specific-page-keyfact-subtitle {
        font-size: 1.5rem;
    }

    .listContacts {
        font-size: 1.3rem;
    }

    .rto-btn{
        bottom: 60px;
        left: 60px;
    }
}

@media (min-width: 2000px) {
    section h2 {
        font-size: 3rem;
    }
    section h3,
    #popUpsList {
        font-size: 1.5rem;
    }
    .keyfacts-block-1 img,
    .keyfacts-block-1 .LazyLoad {
        width: 900px;
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(1) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -393, -265) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -393, -265) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(2) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 75, -410) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 75, -410) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(3) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 541, -268) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 541, -268) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(4) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 660, 53) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 660, 53) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(5) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 333, 313) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 333, 313) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(6) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -183, 348) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -183, 348) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(7) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -590, 50) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -590, 50) scale(1.3);
    }
    /* Hard Fix */
    .Id-00292319-595d-445a-b60e-66f27085c149 .keyfacts-block-2 .keyfacts-block-2-x:nth-child(2) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 38, -410) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 38, -410) scale(1.3);
    }
    .Id-00292319-595d-445a-b60e-66f27085c149 .keyfacts-block-2 .keyfacts-block-2-x:nth-child(6) {
        -webkit-transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -430, 396) scale(1.3);
                transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -430, 396) scale(1.3);
    }
    .centreSpecificContent section,
    .centreSpecificContent .section {
        font-size: 1rem;
    }
    .overview-block-1 {
        flex: 2 1;
    }
    .overview-block-2 {
        flex: 1 1;
    }

    .case-studies-wrapper-content-name, .case-studies-wrapper-content-logo, .case-studies-wrapper-content-summary {
        margin: 40px 40px;
    }

    .centre-specific-page-keyfact-title, .case-studies-wrapper-content-summary{
        font-size: 1.7rem;
    }
}

@media (max-height: 900px) and (min-width: 992px) {
    .case-studies-wrapper-content-logo{
       display: none;
    }
    .case-studies-wrapper-content-name{
        margin-bottom: 20px;
        margin-top: 60px;
        font-weight: bold;
    }
    .case-studies-wrapper-content:before{
        left: -133px;
    }

    section#leasing{
        min-height: 980px;
    }

    .SliderContainer {
        height: 100%;
    }

    .carousel .slider-wrapper, .carousel .slider-wrapper.axis-horizontal .slider .slide{
        height: 100%;
    }
    .carousel .slider-wrapper.axis-horizontal .slider{
        height: 100%;
    }
}

@-webkit-keyframes show-more-animation-plus {
    0% {
        font-size: 13px;
    }
    40% {
        font-size: 15px;
    }
    100% {
        font-size: 13px;
    }
}

@keyframes show-more-animation-plus {
    0% {
        font-size: 13px;
    }
    40% {
        font-size: 15px;
    }
    100% {
        font-size: 13px;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */

/* Opera < 12.1 */
section#contactPage {
    position: relative;
    min-height: 100%;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white !important;
    text-align: center;
    /* background-image: url(https://st3.depositphotos.com/1002969/12792/i/950/depositphotos_127922342-stock-photo-palladium-shopping-center-interior-view.jpg); */
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: center!important;
    color: white;
    flex-direction: row;
}

section#contactPage:after{
    content: '';
    position: absolute;
    left:0px;
    top:0px;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
}

section#contactPage .getInTouchLink a, section#contactPage .footerContactPageWrapper a, section#contactPage .getInTouchLink a:hover, .getInTouchEmail a:hover{
    color: white;
}

section#contactPage .logoWrapper {
    position: absolute;
    background: black;
    left: 0px;
    top: 0px;
    width: 33.333vw;
    z-index: 999;
}

.getInTouchWrapper {
    z-index: 999;;
}

.getInTouchLink {
    display: inline-block;
    position: relative;
    text-align: center;
    padding-left: 60px;
    margin-top: 50px;
}

.getInTouchLink:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background-image: url(/static/media/Icon_Connect_Email_White.4eabc60d.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.getInTouchEmail a {
    color:white;
    /* font-size: 2rem; */
}

.getInTouchLink a {
    color: black;
}

.getInTouchLink a:hover {
    opacity: 0.9;
    color: black;
    text-decoration: underline;
}

.footerContactPageWrapper {
    position: absolute;
    bottom: 60px;
    left: 60px;
    z-index: 99;
}

.footerContactPageWrapper a {
    position: relative;
    margin: 0px 20px;
    color: black;
}

.footerContactPageWrapper a:hover {
    color: black;
}

.footerContactPageWrapper a:after {
    position: absolute;
    content: '';
    height: 1px;
    width: 0px;
    bottom: -2px;
    left: 0px;
    background-color: white;
    -webkit-transition: width .4s;
    transition: width .4s;
}

.footerContactPageWrapper a:hover:after {
    width: 100%;
}

section#TextPage {
    height: 100vh;
    overflow: auto;
    background: rgba(0,0,0,0) !important;
}

section#TextPage .logoWrapper {
    position: absolute;
    width: 100%;
}

.textPageWrapper {
    max-width: 1140px;
    width: 100%;
    padding: 20px;
    padding-top: 150px;
    text-align: left;
    margin: 0 auto;
    min-height: 100vh;
    position: relative;
    display: flex;
    z-index: 9;
    padding-bottom: 150px;
}

.textPageWrapper a {
    color: white;
    font-weight: bold;
}

.textPageWrapper a:hover {
    color: white;
    text-decoration: underline;
}

h2.textPageWrapper-Title {
    text-align: center;
    font-family: "LandsecBrownWeb-Bold" !important;
    margin-bottom: 20px;
}

/* Mobile first queries */

/* Larger than mobile */

@media (min-width: 400px) {}

/* Larger than phablet */

@media (min-width: 550px) {}

/* Larger than tablet */

@media (min-width: 768px) {}

/* Larger than desktop */

@media (min-width: 992px) {
    section#contactPage h1 {
        font-size: 3rem;
    }
    .textPageWrapper {
        padding-top: 250px !important;
        padding-bottom: 100px;
    }
}

/* Larger than Desktop HD */

@media (min-width: 1200px) {}

@media (min-width: 1680px) {}

@media (min-width: 2000px) {
    section#contactPage h1 {
        font-size: 4rem;
    }
}
.MapFiltered-menu{
    width: 100%;
    /* margin: 0 10%; */
    padding:0px;
}


.MapFiltered-subMenu a, .MapFiltered-subMenu .text{
    color:black;
    line-height: 0px;
    z-index: 999;
    position: relative;
}


.MapFiltered-subMenu a, .MapFiltered-subMenu .text .dots{
    content: ".";
    position: absolute;
    height: 20px;
    width:20px;
    font-size: 50px;
    right: -20px;
    top: 0px;
}

.centreSpecificContent .MapFiltered-subMenu a, .centreSpecificContent .MapFiltered-subMenu .text{
    color: white;
}

.centreSpecificContent .MapFiltered-subMenu .checkbox {
    z-index: 999;
    position: relative;
}

.centreSpecificContent .MapFiltered-subMenu input[type="checkbox"]:checked+span .checkbox {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 11.3 7.5' style='enable-background:new 0 0 11.3 7.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Ctitle%3EUntitled-2%3C/title%3E%3Cpolygon class='st0' points='9.9,0 3.8,6.1 1.4,3.8 0,3.8 3.8,7.5 11.3,0 '/%3E%3C/svg%3E%0A");
}

.centreSpecificContent  .MapFiltered-subMenu .checkbox{
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 6.2 6.2' style='enable-background:new 0 0 6.2 6.2;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bclip-path:url(%23SVGID_2_);%7D .st1%7Bfill:none;stroke:%23FFFFFF;stroke-width:0.89;%7D%0A%3C/style%3E%3Ctitle%3EUntitled-1%3C/title%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' y='0' width='6.2' height='6.2'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Cg class='st0'%3E%3Crect x='0.4' y='0.4' class='st1' width='5.3' height='5.3'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}

.MapFiltered-menu input[type="text"]{
    width:100%;
    border:0px;
}

.MapFiltered-menu  ::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
    font-family: "LandsecBrownWeb-Bold", sans-serif !important;
}

.MapFiltered-menu  :-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
    font-family: "LandsecBrownWeb-Bold", sans-serif !important;
}

.MapFiltered-menu  ::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
    font-family: "LandsecBrownWeb-Bold", sans-serif !important;
}

.MapFiltered-menu  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
    font-family: "LandsecBrownWeb-Bold", sans-serif !important;
}
.MapFiltered-container {
    display:none;
}

.MapFiltered-subMenu {
    padding: 30px 0px;
}

.MapFiltered-subMenu {
    font-size: 1.4rem;
    font-family: "LandsecBrownWeb-Regular", sans-serif !important;
    margin: 0px;
    margin-top: 20px;
    padding: 0px;
}

.MapFiltered-subMenu li {
    padding:10px 0px;
    list-style: none;
}

.MapFiltered-subMenu input[type="checkbox"]{
    display:none;
}
.MapFiltered-subMenu .checkbox{
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 6.2 6.2'%3E%3Cdefs%3E%3Cstyle%3E.cls-1,.cls-3%7Bfill:none;%7D.cls-2%7Bclip-path:url(%23clip-path);%7D.cls-3%7Bstroke:%231e1e1c;stroke-width:0.89px;%7D%3C/style%3E%3CclipPath id='clip-path' transform='translate(0 -0.02)'%3E%3Crect class='cls-1' y='0.02' width='6.2' height='6.2'/%3E%3C/clipPath%3E%3C/defs%3E%3Ctitle%3EUntitled-1%3C/title%3E%3Cg class='cls-2'%3E%3Crect class='cls-3' x='0.44' y='0.44' width='5.32' height='5.32'/%3E%3C/g%3E%3C/svg%3E");
    width: 20px;
    height: 20px;
    padding-right: 10px;
    margin-right: 15px;
    display: inline-block;
    background-size: contain;
}

.MapFiltered-subMenu input[type="checkbox"]:checked+span .checkbox{
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11.3 7.53'%3E%3Ctitle%3EUntitled-2%3C/title%3E%3Cpolygon points='9.89 0 3.77 6.12 1.41 3.77 0 3.77 3.77 7.54 11.3 0 9.89 0'/%3E%3C/svg%3E");
    width: 30px;
    margin-right: 5px;
    padding-right: 10px;
}

.MapFiltered-subMenu .cover-wrapper{
    display: none
}

.MapFiltered-subMenu input[type="checkbox"]:checked + span + span + div     {
    display: block!important;
}




.MapFiltered-subMenu li:before {
    display: none;
}

.MapFiltered-container{
    display:block;
}



@media (min-width: 992px) {
    .ghb-wrapper-pink-text ul.MapFiltered-menu > li {
        position: relative;
        margin-left: 0px;
        padding: 30px 0px;
        padding-left: 30px;
        border-bottom:1px solid black;
        text-transform: capitalize;
    }
    .ghb-wrapper-pink-text ul.MapFiltered-menu > li:before{
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='ae7c33e6-af6e-478a-a8da-0ecd3d888618' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' width='12.6' height='25' viewBox='0 0 12.6 25'%3E%3Ctitle%3Earrow2%3C/title%3E%3Cpath d='M18.1,15,8.7,24.4v3.1L21.3,15,8.8,2.5V5.6' transform='translate(-8.7 -2.5)'/%3E%3C/svg%3E");
        position: absolute;
        height: 26px;
        width: 13px;
        left: 0px;
        top: 40px;
        background-size: contain;
        -webkit-transition: 0.35s ease-in-out;
        transition: 0.35s ease-in-out;
    }
    .ghb-wrapper-pink-text ul.MapFiltered-menu > li.active:before{
        -webkit-transform: rotate(90deg); 
        top: 25px;
    }

   
    .ghb-wrapper-pink-text ul.MapFiltered-menu li.search:before{
        background-image: url("data:image/svg+xml,%3Csvg id='Capa_1' data-name='Capa 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50.77 52.66'%3E%3Ctitle%3Esearch2%3C/title%3E%3Cpath d='M51.75,51.32l-14.9-15.5a21,21,0,1,0-1.48,1.35L50.26,52.66c1-.83.61-.51.74-.64s-.07.08.12-.1ZM22,40A19,19,0,1,1,41,21,19,19,0,0,1,22,40Z' transform='translate(-0.98 0)'/%3E%3C/svg%3E");
    }
    .ghb-wrapper-pink-text ul.MapFiltered-menu li:last-child  {
        border-bottom:0px;
    }
}
.grid-head.black.opportunitiesLandingPageBuilderWrapper {
    height: 50vh !important;
    min-height: 50vh !important;
}

section#opportunitiesLandingPageBuilder {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-content: start;
    width: 100%;
}

section#opportunitiesLandingPageBuilder .opportunities-block-1,
section#opportunitiesLandingPageBuilder .opportunities-block-0,
section#opportunitiesLandingPageBuilder .opportunities-block-2,
section#opportunitiesLandingPageBuilder .opportunities-block-3,
section#opportunitiesLandingPageBuilder .opportunities-block-99 {
    width: 100%;
    min-height: 300px;
    background: #ef3b9c;
    display: flex;
    flex-direction: column;
}

section#opportunitiesLandingPageBuilder .opportunities-block-0 {
    background: #ef3b9c;
    height: 600px;
    width: 100%;
}

section#opportunitiesLandingPageBuilder .opportunities-block-1.wrapper {
    height: 600px;
}

section#opportunitiesLandingPageBuilder .opportunities-block-3,
section#opportunitiesLandingPageBuilder .opportunities-block-99 {
    width: 100%;
}

section#opportunitiesLandingPageBuilder .opportunities-block-99 {
    height: 100%;
}

.my-gallery-class {
    width: 100%;
}

.grid-item-b {
    background-size: cover;
    background-position: center center;
}

.grid-sizer-b,
.grid-item-b,
.grid-item-1-1,
.grid-item-1-2,
.grid-item-2-1,
.grid-item-3-1,
.grid-item-4-1 {
    width: 100%;
    height: 300px;
}

.grid-item-jolly {
    display: none;
}



/* Mobile first queries */

/* Larger than mobile */

@media (min-width: 400px) {}

/* Larger than phablet */

@media (min-width: 550px) {}

/* Larger than tablet */

@media (min-width: 768px) {}

/* Larger than desktop */

@media (min-width: 992px) {
    section#opportunitiesLandingPageBuilder .opportunities-block-1,
    section#opportunitiesLandingPageBuilder .opportunities-block-0,
    section#opportunitiesLandingPageBuilder .opportunities-block-2,
    section#opportunitiesLandingPageBuilder .opportunities-block-3,
    section#opportunitiesLandingPageBuilder .opportunities-block-99,
    section#eventSpaces section#opportunitiesLandingPageBuilder .opportunities-block-2 {
        width: 25%;
        height: 600px;
        height: 50vh;
        flex-direction: column;
    }
    section#opportunitiesLandingPageBuilder .opportunities-block-0 {
        height: 300px;
        height: 25vh;
        min-height: 25vh;
        width: 100%;
    }
    section#opportunitiesLandingPageBuilder .opportunities-block-2 {
        width: 50%;
    }
    section#opportunitiesLandingPageBuilder .opportunities-block-2 {
        flex-direction: row;
    }
    section#opportunitiesLandingPageBuilder .opportunities-block-3 {
        width: 75%;
    }
    section#opportunitiesLandingPageBuilder .opportunities-block-99 {
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
    }
    section#opportunitiesLandingPageBuilder .opportunities-block-1.wrapper {
        height: 50%;
    }
    section#opportunitiesLandingPageBuilder {
        position: absolute;
    }
     .grid-sizer-b,
    .grid-item-b,
    .grid-item-1-2 {
        width: 25%;
        height: 50vh;
    }
    .grid-item-1-1 {
        height: 25vh;
    }
    .grid-item-1-3 {
        height: 75vh;
    }
    .grid-item-1-4,
    .grid-item-4-4 {
        height: 100vh;
    }
    .grid-item-2-2 {
        width: 50%;
    }
    .grid-item-3-2 {
        width: 75%;
    }
    .grid-item-4-2,
    .grid-item-4-4 {
        width: 100%;
    }
    .grid-item-jolly,
    .grid-sizer-b {
        display: block;
        width: 25%;
    }
  
    /*
    .oLPBWrapper-2 .el-0 {
        width: 66.666%;
    }
    .oLPBWrapper-2 .el-2 {
        width: 100%;
    }

    .oLPBWrapper-3 .el-0 {
        width: 33.333%;
    }
    .oLPBWrapper-3 .el-2 {
        width: 66.666%;
    }
   
    .oLPBWrapper-4 .el-0 {
        width: 66.666%;
    }
    .oLPBWrapper-4 .el-1 {
        width: 66.666%;
    }
    .oLPBWrapper-4 .el-2 {
        width: 33.333%;
        height: 100vh;
    }
    .oLPBWrapper-4 .el-3 {
        width: 66.666%;
    }

    .oLPBWrapper-5 .el-0 {
        width: 66.666%;
    }
    .oLPBWrapper-5 .el-3 {
        width: 33.333%;
        height: 100vh;
    }
    .oLPBWrapper-5 .el-4 {
        width: 66.666%;
    }
 
    .oLPBWrapper-6 .el-0 {
        width: 33.333%;
        height: 100vh;
    }
    .oLPBWrapper-6 .el-1 {
        width: 33.333%;
    }
    .oLPBWrapper-6 .el-4 {
        height: 100vh;
    }
    
    .oLPBWrapper-7 .el-5 {
        height: 50vh;
    }
    .oLPBWrapper-7 .el-4 {
        height: 75vh;
    }
    */
}

/* Larger than Desktop HD */

@media (min-width: 1200px) {}

@media (min-width: 1680px) {
    .grid-sizer-b,
    .grid-item-b,
    .grid-item-1-2 {
        width: 25% !important;
        height: 50vh !important;
    }
    .grid-item-1-1 {
        height: 25vh !important;
    }
    .grid-item-1-3 {
        height: 75vh !important;
    }
    .grid-item-1-4,
    .grid-item-4-4 {
        height: 100vh !important;
    }
    .grid-item-2-2 {
        width: 50% !important;
    }
    .grid-item-3-2 {
        width: 75% !important;
    }
    .grid-item-4-2,
    .grid-item-4-4 {
        width: 100% !important;
    }
}

@media (min-width: 768px) {}
.FlipBoxWrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.opportunity-block,
.opportunity-block>.Front,
.opportunity-block>.Back {
    height: 100%;
    width: 200%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-align: left;
}

.opportunity-block>.Front {
    background-image: url(https://landsecconnectcms.azurewebsites.net/media/1182/opps_experiences.jpg);
    background-size: cover;
    background-position: center center;
    -webkit-transition: height 2s;
    transition: height 2s;
}

.opportunity-block>.Back,
.opportunity-block>.Front {
    padding: 30px;
}

.opportunity-block.active>.Back {
    display: flex;
}

.FlipBoxWrapper.active .Back>.BackWrapper {
    max-width: 300px;
}

.opportunity-block .title {
    text-transform: capitalize;
    color: white;
    margin-bottom: 20px;
}

.Opportunity-landig-page .Back {
    background-color: #daa404;
}

.FlipBoxWrapper .Back {
    opacity: 0;
    -webkit-transform: scale(.95);
            transform: scale(.95);
    -webkit-transition: all .8s;
    transition: all .8s;
}

.FlipBoxWrapper:hover .Back,
.FlipBoxWrapper.active .Back {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
}

.FlipBoxWrapper .opportunity-block {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}

.FlipBoxWrapper.active .opportunity-block {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.FlipBoxWrapper .text {
    font-size: 1.2rem;
}

.grid-item-title.small {
    font-size: 1.2rem !important;
    -webkit-animation: fadeIn 2.5s forwards;
            animation: fadeIn 2.5s forwards;
    -webkit-animation-delay: 2.5s;
            animation-delay: 2.5s;
}

.active.grid-item-content:before {
    -webkit-animation: lineSlideIn 2s forwards;
            animation: lineSlideIn 2s forwards;
    -webkit-animation-delay: 2.5s;
            animation-delay: 2.5s;
}

.active.grid-item-content:before {}

@-webkit-keyframes lineSlideIn {
    0% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(25px) scaleX(5);
                transform: translate(25px) scaleX(5);
    }
}

@keyframes lineSlideIn {
    0% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(25px) scaleX(5);
                transform: translate(25px) scaleX(5);
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
        -webkit-transform: scaleX(.8);
                transform: scaleX(.8);
    }
    100% {
        opacity: 1;
        -webkit-transform: scaleX(1);
                transform: scaleX(1);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        -webkit-transform: scaleX(.8);
                transform: scaleX(.8);
    }
    100% {
        opacity: 1;
        -webkit-transform: scaleX(1);
                transform: scaleX(1);
    }
}

/* Mobile first queries */

/* Larger than mobile */

@media (min-width: 400px) {}

/* Larger than phablet */

@media (min-width: 550px) {}

/* Larger than tablet */

@media (min-width: 768px) {}

/* Larger than desktop */

@media (min-width: 992px) {
    .opportunity-block:hover>.Back,
    .opportunity-block.active>.Back {
        display: flex;
    }
    .opportunity-block:hover>.Back>.BackWrapper,
    .opportunity-block.active>.Back>.BackWrapper {
        max-width: 300px;
    }
    .FlipBoxWrapper .opportunity-block {
        -webkit-transition: none;
        transition: none;
    }
    .FlipBoxWrapper:hover .opportunity-block {
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        -webkit-transition: none;
        transition: none;
    }
}

/* Larger than Desktop HD */

@media (min-width: 1200px) {}

@media (min-width: 1680px) {}

@media (min-width: 768px) {}
@font-face {
    font-family: "LandsecBrownWeb-Regular";
    src: url(/static/media/LandsecBrownWeb-Regular.219b26c3.eot);
    src: url(/static/media/LandsecBrownWeb-Regular.219b26c3.eot?#iefix) format("embedded-opentype"), url(/static/media/LandsecBrownWeb-Regular.50e5efa3.woff2) format("woff2"), url(/static/media/LandsecBrownWeb-Regular.af2097b6.woff) format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "FM-Light";
    src: url(/static/media/FM-Light.b193fef6.otf);
    src: url(/static/media/FM-LightWEB.b47a257d.woff?#iefix) format("embedded-opentype"), url(/static/media/FM-LightWEB.7596b446.woff2) format("woff2"), url(/static/media/FM-LightWEB.b47a257d.woff) format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "LandsecBrownWeb-Bold";
    src: url(/static/media/LandsecBrownWeb-Bold.b003f346.eot);
    src: url(/static/media/LandsecBrownWeb-Bold.b003f346.eot?#iefix) format("embedded-opentype"), url(/static/media/LandsecBrownWeb-Bold.9189a12d.woff2) format("woff2"), url(/static/media/LandsecBrownWeb-Bold.fdb730d5.woff) format("woff");
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
    font-family: "LandsecBrownWeb-Regular", sans-serif !important;
    background: white;
    line-height: 1 !important;
}

button, input, optgroup, select, textarea {
    font-family: "LandsecBrownWeb-Regular", sans-serif !important;
    font-weight: normal !important;
    font-weight: initial !important;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: "LandsecBrownWeb-Regular", sans-serif !important;
    font-weight: normal !important;
    font-weight: initial !important;
}

a {
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

i.big.icon,
i.big.icons {
    font-size: 1em !important;
    margin: 0px;
}

.App {
    text-align: center;
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.st-bg {
    background-image: url('https://landsecconnectcms.azurewebsites.net/media/1215/holding-image.png');
    background-size: cover;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

.JustMobile {
    display: block;
}

.text-center {
    text-align: center;
}

#nav-right {
    position: fixed;
    right: 0px;
    flex-direction: row;
    display: flex;
    justify-content: center;
    z-index: 99999999;
}

.nav-r-item {
    height: 60px;
    width: 60px;
    border: 1px solid white;
    /*background: black;*/
    display: inline-block;
    margin-top: -1px;
    align-self: flex-end;
    display: flex;
    background-color: rgba(0, 0, 0, 0);
    -webkit-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
}

.dark .nav-r-item {
    background-color: black;
}

.nav-r-item:last-child {
    border-right: 0px;
}

.nav-r-item,
.nav-r-ite:hover,
a,
a:hover,
a *,
a:hover * {
    color: white;
}

.dark .nav-r-item,
.dark .nav-r-ite:hover,
.dark .nav-r-item a,
.dark .nav-r-item a:hover,
.dark .nav-r-text {
    /* color: black !important; */
}

.nav-r-item .nav-r-text {
    width: 0;
    display: none;
    padding: 16px 15px;
    opacity: 0;
}

.nav-r-item-logo {
    height: auto;
    width: 100%;
    justify-content: center;
    display: flex;
    background: rgba(0, 0, 0, 0.2);
}

.nav-r-item:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.dark .nav-r-item:hover,
.dark .nav-r-item:last-child:hover {
    background-color: rgba(0, 0, 0, .4);
}

#logo-img-nav,
.logo-img-nav {
    /* width: 100%; */
    max-width: 30px;
}

.closeBtn.ui.negative.button {
    position: absolute;
    right: -91px;
    top: -76px;
    background: rgba(0, 0, 0, 0);
    font-size: 40px;
    font-family: "LandsecBrownWeb-Bold";
}

.closeBtn.ui.negative.button:hover {
    background: rgba(0, 0, 0, 0);
}


.dark-btn{
    position: relative;
    background: #1c1c1b;
    padding: 15px 20px;
    display: inline-block;
    color: white;
    overflow: hidden;
    font-size: 1.2rem;
    cursor: pointer;
}

.dark-btn.l{
    padding-left: 60px;
    cursor: pointer;
}

.dark-btn.r{
    padding-right: 60px;
}

.dark-btn::after{
    position: absolute;
    font-size: 1.2rem;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 10px;
    height: 16px;
    top:50%;
}

.dark-btn.l::after{
    left:25px;
    content: '<';
 }
 .dark-btn.r::after{
    right:25px;
    content: '>';
 }

.dark-btn::before{
   content: '';
   position: absolute;
   left:-50px;
   top:0px;
   width: 100%;
   height: 200%;;
   background-color: rgba(255, 255, 255, 0.1);
   -webkit-transform: rotate(45deg);
           transform: rotate(45deg);
   -webkit-transition:all 0.3s ease;
   transition:all 0.3s ease;
}

.dark-btn:hover::before{
    left:50px;
    width: 200%;
    height: 200%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.fullScreen{
    display: none;
    padding: 20px;
    color: white;
    background: black;
    position: fixed;
    top: -100px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 99999;
    border: 1px solid white;
    cursor: pointer;
    -webkit-transition:all 1s ease;
    transition:all 1s ease;
}

.fullScreen.open{
    top: 20px;
    -webkit-transition:all 1s ease;
    transition:all 1s ease;
    -webkit-animation: fd-in  1s linear;
            animation: fd-in  1s linear;
}

.fullScreen.close{
    top: -100px;
    -webkit-transition:all 1s ease;
    transition:all 1s ease;
    -webkit-animation: fd-out  1s linear;
            animation: fd-out  1s linear;
}


.loader{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    width: 150px;
    z-index: -1;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-webkit-keyframes fd-in {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@keyframes fd-in {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-webkit-keyframes fd-out {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@keyframes fd-out {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

/* Mobile first queries */

/* Larger than mobile */

@media (min-width: 400px) {}

/* Larger than phablet */

@media (min-width: 550px) {}

/* Larger than tablet */

@media (min-width: 768px) {
   
}

/* Larger than desktop */

@media (min-width: 992px) {
    .JustMobile {
        display: none;
    }
    .closeBtn.ui.negative.button {
        right: -117px;
        top: -76px;
        font-size: 40px;
    }

    #nav-right {
        position: fixed;
        color: white;
        right: 0;
        flex-direction: column;
        height: 250px;
    }
    .nav-r-item {
        height: 85px;
        flex: 1 1;
        min-width: 85px;
        width: auto;
        border: 1px solid white;
    }
    .nav-r-item.active {
        background-color: rgba(0, 0, 0, 0.7);
    }
    .dark .nav-r-item.active {
        background-color: rgba(0, 0, 0, .4) !important;
    }
    .nav-r-item:last-child {
        border: 1px solid white;
    }
    .dark .nav-r-item:last-child {
        background-color: black;
    }
    .nav-r-item-logo {
        width: 83px;
        height: 83px;
        display: flex;
        justify-content: center;
        background: rgba(255, 255, 255, 0);
    }
    #nav-right {
        background: rgba(255, 255, 255, 0);
    }
    .nav-r-item .nav-r-text {
        width: 145px;
        opacity: 1;
        transition: opacity 3s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
        -webkit-transition: opacity 3s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
        transition: opacity 3s cubic-bezier(0.23, 1, 0.32, 1), transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
        transition: opacity 3s cubic-bezier(0.23, 1, 0.32, 1), transform 0.5s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    }
    .nav-r-item:hover .nav-r-text {
        display: flex;
        align-items: center;
        opacity: 1;
    }
    #logo-img-nav,
    .logo-img-nav {
        width: auto;
    }

    .dark-btn{
        padding: 18px 25px;
    }

    .fullScreen{
        display: block;
     
    }
    
}

/* Larger than Desktop HD */

@media (min-width: 1200px) {}

@media (min-width: 1680px) {
    .closeBtn.ui.negative.button {
        right: -225px;
        top: -60px;
        font-size: 70px;
    }
}

@media (min-width: 768px) {}

@media (max-height: 900px) and (min-width: 992px) {
    .nav-r-item, .nav-r-item-logo{
        height: 65px;
        width: 65px;
        min-width: 65px;
    }

    #nav-right{
        height: 195px; 
    }
}
