section#contactPage {
    position: relative;
    min-height: 100%;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white !important;
    text-align: center;
    /* background-image: url(https://st3.depositphotos.com/1002969/12792/i/950/depositphotos_127922342-stock-photo-palladium-shopping-center-interior-view.jpg); */
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: center!important;
    color: white;
    flex-direction: row;
}

section#contactPage:after{
    content: '';
    position: absolute;
    left:0px;
    top:0px;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
}

section#contactPage .getInTouchLink a, section#contactPage .footerContactPageWrapper a, section#contactPage .getInTouchLink a:hover, .getInTouchEmail a:hover{
    color: white;
}

section#contactPage .logoWrapper {
    position: absolute;
    background: black;
    left: 0px;
    top: 0px;
    width: 33.333vw;
    z-index: 999;
}

.getInTouchWrapper {
    z-index: 999;;
}

.getInTouchLink {
    display: inline-block;
    position: relative;
    text-align: center;
    padding-left: 60px;
    margin-top: 50px;
}

.getInTouchLink:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background-image: url('../../assets/Images/Connect/Icon_Connect_Email_White.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.getInTouchEmail a {
    color:white;
    /* font-size: 2rem; */
}

.getInTouchLink a {
    color: black;
}

.getInTouchLink a:hover {
    opacity: 0.9;
    color: black;
    text-decoration: underline;
}

.footerContactPageWrapper {
    position: absolute;
    bottom: 60px;
    left: 60px;
    z-index: 99;
}

.footerContactPageWrapper a {
    position: relative;
    margin: 0px 20px;
    color: black;
}

.footerContactPageWrapper a:hover {
    color: black;
}

.footerContactPageWrapper a:after {
    position: absolute;
    content: '';
    height: 1px;
    width: 0px;
    bottom: -2px;
    left: 0px;
    background-color: white;
    transition: width .4s;
}

.footerContactPageWrapper a:hover:after {
    width: 100%;
}

section#TextPage {
    height: 100vh;
    overflow: auto;
    background: rgba(0,0,0,0) !important;
}

section#TextPage .logoWrapper {
    position: absolute;
    width: 100%;
}

.textPageWrapper {
    max-width: 1140px;
    width: 100%;
    padding: 20px;
    padding-top: 150px;
    text-align: left;
    margin: 0 auto;
    min-height: 100vh;
    position: relative;
    display: flex;
    z-index: 9;
    padding-bottom: 150px;
}

.textPageWrapper a {
    color: white;
    font-weight: bold;
}

.textPageWrapper a:hover {
    color: white;
    text-decoration: underline;
}

h2.textPageWrapper-Title {
    text-align: center;
    font-family: "LandsecBrownWeb-Bold" !important;
    margin-bottom: 20px;
}

/* Mobile first queries */

/* Larger than mobile */

@media (min-width: 400px) {}

/* Larger than phablet */

@media (min-width: 550px) {}

/* Larger than tablet */

@media (min-width: 768px) {}

/* Larger than desktop */

@media (min-width: 992px) {
    section#contactPage h1 {
        font-size: 3rem;
    }
    .textPageWrapper {
        padding-top: 250px !important;
        padding-bottom: 100px;
    }
}

/* Larger than Desktop HD */

@media (min-width: 1200px) {}

@media (min-width: 1680px) {}

@media (min-width: 2000px) {
    section#contactPage h1 {
        font-size: 4rem;
    }
}