/*.cls-1,
.cls-3 {
    fill: none;
}

.cls-2 {
    clip-path: url(#clip-path);
}

.cls-3 {
    stroke: #a44396;
    stroke-miterlimit: 10;
    stroke-width: 0.5px;
}

.cls-4 {
    fill: #a44496;
}
*/

body {
    background-color: #262b44 !important;
}

/**Tooltip style*/

.mapboxgl-popup-content {
    padding: 0px;
    background: rgba(255, 255, 255, 0);
}

.mapboxgl-popup-content .mapboxgl-popup-close-button {
    color: white;
    font-size: 2rem;
}

.mapboxgl-popup-content:hover .mapboxgl-popup-close-button {
    color: black;
}

#tooltip,
.mapboxgl-popup-content {
    width: 400px;
    height: 175px;
}

.mapboxgl-popup {
    max-width: 500px !important;
}

.mapboxgl-popup-anchor-right {
    margin-top: -79px;
    margin-left: -136px;
    position: relative;
}

.mapboxgl-popup-anchor-right,
.mapboxgl-popup-anchor-bottom-right {
    margin-top: -85px;
    margin-left: 539px;
    position: relative;
}

.mapboxgl-popup-anchor-bottom-right {
    margin-top: 7px;
}

.mapboxgl-popup-anchor-bottom-right .toolTipLocationWrapper {
    border-bottom: 0px solid white;
}

.mapboxgl-popup-anchor-bottom-right .toolTipLocationWrapper::after {
    height: 124px !important;
}

.mapboxgl-popup-anchor-right::after,
.mapboxgl-popup-anchor-bottom-righ::after {
    position: absolute;
    content: '';
    width: 482px;
    height: 1px;
    background-color: white;
    bottom: 0px;
    right: calc(100% - 344px);
}

.mapboxgl-popup-anchor-right .toolTipLocationWrapper {
    border-bottom: 0px;
}

.mapboxgl-popup-anchor-left {
    margin-top: -85px;
    margin-left: -539px;
    position: relative;
}

.mapboxgl-popup-anchor-top-right {
    margin-top: 16px;
    margin-left: 8px;
    position: relative;
}

.mapboxgl-popup-anchor-bottom-left {
    margin-left: -532px;
    margin-top: 14px;
}

.mapboxgl-popup-anchor-top-left .toolTipLocationWrapper::before {
    right: -255%;
}

.mapboxgl-popup-anchor-left:after,
.mapboxgl-popup-anchor-bottom-left:after,
.mapboxgl-popup-anchor-top-left:after {
    position: absolute;
    content: '';
    width: 191px;
    height: 1px;
    background-color: white;
    bottom: 0px;
    left: calc(100% - 54px);
}

.mapboxgl-popup-anchor-top-left {
    margin-left: -532px;
    margin-top: -163px;
}

.mapboxgl-popup-anchor-top-right {
    margin-top: -164px;
    margin-left: 546px;
}

.mapboxgl-popup-anchor-top-right .toolTipLocationWrapper {
    border-bottom: 0px;
}

.mapboxgl-popup-anchor-bottom-right::after {
    position: absolute;
    content: '';
    width: 482px;
    height: 1px;
    background-color: white;
    bottom: 0px;
    right: calc(100% - 344px)
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-righ .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
    display: none;
}

.tooltip-border {
    fill: none;
}

.tooltip-background {
    fill: #27314a;
}

#tooltip:hover {
    cursor: pointer;
}

#tooltip:hover .tooltip-background {
    fill: #fff;
}

#tooltip:hover .tooltip-text,
#tooltip:hover .tooltip-title {
    fill: black;
}

.tooltip-border {
    stroke: #fff;
    stroke-miterlimit: 10;
    stroke-width: 0.55px;
}

.tooltip-text,
.tooltip-title {
    fill: #fff;
}

.tooltip-text {
    font-size: 4.24px;
}

.tooltip-title {
    font-size: 7.59px;
}

.tooltip-img,
.tooltip-bg-icon {
    fill: #231f20;
}

/*//Tooltip style*/

#MapWrapper {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.loc {
    cursor: pointer;
}

.loc:hover {
    fill: white;
}

.img-tooltip {
    width: 150px;
    height: 146px;
    background-position: center center;
    background-size: cover;
}

.__react_component_tooltip {
    padding: 0;
    border-radius: 0;
    background-color: #162038 !important;
    color: white !important;
    opacity: 1 !important;
    border: 1px solid white;
    margin-top: -73px !important;
}

.__react_component_tooltip:before {
    content: '';
    position: absolute;
    width: 150px;
    height: 1px;
    background-color: white;
    left: -151px !important;
    bottom: -1px;
    border: 1px !important;
    top: auto !important;
}

.__react_component_tooltip:after {
    border: 0 !important;
}

.__react_component_tooltip.place-right {
    margin-left: 151px;
}

.marker {
    background-color: #a93a8c;
    background-size: cover;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -6px;
    margin-left: -6px;
    transition: 0.2s ease-in-out;
}

.marker:hover {
    width: 15px;
    height: 15px;
    margin-left: -7px;
    margin-top: -7px;
}

.mapboxgl-popup-close-button {
    color: white !important;
    opacity: 0.7;
}

.mapboxgl-popup-content:hover .mapboxgl-popup-close-button {
    color: black !important;
    opacity: 0.7;
}

.mapboxgl-popup-close-button:hover {
    opacity: 1;
}

.marker:hover,
.marker:hover:active,
.marker.active,
.marker:hover:focus {
    background-color: white;
}

.marker.inactive:hover{
    background-color: #a93a8c;  
}

.mapboxgl-popup {
    max-width: 200px;
}

.mapboxgl-popup:hover {
    z-index: 9;
}

.mapboxgl-popup-content {
    text-align: center;
}

.helper-coord {
    display: none;
    z-index: 999999;
    text-align: right;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 20px;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
    display: none;
}

.mapBoxWrapper {
    display: none;
}

.mapBoxRowWrapper {
    display: inline-block;
    padding: 30px;
    color: white;
    /* background: #162038; */
}

.center-detail-container-row {
    border-bottom: 1px solid white;
    margin: 20px 0px;
    margin-top: 10px;
    padding-bottom: 20px;
    display: block;
    min-height: 155px;
    width: 100%;
}

.center-detail-container-row:last-child {
    border: 0px;
    padding-bottom: 0px;
}

.center-detail-column {
    margin-top: 1rem !important;
}

.center-detail-icon-bg {
    /* background-color: black; */
    background-image: url('http://landsecconnectcms.azurewebsites.net/media/1006/icon_menu_connect.svg');
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center center;
    height: 30px;
    width: 30px;
    margin-right: 10px;
    border-radius: 50%;
}

.center-detail-center-name {
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 1.2;
}

.center-detail-row {
    display: flex;
    flex-direction: row;
}

.center-detail-body {
    line-height: 1em;
}

.center-detail-numb {
    font-weight: bold;
    font-size: 1rem;
}

.center-detail-desc {
    /* font-size: 0.7rem; */
    font-size: 1rem;
    line-height: 1.4;
}

.row.center-detail-wrapper {
    padding: 5px 0px !important;
}

.testDiv {
    /* background-image: url('../../../assets/locations/TooltipBorder.svg'); */
    background-size: 118% 101%;
    background-position: center -1px;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
}

#tooltip,
.mapboxgl-popup-content,
.toolTipLocationWrapper {
    width: 395px;
    height: 173px;
}

.toolTipLocationWrapper {
    position: relative;
    overflow: hidden;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    cursor: pointer;
    color: white;
    text-align: left;
}

.mapboxgl-popup-content .mapboxgl-popup-close-button {
    z-index: 99;
}

.toolTipLocationWrapper:hover,
.toolTipLocationWrapper a:hover,
.toolTipLocationWrapper:hover a * {
    color: black;
}

.toolTipLocationWrapper::after {
    content: '';
    position: absolute;
    border-right: 1px solid white;
    width: 1px;
    height: 121px;
    right: 0px;
    top: 0px;
}

.mapboxgl-popup-anchor-left .toolTipLocationWrapper::after,
.mapboxgl-popup-anchor-top-right .toolTipLocationWrapper::after,
.mapboxgl-popup-anchor-bottom-right .toolTipLocationWrapper::after {
    height: 131px;
}

.toolTipLocationWrapper::before {
    content: '';
    position: absolute;
    width: 1100%;
    height: 1100%;
    background: rgba(0, 0, 0, 0);
    bottom: 0;
    right: -258%;
    -webkit-transform-origin: 54% 0;
    -ms-transform-origin: 54% 0;
    transform-origin: 54% 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: -1;
    border: 1px solid white;
    background: #262b44;
}

.toolTipLocationWrapper:hover:before {
    background: white;
}

.toolTipLocationContainer {
    display: flex;
    widows: 100%;
    height: 100%;
}

.LocationImg {
    flex: 1;
    background-size: cover;
    background-position: center center;
}

.LocationDesc {
    flex: 1;
    padding: 15px 25px;
}

.LocationDesc .title {
    font-family: "LandsecBrownWeb-Bold";
}

.listOfSpec {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px 0px;
}

.specItem {
    flex: 1;
    display: flex;
    align-items: center;
}

.specIcon {
    width: 30px;
    height: 30px;
    background-image: url('http://landsecconnectcms.azurewebsites.net/media/1006/icon_menu_connect.svg');
    background-size: 27px;
    background-repeat: no-repeat;
    background-position: center center;
}

.keyFactsTextWrapper {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    font-size: 12px;
}

.keyFactsText{
    white-space: pre-line;
    max-width: 170px;
}

.keyFactsTitle {
    font-family: "LandsecBrownWeb-Bold";
}

.noLinkTrough{
    line-height: 1.4;
    font-size: 0.9rem;
    padding-left: 0;
    margin-left: 9px;
}

.mapboxgl-marker {
    /* z-index: 9; */
}

/* Larger than mobile */

@media (min-width: 400px) {}

/* Larger than phablet */

@media (min-width: 550px) {}

/* Larger than tablet */

@media (min-width: 768px) {}

/* Larger than desktop */

@media (min-width: 992px) {
    #MapWrapper {
        max-width: 100%;
        max-height: 100%;
    }
    .mapBoxWrapper {
        display: block;
        position: absolute;
        overflow: hidden;
        height: 100vh;
        width: calc(100% - 25%)!important;
        right: 0;
    }
    .mapBoxWrapper.Full,  .mapBoxWrapper > div{
        width: 100%!important;
        height: 100%!important;
    }
    .mapBoxRowWrapper {
        display: none;
    }
}

@media (max-height: 900px) and (min-width: 992px) {

    .mapBoxWrapper, .mapBoxWrapper> div{
        min-height: 980px;
    }
}

/* Larger than Desktop HD */

@media (min-width: 1200px) {}

@media (min-width: 1680px) {}

@media (min-width: 768px) {}