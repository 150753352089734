.grid-head {
    float: left;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 196px;
    cursor: pointer;
}

.grid-item,
.grid-head {
    display: inline-block;
    position: relative;
    color: #fff;
    height: 250px;
    overflow: hidden;
    width: 100%;
    vertical-align: top;
    font-size: 1rem;
    background: white;
}

.g-i {
    font-size: 1rem;
}

.grid-head {
    height: 300px;
    /* -webkit-transition: width 0.75s, height 0.75s;
    transition: width 0.75s, height 0.75s; */
}

.grid-item-bg,
.grid-item-bg-img {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
}

.grid-item-bg {
    overflow: hidden;
}

.grid-item-bg-img {
    background-position: center center;
}

.grid-item-bg-img::before {
    position: absolute;
    z-index: 0;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    transition: opacity .5s ease;
}

.grid-item-content {
    position: absolute;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    bottom: 0;
    left: 0;
    padding: 12px 18px 14px;
    z-index: 11;
}

.video-react-big-play-button {
    display: none !important;
}

.grid-item-content::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 0;
    border-top: 1px solid #fff;
    opacity: .75;
    transition: opacity 0.5s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    transition: opacity 0.5s cubic-bezier(0.23, 1, 0.32, 1), transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    transition: opacity 0.5s cubic-bezier(0.23, 1, 0.32, 1), transform 0.5s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-transform-origin: left;
    transform-origin: left;
}

.grid-item-symbol {
    transition: opacity .5s ease;
    margin-right: 10px;
    text-transform: uppercase;
    color: white;
    opacity: 0.75;
    font-size: 20px;
}

.grid-item-symbol.arrow {
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%22-14.5%20385.5%2030%2030%22%3E%0A%20%20%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M3.625%20400.5l-9.375%209.375V413l12.5-12.5-12.5-12.5v3.125%22/%3E%0A%3C/svg%3E%0A");
    width: 20px;
    height: 20px;
    background-size: 20px;
    margin-left: -12px;
    transition: margin-left 0.70s;
}

.grid-item:hover .grid-item-symbol.arrow,
.grid-item-b:hover .grid-item-symbol.arrow {
    margin-left: 0px;
}

.grid-item-title {
    font-family: "LandsecBrownWeb-Bold";
    transition: opacity .5s ease;
    opacity: 1;
    color: white;
}

small,
.small {
    font-size: 1.25rem;
}

.video-react-video,
.video-react .video-react-poster {
    transform: scale(1.5);
}

.video-react .video-react-video {
    top: 20%;
}

/* Mobile first queries */

/* Larger than mobile */

@media (min-width: 400px) {
    .grid-head {
        height: 370px;
        margin-top: -1px;
    }
}

/* Larger than phablet */

@media (min-width: 550px) {}

/* Larger than tablet */

@media (min-width: 768px) {}

/* Larger than desktop */

@media (min-width: 992px) {
    .grid-item {
        /* height: 300px; */
        height: 25vh;
    }
    .grid-item-bg-img {
        height: 100%;
    }
    .grid-head,
    .grid-head-body-black,
    .grid-head-body-pink,
    .ghb {
        height: 600px;
        height: 50vh;
        flex: 1;
        display: flex;
    }
    .grid-item-content {
        padding: 12px 25px 12px;
    }
    .grid-item,
    .grid-head {
        /* width: 33.33333%; */
        width: 25%;
        font-size: 1.5rem;
    }
    .grid-head {
        height: 50vh;
        margin-top: -1px;
    }
    .g-i {
        font-size: 2rem;
    }
    .gridix-list-items:hover .grid-item-bg-img::before,
    .my-gallery-class:hover .grid-item-bg-img::before {
        /* background: rgba(0, 0, 0, 0.85); */
        opacity: 1;
        z-index: 9;
    }
    .gridix-list-items:hover .grid-item:hover .grid-item-bg-img::before,
    .my-gallery-class:hover .g-i:hover .grid-item-bg-img::before {
        opacity: 0;
    }
    .grid-item .grid-item-bg-img::before,
    .g-i .grid-item-bg-img::before {
        opacity: 0;
    }
    .grid-item:hover .grid-item-content::before,
    .g-i:hover .grid-item-content::before {
        opacity: 1;
        -webkit-transform: translate(25px, 0) scale(5, 1);
        transform: translate(25px, 0) scale(5, 1);
    }
    .grid-item-title {
        opacity: 0;
    }
    .grid-item:hover .grid-item-title,
    .g-i:hover .grid-item-title,
    .active .grid-item-title {
        opacity: 1;
    }
    small,
    .small {
        font-size: 1rem;
    }
    .grid-item:hover .grid-item-symbol,
    .g-i:hover .grid-item-symbol {
        opacity: 1;
    }
    .grid-item-title.small {
        font-size: 1.2rem;
    }
    .grid-item-bg-img::before {
        background: rgba(0, 0, 0, 0.65);
    }
}

/* Larger than Desktop HD */

@media (min-width: 1200px) {}

@media (min-width: 1680px) {
    .grid-item,
    .grid-head {
        width: 25%;
        font-size: 2rem;
    }
}

@media (max-height: 900px) and (min-width: 992px) {
    .grid-head.black {
        min-height: 350px;
        /* height: 370px; */
    }
}