/* Makes a fixed background wrapper
which the user cannot interact with */

.iframe-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0 !important;
    pointer-events: none;
    overflow: hidden;
}

.iframe-wrapper.fixed {
    position: fixed;
}

/* Make the iframe keep an aspect ratio, and
position it in the middle of its parent wrapper*/

.iframe-wrapper iframe {
    width: 100vw;
    height: 56.25vw;
    /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh;
    /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.iframe-wrapper.absolute iframe {
    width: 100%;
    height: 100%;
}

#myDefaultVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

