body {
    /* background: #bababa !important; */
}

section {
    background: #bababa ;
}

body,
main,
#root,
.body {
    height: 100%;
}

.centreSpecificContent section,
.centreSpecificContent .section {
    display: flex;
    height: 100%;
    width: 100%;
    font-size: 1rem;
    flex-direction: column;
}

section#keyfacts {
    overflow-y: auto;
}

#referencePositionsKeyFacts {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 912px;
    height: 641px;
    z-index: 999;
    /* display: none; */
}

.centreSpecificContent .small {
    font-size: 0.8rem;
    line-height: 0.8rem;
    margin-top: 5px;
    display: block;
}

/* #OVERVIEW  */

.overview-block-1,
.overview-block-2 {
    min-height: 300px;
    background-size: cover;
    background-position: center center;
}

.overview-block-2-1,
.overview-block-2-2 {
    flex: 1;
    min-height: 300px;
    background-size: cover;
    background-position: center center;
    font-size: 1.3rem;
}

.overview-block-2-2 {
    background-color: #262626;
    line-height: 1.5em;
    color: white;
    padding: 40px 50px;
}

/* #KEYFACTS  */

.keyfacts-block-1 {
    min-height: 300px;
    flex: 1;
    align-items: center;
    display: flex;
    justify-content: center;
}

.keyfacts-block-1 img,
.keyfacts-block-1 .LazyLoad {
    width: 100%;
    height: 100%;
    padding: 30px;
    padding-bottom: 0px;
}

.keyfacts-block-2 {
    flex: 1;
    min-height: 300px;
    padding: 0px 20px;
    padding-bottom: 40px;
    display: flex;
    flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    justify-content: space-around;
    align-content: start;
}

.keyfacts-block-2-x {
    position: relative;
    padding: 0px 5px;
    width: 150px;
    height: 60px;
    padding-left: 37px;
    margin-bottom: 10px;
    color: black;
    font-weight: bold;
    font-size: 1.3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.keyfacts-block-2-x .keyfacts-close-btn {
    display: none;
    z-index: 9999;
}

.keyfacts-block-2-x.active {
    width: 100%;
    min-height: 100px;
    height: auto;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 37px;
    -webkit-transition: all 0.35s ease-out;
    -moz-transition: all 0.35s ease-out;
    -o-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
    background-color: white;
}

.keyfacts-block-2-x.active .keyfacts-close-btn {
    display: block;
    text-align: right;
    position: absolute;
    right: 15px;
    top: 10px;
}

.keyfacts-block-2-x.active .keyfacts-close-btn:hover {
    opacity: 0.7;
}

.active.keyfacts-block-2-x:before {
    position: absolute;
    content: '';
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: white;
    z-index: -2;
    cursor: pointer;
}

.keyfacts-block-2-x .keyfacts-details-wrapper,
.keyfacts-block-2-x.active .keyfacts-main-wrapper {
    display: none;
}

.keyfacts-block-2-x.active .keyfacts-details-wrapper,
.keyfacts-block-2-x .keyfacts-main-wrapper {
    display: block;
}

.keyfacts-block-2-x .keyfacts-main-wrapper {
    position: relative;
    line-height: 1rem !important;
}

.showMore {
    position: absolute;
    right: -10px;
    top: -10px;
    animation-name: show-more-animation-plus;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}

.keyfacts-block-2-x.active .keyfacts-show-more>i {
    display: none;
}

.keyfacts-block-2-x>div {
    margin-bottom: 2px;
}

.keyfacts-block-corner {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 35px 0 0 35px;
    border-color: transparent transparent transparent #ef3b9c;
    left: 0px;
    bottom: 0px;
    z-index: -1;
    margin-bottom: 0px !important;
    z-index: 1;
}

.keyfacts-block-2-x-x {
    margin: 20px 0px;
}

.keyfacts-show-more {
    position: absolute;
    width: 40px;
    height: 40px;
    left: -2px;
    bottom: -4px;
    z-index: 1;
    text-align: right;
    color: black;
    font-size: 1rem;
    padding: 2px;
    background-image: url(https://landsecconnectcms.azurewebsites.net/media/1006/icon_menu_connect.svg);
    background-position: 10px calc(100% - 10px);
    background-repeat: no-repeat;
    background-size: 20px;
    z-index: 2;
}

.keyfacts-show-more i {
    position: absolute;
    right: 0px;
    top: 0px;
    animation-name: show-more-animation-plus;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    /* -webkit-transition: all 0.3s show-more-animation-plus;
    -moz-transition: all 0.3s show-more-animation-plus;
    -o-transition: all 0.3s show-more-animation-plus;
    -ms-transition: all 0.3s show-more-animation-plus; */
}

.keyfact-Image-wrapper {
    margin-top: 20px;
}

/* #OPPORTUNITIES  */

section#opportunities,
section#eventSpaces {
    flex-flow: row wrap;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-flex-line-pack: start;
    align-content: start;
}

.opportunities-block-1,
.opportunities-block-0,
.opportunities-block-2,
.opportunities-block-3,
.opportunities-block-99 {
    width: 100%;
    min-height: 300px;
    background: #ef3b9c;
    display: flex;
    flex-direction: column;
}

.opportunities-block-0 {
    background: #ef3b9c;
    height: 600px;
    width: 100%;
}

.opportunities-block-1.wrapper {
    height: 600px;
}

.opportunities-block-3,
.opportunities-block-99 {
    width: 100%;
}

.opportunities-block-99 {
    height: 100%;
}

section#eventSpaces .opportunities-block-1>.opportunity-block {
    align-items: start;
    justify-content: start;
}

.cover-wrapper {
    width: 100%;
    height: 100%;
}

.cover,
.overview-block-1 img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
}

section#eventSpaces .opportunities-block-2 {
    background-color: black;
    width: 100%;
}

section#eventSpaces .opportunities-block-3 {
    background-color: #bababa;
    width: 100%;
}

#popUpsList {
    list-style: none;
    color: white;
    padding-left: 20px;
    line-height: 25px;
    overflow-y: auto;
    width: 100%;
}

#popUpsList li {
    margin-bottom: 15px;
}

#popUpsList li a {
    color: white;
}

#popUpsList li a:hover,
#popUpsList li a.active {
    font-family: "LandsecBrownWeb-Bold";
    position: relative;
    color: black;
    cursor: pointer;
    text-decoration: underline;
    z-index: 1;
}

#popUpsList li a:hover:after,
#popUpsList li a.active:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25px 0 0 25px;
    border-color: transparent transparent transparent white;
    left: -6px;
    bottom: 0px;
    z-index: -1;
}

section#eventSpaces .opportunities-block-2 {
    min-height: 300px;
    height: auto;
}

section#eventSpaces .opportunities-block-2>div {
    width: 100%;
}

#eventSpacesSlider .slideItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

#eventSpacesSlider #sliderImg {
    position: relative;
    background-position: center center;
    background-size: cover;
    z-index: 1;
}

#eventSpacesSlider {
    position: relative;
}

#eventSpacesSlider::before {
    position: absolute;
    content: '';
    right: 0px;
    bottom: 0px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 9vw 9vw;
    border-color: transparent transparent #000000 transparent;
    z-index: 2;
}

#eventSpacesSlider #summary,
#eventSpaces .opportunities-block-1,
#eventSpaces .opportunities-block-3 {
    position: relative;
    padding: 40px;
}

#eventSpacesSlider #summary {
    position: relative;
    font-family: "FM-Light";
    letter-spacing: 0.1em;
    background-color: #262626;
    color: white;
    line-height: 1.5em;
    z-index: 1;
}

#eventSpacesSlider #summary:before {
    position: absolute;
    content: '';
    left: 0px;
    top: 0px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9vw 9vw 0 0;
    border-color: #000000 transparent transparent transparent;
    z-index: -1;
}

#eventSpacesSlider #summary {
    order: 2;
}

.event-space-block {
    position: relative;
    width: 100%;
    height: 100%;
}

.trianlgeCorner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    transform: translateX(-15px) translateY(-15px);
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 100px 100px 0 0;
    border-color: #000000 transparent transparent transparent;
    z-index: 1;
}

.event-space-container {
    position: relative;
    z-index: 2;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.event-space-container .block-1 {
    flex: 1;
    min-height: 300px;
}

.event-space-container .block-1:first-child {
    min-height: auto;
    margin-bottom: 40px;
}

#eventSpacesSlider {
    background-size: cover;
    background-position: center center;
}

.event-space-container .eve-button {
    position: relative;
    background: black;
    padding: 10px 20px;
    color: white;
    padding-right: 40px;
    margin-top: 20px;
    display: inline-block;
}

.event-space-container .eve-button:before {
    position: absolute;
    content: '>';
    right: 20px;
}

.event-space-container .block-1 h2 {
    margin-bottom: 0px;
}

.event-space-container p {
    margin-top: 40px;
}

.event-space-container .block-1.imageWrapper {
    padding: 0px;
    margin-bottom: 50px;
}

.event-space-container .imageMap {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(https://landsecconnectcms.azurewebsites.net/media/1003/holding-image.png);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 10px 0px;
}

.event-space-container .details span {
    margin-bottom: 20px;
    display: block;
    line-height: 1.5em;
}

.event-space-container .details>span div {
    font-family: "LandsecBrownWeb-Bold";
}

section#leasing {
    justify-content: center;
    align-items: center;
    display: inline-block;
    height: auto;
}

section#leasing .cover-wrapper {
    width: 100%;
    max-width: 100%;
    position: absolute;
    top:0px;
    left:0px;
    height:90vw;
    background-size: cover;
    background-position: center center;
    /* background-color: white; */
    background-repeat: no-repeat;
}

#levSelectorShadow {
    opacity: 0.5;
}

#LevelSelector,
#levSelectorShadow {
    position: absolute;
    width: 100%;
    max-width: 150px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

/* _:-ms-fullscreen, #LevelSelector  { position: relative!important; }
_:-ms-fullscreen, #levSelectorShadow  { display: none; } */


.LevelSelectorWrapper {
    position: relative;
    height: 10px;
    width: 100px;
    transition: all 0.5s ease;
}

.LevelSelectorWrapper:hover {
    height: 30px;
    cursor: pointer;
}

#levSelectorShadow {
    z-index: 99;
}

.LevelSelectorPanelWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.FloorNameLabel {
    font-family: "LandsecBrownWeb-Bold";
    padding-left: 20px;
    margin-right: 10px;
}

#ListLevelSelector {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
    padding-right: 20px;
}

#ListLevelSelector li {
    position: relative;
    flex: 0 0 auto;
}

section#leasing .LS-1,
section#leasing .LS-2,
section#leasing .LS-3 {
    fill: #dbdada;
}

.leasing-wrapper {
    padding: 40px;
    padding-top: 300px;
    width: 100%;
    /* height: 100%; */
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.leasing-wrapper .topContent,
.leasing-wrapper .bottomContent {
    flex: 1;
}

.leasing-wrapper .bottomContent {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    z-index: 1;
}

.leasing-wrapper .headerContact {
    color: white;
}

.headerContact h1,
 .headerContact h3,
.headerContact h4 {
    margin: 0px;
}

.leasing-wrapper .headerContact h4 {
    margin-top: 15px;
}

.listContacts {
    padding-left: 0px;
    line-height: 2.5em;
    list-style: none;
    font-size: 1.3rem;
    /* letter-spacing: 0.05em; */
}

.listContacts li {
    position: relative;
    padding-left: 30px;
}

.listContacts li:before {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    background-image: url('../../assets/Images/Leasing/Leasing_Email.svg');
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
}

.listContacts li a {
    color: black;
    position: relative;
}

.listContacts li a:after {
    position: absolute;
    content: '';
    height: 1px;
    width: 0px;
    bottom: -2px;
    left: 0px;
    background-color: black;
    -webkit-transition: width .1s;
    -o-transition: width .1s;
    transition: width .1s;
}

.listContacts li a:hover:after {
    width: 100%;
}

.listContacts li.email:before {
    background-image: url('../../assets/Images/Leasing/Leasing_Email_white.svg');
}

.listContacts li.telephone:before {
    background-image: url('../../assets/Images/Leasing/Leasing_Telephone_white.svg');
}

.listContacts li.download:before {
    background-image: url('../../assets/Images/Leasing/Leasing_Download_white.svg');
}

.leasing-wrapper .topContent {
    display: none;
}

.leasing-wrapper .bottomContent {
    display: inline-block;
}

/* #Gallery */

section#gallery {
    flex-direction: column;
}

section#gallery>.SliderWrapper {
    flex: 1;
}

section#gallery>.SliderFooter {
    height: auto;
    background: white;
    padding: 20px;
}

section#gallery>.SliderFooter h2 {
    margin-bottom: 5px;
}

section#gallery>.SliderWrapper .SliderContainer,
section#gallery>.SliderWrapper .slideItem,
section#gallery>.SliderWrapper .carousel-slider,
section#gallery>.SliderWrapper .slider,
section#gallery>.SliderWrapper .slider-wrapper {
    height: 100%;
    width: 100%;
}

section#gallery>.SliderWrapper .carousel.carousel-slider .control-arrow:hover,
section#gallery>.SliderWrapper .carousel.carousel-slider .control-arrow {
    background: black;
    opacity: 1;
    height: 100px;
    width: 40px;
    top: 50%;
    transform: translateY(-50%)
}

.carousel .control-arrow.control-prev:before,
.carousel.carousel-slider .control-arrow.control-prev:before {
    content: ' ';
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%22-14.5%20385.5%2030%2030%22%3E%0A%20%20%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M3.625%20400.5l-9.375%209.375V413l12.5-12.5-12.5-12.5v3.125%22/%3E%0A%3C/svg%3E%0A");
    height: 26px;
    border: 0;
    width: 26px;
    background-size: 26px;
    transform: rotate(180deg);
    margin-left: 0px;
}

.carousel .control-arrow.control-next:before,
.carousel.carousel-slider .control-arrow.control-next:before {
    content: ' ';
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%22-14.5%20385.5%2030%2030%22%3E%0A%20%20%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M3.625%20400.5l-9.375%209.375V413l12.5-12.5-12.5-12.5v3.125%22/%3E%0A%3C/svg%3E%0A");
    height: 26px;
    border: 0;
    width: 26px;
    background-size: 26px;
    margin-right: 0px;
}

section#gallery>.SliderWrapper .carousel .slide .legend {
    width: 100%;
    left: 0;
    transform: translateX(0px);
    margin: 0px;
    bottom: 0;
    opacity: 1;
    border-radius: 0px;
    background: white;
    color: black;
    text-align: left;
    padding: 20px
}

section#gallery>.SliderWrapper .carousel .slide .legend h2 {
    font-size: 1.2rem;
    font-family: "LandsecBrownWeb-Bold" !important;
}

.carousel.carousel-slider {
    height: 100%;
}

#eventSpacesSlider .carousel.carousel-slider .control-arrow {
    height: 25px;
    margin: 5%;
    top: 50%;
    opacity: 0.8;
}

#eventSpacesSlider .carousel.carousel-slider .control-arrow:hover {
    background: rgba(0, 0, 0, 0);
    opacity: 1;
}

section#eventSpaces .opportunities-block-1>.opportunity-block {
    align-items: center;
}

section#eventSpaces .opportunities-block-1>.opportunity-block .text {
    margin-top: 10px;
}

section#eventSpaces .opportunities-block-1>.opportunity-block>div>div:first-child {
    display: none;
}

section#eventSpaces .opportunities-block-1>.opportunity-block h2 {
    margin-top: 0px;
    margin-bottom: 20px;
    text-align: center;
}

section#centre-specific-page{
    width: 100vw;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-content: start;
    width: 100%;
    height: auto;
    position: absolute;
}

section#centre-specific-page .grid-item-jolly{
    background-color:black;
}

section#centre-specific-page .ModelImage {
    background-size: auto 90%;
    background-repeat: no-repeat;
    background-size: contain;
}

.centre-specific-page-detailsWrapper{
    padding:50px;
    line-height: 1.3;
    color:white;
    font-size: 1.5em;
    line-height: 1.5em;
    display: flex;
    flex-flow: wrap;
    margin: 0 auto;
    text-align:left;
    height: 100%;
    /* width: 100%; */
    display: block;
    flex: 1 1 auto;
}

.overview-block-01.grid-item-b{
    order: 1;
    /* height: 500px; */
}
.overview-block-02{
    order: 3;
}
.overview-block-03{
    order: 2;
}

.centre-specific-page-keyfact-wrapper{
    padding-left:45px;
    position: relative;
    /* flex: 0 0 100%; */
    margin-bottom: 40px;
    justify-content: flex-end;
    display: start;
}

.centre-specific-page-keyfact-details{
    display: none;
    background: white;
    color:black;
    padding:15px;
    font-size: 1.2rem;
    width: 100%;
    z-index: 9999;
    margin-left: -30px;
    margin-top: 10px;
}

.centre-specific-page-keyfact-controls{
    display:none;
}

.centre-specific-page-keyfact-details-heading{
    font-family: "LandsecBrownWeb-Bold";
    font-size: 1.5rem;
}

.centre-specific-page-keyfact-details-text{
    margin-bottom: 10px;
}

.centre-specific-page-keyfact-wrapper.active .centre-specific-page-keyfact-controls:checked + div > .centre-specific-page-keyfact-details{
    display: block;
}
.centre-specific-page-keyfact-wrapper.active .centre-specific-page-keyfact-controls:checked + div > .centre-specific-page-keyfact-title,
.centre-specific-page-keyfact-wrapper.active .centre-specific-page-keyfact-controls:checked + div > .centre-specific-page-keyfact-subtitle{
    display: none;
}

.centre-specific-page-keyfact-title{
    font-size: 1.5rem;
    font-family: "LandsecBrownWeb-Bold";
    line-height: 1;
    text-align: left;
}

.centre-specific-page-keyfact-subtitle{
    color:black;
    font-size: 1rem;
    margin-left: 52px;
    text-align: left;
}

.centre-specific-page-keyfact-icon{
    background-image: url(https://landsec-connect-cms.boxxed.com/media/1209/keyfacts_visitorfrequency.svg);
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    float: left;
    margin-right: 20px;
   
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
    width: 100%;
}
.lazy-load-image-background.blur.lazy-load-image-loaded > img {
    width: 100%;
    /* max-height: 100vh; */
}

.controlsLevelSelector {
    display: flex;
    height: 66px;
    width: 20px;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    font-size: 2rem;
    color: white;
}

.caseStudyText {
    font-family: "LandsecBrownWeb-Regular";
}

.escTexty {
    font-family: "FM-Light";
}

.caseStudyImgWrapper {
    margin: 0 auto;
    max-width: 200px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.dialog-cover-wrapper {
    position: relative;
    overflow: auto;
    width: 100% !important;
    height: 100%;
    padding: 20px;
}

.dialog-cover-wrapper img {
    /* transform: translateX(-50%) translateY(-50%) scale(1);
    left: 50%;
    top: 50%;
    position: absolute; */
}

.imageInModal img {
    height: 100%;
}
.cover-wrapper.modal .imgWrapperModal   {
    width: 100%;
    max-width: 100%;
}

.grid-item-b.centre-specific-page-details-container {
    height: auto;
}

.centre-specific-page-details-container .carousel .slide{
    background-color: rgba(0, 0, 0, 0 )
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
    opacity:1;
}

section#centre-specific-page .grid-item-jolly {
    display: block;
    /* min-height: 350px; */
}


.leasing-map-wrapper{
    position: relative;
}
.leasing-map-btn {
    position: absolute!important;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
}

.case-studies-wrapper{
    background-color: #404040;
    display: block;
    flex-direction: column;
    height: 100%;
}

.case-studies-wrapper-content{
    flex: 0 0 50%;
    position:relative;
    overflow: hidden;
    z-index: 1;
}

.case-studies-wrapper-content:before{
    content: '';
    position: absolute;
    width: 200px;
    height: 350px;
    left:-80px;
    top: -130px;
    transform: rotate(45deg);
    background: rgba(0, 0, 0, 0.5 );
    z-index: -1;
}

.case-studies-wrapper-content-name, .case-studies-wrapper-content-logo, .case-studies-wrapper-content-summary {
    margin:50px;
    color:white;
    /* font-family: "FM-Light"; */
    font-size: 1.5rem;
}

.case-studies-wrapper-content-summary{
    line-height: 1.5em;
    text-align: left;
    margin-top: 0px;
}

.case-studies-wrapper-content-name{
    font-size: 1.8rem;
    display: none;
}
.case-studies-wrapper-bg{
    background-size: cover;
    background-position: center center;
    flex: 0 0 50%;
    min-height: 300px;
    display: none;
}
.case-studies-wrapper-content-logo{
    width: 60%;
    height: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: 0 auto;
}

.carousel.carousel-slider .control-arrow:hover {
    background: rgba(0,0,0,0);
}

.FilterName{
    font-size: 2rem;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide{
    display: flex;
    justify-content: center;
}

.mapImage{
    position: absolute;
    left: 0px;
    top: 50px;
    width: 100%; 
    height: 300px;
    background-size: contain;
    background-repeat: no-repeat;
}

.rto-btn{
    position: absolute!important;
    bottom: 30px;
    left: 30px;
    width: 155px;
    text-align: left;
    line-height: 1;
}

.rto-btn.leasing{
    /* left:0;
    bottom: 0; */
}


.connectDetail.grid-item-b{
    min-height: 300px!important;
    height: auto!important;
    background-color: #262b44;
    padding-bottom: 50px;
}

.leasing-map-btn {
    position: absolute!important;
    bottom: 30px;
    left: 30px;
    transform: translateX(0%);
}



/* Mobile first queries */

/* Larger than mobile */

@media (min-width: 400px) {}

/* Larger than phablet */

@media (min-width: 550px) {}

/* Larger than tablet */

@media (min-width: 768px) {}

/* Larger than desktop */

@media (min-width: 992px) {
    section h2 {
        font-size: 2.3rem;
    }
    section h3,
    #popUpsList {
        font-size: 1.3rem;
    }
    .centreSpecificContent section,
    .centreSpecificContent .section {
        /* width: 66.6666%; */
        width: 75%;
        flex-direction: row;
    }
    .overview-block-1 {
        flex: 1;
    }
    .overview-block-2 {
        flex: 1;
        flex-direction: column;
        display: flex;
    }
    .overview-block-2-1 {
        flex: 1;
        height: 600px;
        background-size: cover;
        background-position: center center;
    }
    .overview-block-2-2 {
        flex: 1;
        height: 600px;
        background-color: #262626;
        line-height: 1.5em;
        color: white;
        padding: 40px 50px;
        z-index: 999;
        position: relative;
        overflow-y: auto;
    }
    .overview-block-2-1,
    .overview-block-2-2 {
        font-size: 1.5rem;
    }
    .keyfacts-details-wrapper .small {
        margin-top: 4px;
    }
    section#keyfacts {
        flex-direction: column;
        position: relative;
        height: 100%;
    }
    .keyfacts-block-2-x.active {
        width: 250px;
    }
    .keyfacts-block-1 {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
    .keyfacts-block-2 {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
    }
    .keyfacts-block-1 img,
    .keyfacts-block-1 .LazyLoad {
        width: 350px;
    }
    .keyfacts-block-2 .keyfacts-block-2-x.hand {
        cursor: pointer;
    }
    .keyfacts-block-2 .keyfacts-block-2-x {
        position: absolute;
        left: 50%;
        top: 50%;
        opacity: 0;
        transform: translateX(-50%) translateY(-50%);
        -webkit-animation: fadein 2s;
        -moz-animation: fadein 2s;
        -ms-animation: fadein 2s;
        -o-animation: fadein 2s;
        animation: fadein 2s;
        animation-fill-mode: forwards;
        /* max-height: 400px;
        overflow-y: auto;
        position: absolute; */
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(1) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -127, -158) scale(1.3);
        animation-delay: 0.3
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(2) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 76, -235) scale(1.3);
        animation-delay: 0.6s
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(3) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 253, -158) scale(1.3);
        animation-delay: 0.9s
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(4) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 290, 37) scale(1.3);
        animation-delay: 1.1s
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(5) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 177, 164) scale(1.3);
        animation-delay: 1.4s
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(6) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -45, 191) scale(1.3);
        animation-delay: 1.7s
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(7) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -245, 36) scale(1.3);
        animation-delay: 2s
    }
    .opportunities-block-1,
    .opportunities-block-0,
    .opportunities-block-2,
    .opportunities-block-3,
    .opportunities-block-99,
    section#eventSpaces .opportunities-block-2 {
        width: 33.3333%;
        height: 600px;
        height: 50vh;
        flex-direction: column;
    }
    .opportunities-block-0 {
        height: 300px;
        height: 25vh;
        min-height: 25vh;
        width: 100%;
    }
    .opportunities-block-2,
    section#eventSpaces .opportunities-block-2 {
        width: 66.6666%;
    }
    section#eventSpaces .opportunities-block-2 {
        flex-direction: row;
    }
    section#eventSpaces .opportunities-block-2 .opportunities-block-1 {
        width: 50%;
    }
    .opportunities-block-3,
    .opportunities-block-99 {
        width: 100%;
    }
    .opportunities-block-99 {
        height: 100%;
    }
    .opportunities-block-1.wrapper {
        height: 50%;
    }
    body,
    main,
    #root,
    .body {
        /* min-height: 1200px; */
        height: 100vh;
    }
    .event-space-container {
        flex-direction: row;
    }
    .event-space-container .block-1 {
        flex: 1;
        min-height: auto;
    }
    .trianlgeCorner {
        border-width: 5vw 5vw 0 0;
    }
    .event-space-container .block-1.imageWrapper {
        padding: 0px 20px;
    }
    #eventSpacesSlider .slideItem {
        display: flex;
        flex-direction: row;
    }
    .event-space-container .block-1 .details {
        padding-left: 70px;
        width: 100%;
        height: 100%;
        font-size: 1.3rem;
    }
    #eventSpacesSlider #summary,
    #eventSpaces .opportunities-block-1,
    #eventSpaces .opportunities-block-3,
    .leasing-wrapper {
        padding: 30px;
    }
    .leasing-wrapper {
        height: 100%;
    }
    .trianlgeCorner {
        transform: translateX(-10%) translateY(-10%);
        border-width: 150px 150px 0 0;
    }
    .event-space-container .block-1.imageWrapper {
        margin-bottom: 0px;
    }
    section#leasing{
        height: 100vh;
    }
    section#leasing .cover-wrapper {
        width: 100%;
        height: 100vh;
        background-size: cover;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    section#leasing .cover-wrapper.modal {
        /* z-index: 99; */
        cursor: pointer;
    }
    section#leasing .cover-wrapper.modal .imgWrapperModal, .cover-wrapper.modal .imgWrapperModal   {
        width: 100%;
        max-width: 100%;
        /* max-height: 100vh;
        overflow: hidden; */
    }
    .LevelSelectorPanelWrapper {
        position: absolute;
        right: 5%;
        bottom: 5%;
        /* align-items: flex-end; */
        z-index: 99;
        margin-top: 0px;
    }
    .FloorNameLabel {
        padding-left: 40px;
        margin-right: 40px;
    }
    .LevelSelectorWrapper {
        position: relative;
        height: 20px;
        width: 150px;
        transition: all 0.5s ease;
    }
    .LevelSelectorWrapper:hover #LevelSelector,
    .LevelSelectorWrapper:hover #levSelectorShadow {
        transform: translateY(-50%) matrix(1, 0, 0, 1, -10, 1);
        transition: all 0.3s ease;
    }
    .leasing-wrapper .topContent {
        position: absolute;
        top: 0px;
        left: 0px;
        color: white;
        display: block;
        padding: 30px;
    }
    .leasing-wrapper .bottomContent {
        position: absolute;
        bottom: 0px;
        left: 0px;
        display: block;
        padding: 30px;
    }
    /* section#gallery>.SliderWrapper .carousel.carousel-slider .control-arrow:hover,
    section#gallery>.SliderWrapper .carousel.carousel-slider .control-arrow {
        transform: translateY(0px)
    } */
    /* .centreSpecificContent section,
    .centreSpecificContent .section {
        font-size: 12px; 
    } */
    .opportunity-block .title {
        font-family: "LandsecBrownWeb-Bold";
    }
    .opportunity-block .BackWrapper .text {
        font-size: 1.5rem;
        line-height: 2rem;
        color: black;
    }
    #eventSpacesSlider .carousel.carousel-slider .control-arrow {
        bottom: 0;
        top: auto;
    }
    section#eventSpaces .opportunities-block-1>.opportunity-block>div>div:first-child {
        display: block;
    }
    section#eventSpaces .opportunities-block-1>.opportunity-block {
        align-items: start;
    }
    section#eventSpaces .opportunities-block-1>.opportunity-block h2 {
        margin-top: 20px;
        text-align: left;
    }
    #eventSpacesSlider #summary,
    #eventSpacesSlider #sliderImg {
        order: 1;
    }
    .event-space-container .details span {
        line-height: 1.3em;
    }
    .lazy-load-image-background.blur.lazy-load-image-loaded {
        width: auto;
    }
    .controlsLevelSelector {
        display: none;
    }
    section#leasing {
        display: flex;
        overflow: hidden;
    }
    .caseStudyImgWrapper {
        margin-left: 0px;
    }
    .imageInModal img {
        height: auto;
    }


    .centre-specific-page-keyfact-wrapper.active .centre-specific-page-keyfact-controls:checked + div > .centre-specific-page-keyfact-details{
        display: block;
    }

    .centre-specific-page-keyfact-wrapper{
        /* flex: 1 1 50%; */
        /* flex-grow: 0;
        flex-shrink: 0; */
        flex-basis: 50%;
        /* width: 20%; */
        position: relative;
        /* width: 75%; */
        font-size: 1.3rem;
        margin-bottom: 20px;
        padding-left: 0px;  
    }

    .centre-specific-page-keyfact-wrapper:last-child{
        margin-bottom: 0px;
    }

    .grid-item-b.centre-specific-page-details-container {
        /* overflow-y: scroll; */
        overflow:hidden;
    }

    .grid-item-b.centre-specific-page-details-container{
        justify-content: center;
        display: flex;
        align-items: center;
        height: 50vh;
    }

    .centre-specific-page-keyfact-title, .case-studies-wrapper-content-summary{
        font-size: 1.3rem;
        text-align: left;
    }
    .centre-specific-page-keyfact-subtitle {
        font-size: 1rem;
        text-align: left;
    }

    .leasing-map-btn {
        position: absolute!important;
        bottom: 40px;
        right: 40px;
        left: initial;
        transform: translateX(0%);
    }

    .case-studies-wrapper{
        background-color: #404040;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .case-studies-wrapper-bg{
        min-height: 300px;
        display: block;
    }

    .case-studies-wrapper-content-name{
        display: block;
    }
   w
    .SliderContainer {
        width: 50vw;
        height: 50vh;
    }

    .carousel .slider-wrapper.axis-horizontal .slider .slide{
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .carousel .slider-wrapper, .carousel .slider-wrapper.axis-horizontal .slider .slide{
        height: 50vh;
    }

    .case-studies-wrapper-content-name, .case-studies-wrapper-content-logo, .case-studies-wrapper-content-summary {
        margin: 30px;
    }
    .case-studies-wrapper-content-name{
        margin-bottom: 0px;
    }
    .case-studies-wrapper-content-logo{
        margin-top: 10px;
        height: 150px;
    }

    .case-studies-wrapper-content-summary{
        line-height: 1.4em;
    }

    .overview-block-02{
        order: 2;
    }
    .overview-block-03{
        order: 3;
    }

    .listContacts {
        font-size: 1rem;
    }

    .centreSpecificContent .hidden{
        display: none!important;
    }
    section#leasing.full{
        width: 100%;
        overflow-y: auto;
    }

    .mapImage{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 60%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .full   .mapImage, .mapImage.FullScreen{
        width: 100%;
        top: 0px;
        transform: translateX(-50%) ;
    }
    

    .mapImage.FullScreen{
        height:100%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        background-size: cover;
        
    
    }

    .full .bottomContent, .full .topContent, .full .LevelSelectorPanelWrapper{
        position: fixed;
    }

    .Slider-Flex{
        /* flex: 1 1 auto; */
        width: 100%;
    }

    .centre-specific-page-detailsWrapper {
        max-width: 80%;
        display: flex!important;
        overflow: hidden;
        flex-direction: row;
        width: 40vw!important;
        padding-left: 0px;
        padding-right: 0px;
        max-width: 40vw!important;
        overflow: hidden;
        justify-content: center;
        /* flex-direction: column; */
    }

    section#centre-specific-page .grid-sizer-b,section#centre-specific-page .grid-item-b,section#centre-specific-page .grid-item-1-1,section#centre-specific-page .grid-item-1-2,section#centre-specific-page .grid-item-2-1,
    section#centre-specific-page .grid-item-3-1,section#centre-specific-page .grid-item-4-1{
        min-height: 490px;
    }

    .connectionDetail {
        max-width: 100%;
        width: 100%;
        display: block;
    }
    .SliderContainer{
        width: 100%;
    }
   
}

/* Larger than Desktop HD */

@media (min-width: 1200px) {
    .keyfacts-block-1 img,
    .keyfacts-block-1 .LazyLoad {
        width: 800px;
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(1) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -298, -234) scale(1.2);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(2) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 75, -370) scale(1.2);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(3) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 399, -223) scale(1.2);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(4) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 428, 63) scale(1.2);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(5) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 257, 306) scale(1.2);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(6) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -158, 302) scale(1.2);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(7) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -387, 72) scale(1.2);
    }
    /* Hard Fix */
    .Id-00292319-595d-445a-b60e-66f27085c149 .lazy-load-image-background {
        transform: scale(0.9);
    }
    .Id-00292319-595d-445a-b60e-66f27085c149 .keyfacts-block-2 .keyfacts-block-2-x:nth-child(6) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -346, 326) scale(1.2);
    }
    section#leasing .cover-wrapper {
        width: 80%;
        max-width: 100%;
        background-size: contain;
    }

    #leasing.full .cover-wrapper  {
        width: 100%!important;
    }

    .centre-specific-page-details-container {
        /* padding:90px; */
    }

    .centre-specific-page-details-container .slide{
        display: flex;
        justify-content: center;
        text-align: left;
    }

  
/* 
    .centre-specific-page-details-container .slide > div{
    } */

    .centre-specific-page-detailsWrapper{
        font-size: 1.5rem;
        margin-bottom: 20px;
        padding-left: 0px;  
    }
  
    .centre-specific-page-keyfact-title{
        font-size: 1.7rem;
        text-align: left;
    }
    .centre-specific-page-keyfact-subtitle {
        font-size: 1.3rem;
        text-align: left;
    }
   

    .case-studies-wrapper-content-name, .case-studies-wrapper-content-logo, .case-studies-wrapper-content-summary {
        margin: 10px 50px;
    }

    .case-studies-wrapper-content-name{
        margin-top: 50px;
    }
   
}

@media (min-width: 1680px) {
    .keyfacts-block-1 img,
    .keyfacts-block-1 .LazyLoad {
        width: 900px;
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(1) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -393, -265) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(2) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 75, -410) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(3) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 505, -261) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(4) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 549, 53) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(5) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 333, 313) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(6) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -183, 348) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(7) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -521, 50) scale(1.3);
    }
    /* Hard Fix */
    .Id-00292319-595d-445a-b60e-66f27085c149 .lazy-load-image-background {
        transform: scale(0.9);
    }
    .Id-00292319-595d-445a-b60e-66f27085c149 .keyfacts-block-2 .keyfacts-block-2-x:nth-child(6) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -392, 380) scale(1.3);
    }
    .Id-f772fb9f-bd97-438b-87a6-3c84d79f0fbd .lazy-load-image-background {
        transform: translateY(-61px);
    }
    section#leasing .cover-wrapper {
        width: 100%;
        max-width: 100%;
    }
    #eventSpacesSlider #summary,
    #eventSpaces .opportunities-block-1,
    #eventSpaces .opportunities-block-3,
    .leasing-wrapper {
        padding: 60px;
    }
    .leasing-wrapper {
        height: 100%;
    }
    .trianlgeCorner {
        transform: translateX(-25%) translateY(-25%);
    }
    .event-space-container .details span {
        line-height: 1.5em;
    }
    .event-space-container .block-1 .details {
        font-size: 1.5rem;
    }
    section#eventSpaces .opportunities-block-1>.opportunity-block h2 {
        margin-top: 40px;
    }
    .overview-block-2-1,
    .overview-block-2-2 {
        font-size: 1.8rem;
    }

  

 

    .case-studies-wrapper-content-logo{
        height: 200px;
    }

    .centre-specific-page-detailsWrapper{
        line-height: 1.6;
        font-size: 1.7rem;
        margin-bottom: 40px;

    }

    .centre-specific-page-keyfact-subtitle {
        font-size: 1.5rem;
    }

    .listContacts {
        font-size: 1.3rem;
    }

    .rto-btn{
        bottom: 60px;
        left: 60px;
    }
}

@media (min-width: 2000px) {
    section h2 {
        font-size: 3rem;
    }
    section h3,
    #popUpsList {
        font-size: 1.5rem;
    }
    .keyfacts-block-1 img,
    .keyfacts-block-1 .LazyLoad {
        width: 900px;
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(1) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -393, -265) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(2) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 75, -410) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(3) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 541, -268) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(4) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 660, 53) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(5) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 333, 313) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(6) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -183, 348) scale(1.3);
    }
    .keyfacts-block-2 .keyfacts-block-2-x:nth-child(7) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -590, 50) scale(1.3);
    }
    /* Hard Fix */
    .Id-00292319-595d-445a-b60e-66f27085c149 .keyfacts-block-2 .keyfacts-block-2-x:nth-child(2) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, 38, -410) scale(1.3);
    }
    .Id-00292319-595d-445a-b60e-66f27085c149 .keyfacts-block-2 .keyfacts-block-2-x:nth-child(6) {
        transform: translateX(-50%) translateY(-50%) matrix(1, 0, 0, 1, -430, 396) scale(1.3);
    }
    .centreSpecificContent section,
    .centreSpecificContent .section {
        font-size: 1rem;
    }
    .overview-block-1 {
        flex: 2;
    }
    .overview-block-2 {
        flex: 1;
    }

    .case-studies-wrapper-content-name, .case-studies-wrapper-content-logo, .case-studies-wrapper-content-summary {
        margin: 40px 40px;
    }

    .centre-specific-page-keyfact-title, .case-studies-wrapper-content-summary{
        font-size: 1.7rem;
    }
}

@media (max-height: 900px) and (min-width: 992px) {
    .case-studies-wrapper-content-logo{
       display: none;
    }
    .case-studies-wrapper-content-name{
        margin-bottom: 20px;
        margin-top: 60px;
        font-weight: bold;
    }
    .case-studies-wrapper-content:before{
        left: -133px;
    }

    section#leasing{
        min-height: 980px;
    }

    .SliderContainer {
        height: 100%;
    }

    .carousel .slider-wrapper, .carousel .slider-wrapper.axis-horizontal .slider .slide{
        height: 100%;
    }
    .carousel .slider-wrapper.axis-horizontal .slider{
        height: 100%;
    }
}

@keyframes show-more-animation-plus {
    0% {
        font-size: 13px;
    }
    40% {
        font-size: 15px;
    }
    100% {
        font-size: 13px;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */

@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */

@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */

@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}