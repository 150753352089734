@font-face {
    font-family: "LandsecBrownWeb-Regular";
    src: url("./assets/fonts/LandsecBrownWeb-Regular.eot");
    src: url("./assets/fonts/LandsecBrownWeb-Regular.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/LandsecBrownWeb-Regular.woff2") format("woff2"), url("./assets/fonts/LandsecBrownWeb-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "FM-Light";
    src: url("./assets/fonts/FM-Light.otf");
    src: url("./assets/fonts/FM-LightWEB.woff?#iefix") format("embedded-opentype"), url("./assets/fonts/FM-LightWEB.woff2") format("woff2"), url("./assets/fonts/FM-LightWEB.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "LandsecBrownWeb-Bold";
    src: url("./assets/fonts/LandsecBrownWeb-Bold.eot");
    src: url("./assets/fonts/LandsecBrownWeb-Bold.eot?#iefix") format("embedded-opentype"), url("./assets/fonts/LandsecBrownWeb-Bold.woff2") format("woff2"), url("./assets/fonts/LandsecBrownWeb-Bold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
    font-family: "LandsecBrownWeb-Regular", sans-serif !important;
    background: white;
    line-height: 1 !important;
}

button, input, optgroup, select, textarea {
    font-family: "LandsecBrownWeb-Regular", sans-serif !important;
    font-weight: initial !important;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: "LandsecBrownWeb-Regular", sans-serif !important;
    font-weight: initial !important;
}

a {
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

i.big.icon,
i.big.icons {
    font-size: 1em !important;
    margin: 0px;
}

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.st-bg {
    background-image: url('https://landsecconnectcms.azurewebsites.net/media/1215/holding-image.png');
    background-size: cover;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

.JustMobile {
    display: block;
}

.text-center {
    text-align: center;
}

#nav-right {
    position: fixed;
    right: 0px;
    flex-direction: row;
    display: flex;
    justify-content: center;
    z-index: 99999999;
}

.nav-r-item {
    height: 60px;
    width: 60px;
    border: 1px solid white;
    /*background: black;*/
    display: inline-block;
    margin-top: -1px;
    align-self: flex-end;
    display: flex;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.5s ease;
}

.dark .nav-r-item {
    background-color: black;
}

.nav-r-item:last-child {
    border-right: 0px;
}

.nav-r-item,
.nav-r-ite:hover,
a,
a:hover,
a *,
a:hover * {
    color: white;
}

.dark .nav-r-item,
.dark .nav-r-ite:hover,
.dark .nav-r-item a,
.dark .nav-r-item a:hover,
.dark .nav-r-text {
    /* color: black !important; */
}

.nav-r-item .nav-r-text {
    width: 0;
    display: none;
    padding: 16px 15px;
    opacity: 0;
}

.nav-r-item-logo {
    height: auto;
    width: 100%;
    justify-content: center;
    display: flex;
    background: rgba(0, 0, 0, 0.2);
}

.nav-r-item:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.dark .nav-r-item:hover,
.dark .nav-r-item:last-child:hover {
    background-color: rgba(0, 0, 0, .4);
}

#logo-img-nav,
.logo-img-nav {
    /* width: 100%; */
    max-width: 30px;
}

.closeBtn.ui.negative.button {
    position: absolute;
    right: -91px;
    top: -76px;
    background: rgba(0, 0, 0, 0);
    font-size: 40px;
    font-family: "LandsecBrownWeb-Bold";
}

.closeBtn.ui.negative.button:hover {
    background: rgba(0, 0, 0, 0);
}


.dark-btn{
    position: relative;
    background: #1c1c1b;
    padding: 15px 20px;
    display: inline-block;
    color: white;
    overflow: hidden;
    font-size: 1.2rem;
    cursor: pointer;
}

.dark-btn.l{
    padding-left: 60px;
    cursor: pointer;
}

.dark-btn.r{
    padding-right: 60px;
}

.dark-btn::after{
    position: absolute;
    font-size: 1.2rem;
    transform: translateY(-50%);
    width: 10px;
    height: 16px;
    top:50%;
}

.dark-btn.l::after{
    left:25px;
    content: '<';
 }
 .dark-btn.r::after{
    right:25px;
    content: '>';
 }

.dark-btn::before{
   content: '';
   position: absolute;
   left:-50px;
   top:0px;
   width: 100%;
   height: 200%;;
   background-color: rgba(255, 255, 255, 0.1);
   transform: rotate(45deg);
   transition:all 0.3s ease;
}

.dark-btn:hover::before{
    left:50px;
    width: 200%;
    height: 200%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.fullScreen{
    display: none;
    padding: 20px;
    color: white;
    background: black;
    position: fixed;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99999;
    border: 1px solid white;
    cursor: pointer;
    transition:all 1s ease;
}

.fullScreen.open{
    top: 20px;
    transition:all 1s ease;
    animation: fd-in  1s linear;
}

.fullScreen.close{
    top: -100px;
    transition:all 1s ease;
    animation: fd-out  1s linear;
}


.loader{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 150px;
    z-index: -1;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes fd-in {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@keyframes fd-out {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

/* Mobile first queries */

/* Larger than mobile */

@media (min-width: 400px) {}

/* Larger than phablet */

@media (min-width: 550px) {}

/* Larger than tablet */

@media (min-width: 768px) {
   
}

/* Larger than desktop */

@media (min-width: 992px) {
    .JustMobile {
        display: none;
    }
    .closeBtn.ui.negative.button {
        right: -117px;
        top: -76px;
        font-size: 40px;
    }

    #nav-right {
        position: fixed;
        color: white;
        right: 0;
        flex-direction: column;
        height: 250px;
    }
    .nav-r-item {
        height: 85px;
        flex: 1;
        min-width: 85px;
        width: auto;
        border: 1px solid white;
    }
    .nav-r-item.active {
        background-color: rgba(0, 0, 0, 0.7);
    }
    .dark .nav-r-item.active {
        background-color: rgba(0, 0, 0, .4) !important;
    }
    .nav-r-item:last-child {
        border: 1px solid white;
    }
    .dark .nav-r-item:last-child {
        background-color: black;
    }
    .nav-r-item-logo {
        width: 83px;
        height: 83px;
        display: flex;
        justify-content: center;
        background: rgba(255, 255, 255, 0);
    }
    #nav-right {
        background: rgba(255, 255, 255, 0);
    }
    .nav-r-item .nav-r-text {
        width: 145px;
        opacity: 1;
        transition: opacity 3s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
        transition: opacity 3s cubic-bezier(0.23, 1, 0.32, 1), transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
        transition: opacity 3s cubic-bezier(0.23, 1, 0.32, 1), transform 0.5s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    }
    .nav-r-item:hover .nav-r-text {
        display: flex;
        align-items: center;
        opacity: 1;
    }
    #logo-img-nav,
    .logo-img-nav {
        width: auto;
    }

    .dark-btn{
        padding: 18px 25px;
    }

    .fullScreen{
        display: block;
     
    }
    
}

/* Larger than Desktop HD */

@media (min-width: 1200px) {}

@media (min-width: 1680px) {
    .closeBtn.ui.negative.button {
        right: -225px;
        top: -60px;
        font-size: 70px;
    }
}

@media (min-width: 768px) {}

@media (max-height: 900px) and (min-width: 992px) {
    .nav-r-item, .nav-r-item-logo{
        height: 65px;
        width: 65px;
        min-width: 65px;
    }

    #nav-right{
        height: 195px; 
    }
}