.grid-head.black.opportunitiesLandingPageBuilderWrapper {
    height: 50vh !important;
    min-height: 50vh !important;
}

section#opportunitiesLandingPageBuilder {
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-flex-line-pack: start;
    align-content: start;
    width: 100%;
}

section#opportunitiesLandingPageBuilder .opportunities-block-1,
section#opportunitiesLandingPageBuilder .opportunities-block-0,
section#opportunitiesLandingPageBuilder .opportunities-block-2,
section#opportunitiesLandingPageBuilder .opportunities-block-3,
section#opportunitiesLandingPageBuilder .opportunities-block-99 {
    width: 100%;
    min-height: 300px;
    background: #ef3b9c;
    display: flex;
    flex-direction: column;
}

section#opportunitiesLandingPageBuilder .opportunities-block-0 {
    background: #ef3b9c;
    height: 600px;
    width: 100%;
}

section#opportunitiesLandingPageBuilder .opportunities-block-1.wrapper {
    height: 600px;
}

section#opportunitiesLandingPageBuilder .opportunities-block-3,
section#opportunitiesLandingPageBuilder .opportunities-block-99 {
    width: 100%;
}

section#opportunitiesLandingPageBuilder .opportunities-block-99 {
    height: 100%;
}

.my-gallery-class {
    width: 100%;
}

.grid-item-b {
    background-size: cover;
    background-position: center center;
}

.grid-sizer-b,
.grid-item-b,
.grid-item-1-1,
.grid-item-1-2,
.grid-item-2-1,
.grid-item-3-1,
.grid-item-4-1 {
    width: 100%;
    height: 300px;
}

.grid-item-jolly {
    display: none;
}



/* Mobile first queries */

/* Larger than mobile */

@media (min-width: 400px) {}

/* Larger than phablet */

@media (min-width: 550px) {}

/* Larger than tablet */

@media (min-width: 768px) {}

/* Larger than desktop */

@media (min-width: 992px) {
    section#opportunitiesLandingPageBuilder .opportunities-block-1,
    section#opportunitiesLandingPageBuilder .opportunities-block-0,
    section#opportunitiesLandingPageBuilder .opportunities-block-2,
    section#opportunitiesLandingPageBuilder .opportunities-block-3,
    section#opportunitiesLandingPageBuilder .opportunities-block-99,
    section#eventSpaces section#opportunitiesLandingPageBuilder .opportunities-block-2 {
        width: 25%;
        height: 600px;
        height: 50vh;
        flex-direction: column;
    }
    section#opportunitiesLandingPageBuilder .opportunities-block-0 {
        height: 300px;
        height: 25vh;
        min-height: 25vh;
        width: 100%;
    }
    section#opportunitiesLandingPageBuilder .opportunities-block-2 {
        width: 50%;
    }
    section#opportunitiesLandingPageBuilder .opportunities-block-2 {
        flex-direction: row;
    }
    section#opportunitiesLandingPageBuilder .opportunities-block-3 {
        width: 75%;
    }
    section#opportunitiesLandingPageBuilder .opportunities-block-99 {
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
    }
    section#opportunitiesLandingPageBuilder .opportunities-block-1.wrapper {
        height: 50%;
    }
    section#opportunitiesLandingPageBuilder {
        position: absolute;
    }
     .grid-sizer-b,
    .grid-item-b,
    .grid-item-1-2 {
        width: 25%;
        height: 50vh;
    }
    .grid-item-1-1 {
        height: 25vh;
    }
    .grid-item-1-3 {
        height: 75vh;
    }
    .grid-item-1-4,
    .grid-item-4-4 {
        height: 100vh;
    }
    .grid-item-2-2 {
        width: 50%;
    }
    .grid-item-3-2 {
        width: 75%;
    }
    .grid-item-4-2,
    .grid-item-4-4 {
        width: 100%;
    }
    .grid-item-jolly,
    .grid-sizer-b {
        display: block;
        width: 25%;
    }
  
    /*
    .oLPBWrapper-2 .el-0 {
        width: 66.666%;
    }
    .oLPBWrapper-2 .el-2 {
        width: 100%;
    }

    .oLPBWrapper-3 .el-0 {
        width: 33.333%;
    }
    .oLPBWrapper-3 .el-2 {
        width: 66.666%;
    }
   
    .oLPBWrapper-4 .el-0 {
        width: 66.666%;
    }
    .oLPBWrapper-4 .el-1 {
        width: 66.666%;
    }
    .oLPBWrapper-4 .el-2 {
        width: 33.333%;
        height: 100vh;
    }
    .oLPBWrapper-4 .el-3 {
        width: 66.666%;
    }

    .oLPBWrapper-5 .el-0 {
        width: 66.666%;
    }
    .oLPBWrapper-5 .el-3 {
        width: 33.333%;
        height: 100vh;
    }
    .oLPBWrapper-5 .el-4 {
        width: 66.666%;
    }
 
    .oLPBWrapper-6 .el-0 {
        width: 33.333%;
        height: 100vh;
    }
    .oLPBWrapper-6 .el-1 {
        width: 33.333%;
    }
    .oLPBWrapper-6 .el-4 {
        height: 100vh;
    }
    
    .oLPBWrapper-7 .el-5 {
        height: 50vh;
    }
    .oLPBWrapper-7 .el-4 {
        height: 75vh;
    }
    */
}

/* Larger than Desktop HD */

@media (min-width: 1200px) {}

@media (min-width: 1680px) {
    .grid-sizer-b,
    .grid-item-b,
    .grid-item-1-2 {
        width: 25% !important;
        height: 50vh !important;
    }
    .grid-item-1-1 {
        height: 25vh !important;
    }
    .grid-item-1-3 {
        height: 75vh !important;
    }
    .grid-item-1-4,
    .grid-item-4-4 {
        height: 100vh !important;
    }
    .grid-item-2-2 {
        width: 50% !important;
    }
    .grid-item-3-2 {
        width: 75% !important;
    }
    .grid-item-4-2,
    .grid-item-4-4 {
        width: 100% !important;
    }
}

@media (min-width: 768px) {}