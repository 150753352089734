.MapFiltered-menu{
    width: 100%;
    /* margin: 0 10%; */
    padding:0px;
}


.MapFiltered-subMenu a, .MapFiltered-subMenu .text{
    color:black;
    line-height: 0px;
    z-index: 999;
    position: relative;
}


.MapFiltered-subMenu a, .MapFiltered-subMenu .text .dots{
    content: ".";
    position: absolute;
    height: 20px;
    width:20px;
    font-size: 50px;
    right: -20px;
    top: 0px;
}

.centreSpecificContent .MapFiltered-subMenu a, .centreSpecificContent .MapFiltered-subMenu .text{
    color: white;
}

.centreSpecificContent .MapFiltered-subMenu .checkbox {
    z-index: 999;
    position: relative;
}

.centreSpecificContent .MapFiltered-subMenu input[type="checkbox"]:checked+span .checkbox {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 11.3 7.5' style='enable-background:new 0 0 11.3 7.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Ctitle%3EUntitled-2%3C/title%3E%3Cpolygon class='st0' points='9.9,0 3.8,6.1 1.4,3.8 0,3.8 3.8,7.5 11.3,0 '/%3E%3C/svg%3E%0A");
}

.centreSpecificContent  .MapFiltered-subMenu .checkbox{
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 6.2 6.2' style='enable-background:new 0 0 6.2 6.2;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bclip-path:url(%23SVGID_2_);%7D .st1%7Bfill:none;stroke:%23FFFFFF;stroke-width:0.89;%7D%0A%3C/style%3E%3Ctitle%3EUntitled-1%3C/title%3E%3Cg%3E%3Cdefs%3E%3Crect id='SVGID_1_' y='0' width='6.2' height='6.2'/%3E%3C/defs%3E%3CclipPath id='SVGID_2_'%3E%3Cuse xlink:href='%23SVGID_1_' style='overflow:visible;'/%3E%3C/clipPath%3E%3Cg class='st0'%3E%3Crect x='0.4' y='0.4' class='st1' width='5.3' height='5.3'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}

.MapFiltered-menu input[type="text"]{
    width:100%;
    border:0px;
}

.MapFiltered-menu  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
    font-family: "LandsecBrownWeb-Bold", sans-serif !important;
}
.MapFiltered-container {
    display:none;
}

.MapFiltered-subMenu {
    padding: 30px 0px;
}

.MapFiltered-subMenu {
    font-size: 1.4rem;
    font-family: "LandsecBrownWeb-Regular", sans-serif !important;
    margin: 0px;
    margin-top: 20px;
    padding: 0px;
}

.MapFiltered-subMenu li {
    padding:10px 0px;
    list-style: none;
}

.MapFiltered-subMenu input[type="checkbox"]{
    display:none;
}
.MapFiltered-subMenu .checkbox{
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 6.2 6.2'%3E%3Cdefs%3E%3Cstyle%3E.cls-1,.cls-3%7Bfill:none;%7D.cls-2%7Bclip-path:url(%23clip-path);%7D.cls-3%7Bstroke:%231e1e1c;stroke-width:0.89px;%7D%3C/style%3E%3CclipPath id='clip-path' transform='translate(0 -0.02)'%3E%3Crect class='cls-1' y='0.02' width='6.2' height='6.2'/%3E%3C/clipPath%3E%3C/defs%3E%3Ctitle%3EUntitled-1%3C/title%3E%3Cg class='cls-2'%3E%3Crect class='cls-3' x='0.44' y='0.44' width='5.32' height='5.32'/%3E%3C/g%3E%3C/svg%3E");
    width: 20px;
    height: 20px;
    padding-right: 10px;
    margin-right: 15px;
    display: inline-block;
    background-size: contain;
}

.MapFiltered-subMenu input[type="checkbox"]:checked+span .checkbox{
    background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11.3 7.53'%3E%3Ctitle%3EUntitled-2%3C/title%3E%3Cpolygon points='9.89 0 3.77 6.12 1.41 3.77 0 3.77 3.77 7.54 11.3 0 9.89 0'/%3E%3C/svg%3E");
    width: 30px;
    margin-right: 5px;
    padding-right: 10px;
}

.MapFiltered-subMenu .cover-wrapper{
    display: none
}

.MapFiltered-subMenu input[type="checkbox"]:checked + span + span + div     {
    display: block!important;
}




.MapFiltered-subMenu li:before {
    display: none;
}

.MapFiltered-container{
    display:block;
}



@media (min-width: 992px) {
    .ghb-wrapper-pink-text ul.MapFiltered-menu > li {
        position: relative;
        margin-left: 0px;
        padding: 30px 0px;
        padding-left: 30px;
        border-bottom:1px solid black;
        text-transform: capitalize;
    }
    .ghb-wrapper-pink-text ul.MapFiltered-menu > li:before{
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='ae7c33e6-af6e-478a-a8da-0ecd3d888618' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' width='12.6' height='25' viewBox='0 0 12.6 25'%3E%3Ctitle%3Earrow2%3C/title%3E%3Cpath d='M18.1,15,8.7,24.4v3.1L21.3,15,8.8,2.5V5.6' transform='translate(-8.7 -2.5)'/%3E%3C/svg%3E");
        position: absolute;
        height: 26px;
        width: 13px;
        left: 0px;
        top: 40px;
        background-size: contain;
        transition: 0.35s ease-in-out;
    }
    .ghb-wrapper-pink-text ul.MapFiltered-menu > li.active:before{
        -webkit-transform: rotate(90deg); 
        top: 25px;
    }

   
    .ghb-wrapper-pink-text ul.MapFiltered-menu li.search:before{
        background-image: url("data:image/svg+xml,%3Csvg id='Capa_1' data-name='Capa 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50.77 52.66'%3E%3Ctitle%3Esearch2%3C/title%3E%3Cpath d='M51.75,51.32l-14.9-15.5a21,21,0,1,0-1.48,1.35L50.26,52.66c1-.83.61-.51.74-.64s-.07.08.12-.1ZM22,40A19,19,0,1,1,41,21,19,19,0,0,1,22,40Z' transform='translate(-0.98 0)'/%3E%3C/svg%3E");
    }
    .ghb-wrapper-pink-text ul.MapFiltered-menu li:last-child  {
        border-bottom:0px;
    }
}